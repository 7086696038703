import React from 'react';

const CancellationCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
    >
      <g clipPath="url(#clip0_11_71)">
        <path
          d="M33.5529 6.20877C33.9736 6.20877 34.3147 5.86774 34.3147 5.44705C34.3147 5.02637 33.9736 4.68533 33.5529 4.68533C33.1323 4.68533 32.7912 5.02637 32.7912 5.44705C32.7912 5.86774 33.1323 6.20877 33.5529 6.20877Z"
          fill="black"
        />
        <path
          d="M36.3408 7.70479C36.1131 7.35135 35.6416 7.24852 35.2881 7.47627C34.9347 7.70402 34.8319 8.17553 35.0596 8.52896C36.641 10.9863 37.4766 13.8321 37.4766 16.7578C37.4766 25.158 30.6424 31.9922 22.2422 31.9922C20.0576 31.9922 17.956 31.542 15.9885 30.6538C15.8102 26.52 12.48 23.189 8.34615 23.0115C7.45799 21.044 7.00781 18.9424 7.00781 16.7578C7.00781 8.35758 13.842 1.52344 22.2422 1.52344C25.1679 1.52344 28.0137 2.35904 30.471 3.94037C30.8245 4.16813 31.296 4.06605 31.5237 3.71186C31.7515 3.35766 31.6494 2.88691 31.2952 2.65916C28.5919 0.919394 25.4612 0 22.2422 0C17.7663 0 13.5578 1.74281 10.3929 4.90852C7.22795 8.07346 5.48438 12.282 5.48438 16.7578C5.48438 18.9645 5.9018 21.0943 6.7275 23.1045C2.91891 23.7154 0 27.0243 0 31.002C0 35.4123 3.5877 39 7.99805 39C11.9757 39 15.2846 36.0811 15.8955 32.2725C17.9057 33.0982 20.0355 33.5156 22.2422 33.5156C26.718 33.5156 30.9265 31.7728 34.0915 28.6071C37.2564 25.4422 39 21.2337 39 16.7578C39 13.5388 38.0806 10.4081 36.3408 7.70479ZM7.99805 37.4766C4.42787 37.4766 1.52344 34.5721 1.52344 31.002C1.52344 27.4318 4.42787 24.5273 7.99805 24.5273C11.5682 24.5273 14.4727 27.4318 14.4727 31.002C14.4727 34.5721 11.5682 37.4766 7.99805 37.4766Z"
          fill="black"
        />
        <path
          d="M25.5534 9.93281C25.6387 8.48479 25.1862 7.06418 24.2806 5.93151C23.7824 5.30842 23.039 4.95193 22.2422 4.95193C21.4454 4.95193 20.702 5.30918 20.2038 5.93151C19.2981 7.06418 18.8457 8.48479 18.931 9.93281L19.0932 12.6918L12.1989 15.2839C11.9019 15.3959 11.7053 15.68 11.7053 15.9969V19.0437C11.7053 19.5122 12.1235 19.8694 12.5859 19.7963L19.4474 18.7131L19.6775 22.6276L18.4983 23.3353C17.8707 23.7115 17.4815 24.4001 17.4815 25.1314V27.0425C17.4815 27.53 17.9324 27.8919 18.4085 27.786L22.2422 26.9344L26.0759 27.786C26.5512 27.8919 27.0029 27.53 27.0029 27.0425V25.1314C27.0029 24.3956 26.6167 23.7138 25.986 23.3353L24.8069 22.6276L25.0369 18.7131L31.8985 19.7963C32.3609 19.8694 32.779 19.5122 32.779 19.0437V15.9969C32.779 15.68 32.5825 15.3951 32.2855 15.2839L25.3911 12.6918L25.5534 9.93281ZM13.228 18.1518V16.524L19.1862 14.2838L19.3568 17.1836L13.228 18.1518ZM25.2015 24.6401C25.3736 24.7437 25.4787 24.9295 25.4787 25.1299V26.0912L22.2414 25.3721L19.0041 26.0912V25.1299C19.0041 24.9295 19.1092 24.7429 19.2814 24.6401L20.8559 23.6955C21.0989 23.5493 21.2413 23.2804 21.2245 22.9978L20.4506 9.84369C20.3874 8.77272 20.7218 7.72154 21.3921 6.88365C21.5993 6.62467 21.9093 6.47537 22.2414 6.47537C22.5735 6.47537 22.8828 6.62391 23.0907 6.88365C23.7611 7.72154 24.0955 8.77272 24.0322 9.84369L23.2583 22.9978C23.2416 23.2812 23.3832 23.5501 23.627 23.6955L25.2015 24.6401ZM31.2556 16.524V18.1518L25.1268 17.1844L25.2974 14.2845L31.2556 16.524Z"
          fill="black"
        />
        <path
          d="M11.2026 27.7974C10.9055 27.5003 10.4226 27.5003 10.1255 27.7974L7.99805 29.9249L5.87057 27.7974C5.5735 27.5003 5.09057 27.5003 4.7935 27.7974C4.49643 28.0945 4.49643 28.5774 4.7935 28.8745L6.92098 31.002L4.7935 33.1294C4.49643 33.4265 4.49643 33.9094 4.7935 34.2065C5.09057 34.5036 5.5735 34.5036 5.87057 34.2065L7.99805 32.079L10.1255 34.2065C10.4226 34.5036 10.9055 34.5036 11.2026 34.2065C11.4997 33.9094 11.4997 33.4265 11.2026 33.1294L9.07512 31.002L11.2026 28.8745C11.4997 28.5774 11.4997 28.0945 11.2026 27.7974Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_71">
          <rect width="39" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CancellationCard;

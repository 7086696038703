import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const useTranslationForPersonType = () => {
  const { general } = useSelector(
    (state: RootState) => state.config.translations
  );

  const getPersonTypeTranslation = (
    type: 'adult' | 'child' | 'infant',
    quantity: number
  ) => {
    if (quantity > 1) {
      if (type === 'adult') return general.adults;
      if (type === 'child') return general.children;
      if (type === 'infant') return general.infants;
    } else {
      if (type === 'adult') return general.adult;
      if (type === 'child') return general.child;
      if (type === 'infant') return general.infant;
    }
  };

  return getPersonTypeTranslation;
};

export default useTranslationForPersonType;

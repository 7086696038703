import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { convertToNumberIfPossible } from '../../utils/convertToNumberIfPossible';

const searchDateFormat = 'DD/MM/YYYY';
const params = new URL(window.location.href).searchParams;

const initialState: any = {
  searchParams: {
    from: null,
    to: null,
    pax:
      params.get('pax') === '' || params.get('pax') === null
        ? null
        : params.get('pax')?.split(','),
    rooms:
      params.get('rooms') === '' || params.get('rooms') === null
        ? null
        : params.get('rooms'),
    adult:
      params.get('adult') === '' || params.get('adult') === null
        ? 2
        : params.get('adult'),
    child:
      params.get('child') === '' || params.get('child') === null
        ? 0
        : params.get('child'),
     infant:
      params.get('infant') === '' || params.get('infant') === null
        ? 0
        : params.get('infant'),
    oneWay:
      params.get('oneWay') === '' || params.get('oneWay') === null
        ? '0'
        : params.get('oneWay'),
    prvmd:
      params.get('prvmd') === '' || params.get('prvmd') === null
        ? '0'
        : params.get('prvmd'),
    vacationType:
      params.get('vacationType') == '' || params.get('vacationType') === null
        ? null
        : params.get('vacationType'),
    destination:
      params.get('destination') === '' || params.get('destination') === null
        ? null
        : params
            .get('destination')?.includes(',') ? 
            params?.get('destination')?.split(',')
            .map((entry) => Number(entry)) : params
            .get('destination'),
    departure:
      params.get('departure') === '' || params.get('departure') === null
        ? null
        : params
            .get('departure')?.includes(',') ? 
            params?.get('departure')?.split(',')
            .map((entry) => Number(entry)) : params
            .get('departure'),
    destinationCity:
      params.get('destinationCity') === '' || params.get('destinationCity') === null
        ? null
        : params
            .get('destinationCity'),
    departureCity:
      params.get('departureCity') === '' || params.get('departureCity') === null
        ? null
        : params
            .get('departureCity'),
    directFlight:
      params.get('directFlight') === '' || params.get('directFlight') === '0'
        ? '0'
        : params
            .get('directFlight') ?? '0',
    boardBasis:
      params.get('boardBasis') === '' || params.get('boardBasis') === null
        ? null
        : params.get('boardBasis')?.split(','),
        multi_room_guests:
      params.get('multi_room_guests') === '' || params.get('multi_room_guests') === null
        ? [{'adult':2, child:0, infant:0}]
        : JSON.parse(params.get('multi_room_guests') ?? "[{'adult':2, child:0, infant:0}]"),
        hotelStar:
        params.get('hotelStar') === '' || params.get('hotelStar') === null
          ? null
          : params.get('hotelStar')?.split(','),  },
  searchResults: [],
  sideFilters:{}
};
// ONLY POPULATE WITH SIDE FILTERS FROM URL
params.forEach((value, key) => {
  if (initialState.searchParams.hasOwnProperty(key)) {
    if (initialState.searchParams[key] === null || initialState.searchParams[key] === undefined) {
      initialState.searchParams[key] = convertToNumberIfPossible(value);
    }
  } else {
    if (!initialState.sideFilters.hasOwnProperty(key)) {
      initialState.sideFilters[key] = [];
    }
    initialState.sideFilters[key] = value.split(',').map(convertToNumberIfPossible);
  }
});

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchParams: (state, action) => {
      state.searchParams = action.payload;
    },
    updateSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    clearSearchParams: (state) => {
      state.searchParams = {
        ...initialState.searchParams,
        pax: null,
        rooms: null,
        vacationType: null,
        destination: null,
        adult: 0,
        child: 0,
        infant: 0,
      };
    },
    updateSearchInitialState: (
      state,
      action: { type: string; payload: { from: number; range: number, destination:string, destinationCity:string, departure:string, departureCity:string  } }
    ) => {
      const currParams = new URL(window.location.href).searchParams;
      let from, to, date;
      if (currParams.get('from')) {
        from = currParams.get('from');
      }
      if (currParams.get('date')) {
        date = currParams.get('date');
      }
      if (currParams.get('to') && from) {
        to = currParams.get('to');
      }
      if (!from) {
        from = moment().add(action.payload.from, 'd').format(searchDateFormat);
      }
      if (!date) {
        date = moment().add(action.payload.from, 'd').format(searchDateFormat);
      }
      if (!to) {
        to = moment()
          .add(action.payload.range + action.payload.from, 'd')
          .format(searchDateFormat);
      }

      if(!state.searchParams.from)state.searchParams.from = from;
      if(!state.searchParams.date)state.searchParams.date = date;
      if(!state.searchParams.to)state.searchParams.to = to;
      if(!state.searchParams.destination)state.searchParams.destination = action.payload.destination;
      if(!state.searchParams.departure)state.searchParams.departure = action.payload.departure;
      if(!state.searchParams.destinationCity)state.searchParams.destinationCity = action.payload.destinationCity;
      if(!state.searchParams.departureCity)state.searchParams.departureCity = action.payload.departureCity;
    },
    updateSiteFilter:(state, action) => {
        state.sideFilters[action.payload.key] = action.payload.value;
    },
    setSiteFilters:(state, action) => {
        state.sideFilters = action.payload;
    },
    clearSideFilter:(state) => {
      if (state.searchParams.directFlight == '1' && window.location.pathname.includes('flights')) {
        state.sideFilters = {
          f1_stops: [0],
          f2_stops: [0],
          stops: [0],
        };
      } else {
        state.sideFilters = {};
      }
    }
  },
});

export const {
  updateSearchParams,
  updateSearchResults,
  updateSearchInitialState,
  clearSearchParams,
  updateSiteFilter,
  clearSideFilter,
  setSiteFilters
} = searchSlice.actions;

export default searchSlice.reducer;

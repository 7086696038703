import { message, Modal } from 'antd';
import React from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import useSiteLang from '../../../../hooks/useSiteLang';
import useGetGrossPrice from '../../../../hooks/useGetGrossPrice';
import Amenities from '../../../ViewFlight/AdditionalFaresFlightBox/Amenities';

interface Props {
  isFareModalOpen: boolean;
  setIsFareModalOpen: (value: React.SetStateAction<boolean>) => void;
  isFlightFrom?: boolean;
  additionalFares: any;
  setDynamicFlights: any;
  fareName: any;
  fareIdentifier: string;
}

const AdditionalFaresFlightModal = ({
  isFareModalOpen,
  setIsFareModalOpen,
  isFlightFrom,
  additionalFares,
  setDynamicFlights,
  fareName,
  fareIdentifier,
}: Props) => {
  const { searchDatePicker: t, viewFlight: viewFlight_t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const getGrossPrice = useGetGrossPrice();
  const { config } = useSelector((data: RootState) => data.config);

  const { direction } = useSiteLang({
    siteConfig: config?.siteConfig,
  });
  return (
    <Modal
      open={isFareModalOpen}
      footer={null}
      onCancel={() => {
        setIsFareModalOpen(false);
      }}
      closeIcon={null}
      className={`additional-fares-modal additional-fares-modal-${direction}`}
    >
      <h2 style={{ marginBottom: 20, fontSize: 24 }}>
        {viewFlight_t.allFares}
      </h2>
      {additionalFares &&
        Object.values(additionalFares)?.map((flight: any, index: number) => {
          const packagePrice = getGrossPrice(
            flight.price.grandTotal,
            flight.agent_additional_info?.suggested_commission
          );
          const flightFromNames = flight.flightFrom.map((flight: any) => {
            return flight.fare.name;
          });
          const uniqueNames = Array.from(new Set([...flightFromNames]));
          const currentFareName = flight.flightFrom[0].fare.name;
          const currentFareIdentifier = flight.identifier;
          const uniqueFlightFromFares: any[] = [];
          const seenNamesFrom = new Set();

          flight?.flightFrom?.forEach((flight: any) => {
            if (!seenNamesFrom.has(flight.fare.name)) {
              seenNamesFrom.add(flight.fare.name);
              uniqueFlightFromFares.push(flight);
            }
          });

          const uniqueFlightBackFares: any[] = [];
          const seenNamesBack = new Set();

          flight?.flightBack?.forEach((flight: any) => {
            if (!seenNamesBack.has(flight.fare.name)) {
              seenNamesBack.add(flight.fare.name);
              uniqueFlightBackFares.push(flight);
            }
          });

          if (uniqueNames?.length > 1)
            return (
              <div
                className={`AdditionalFaresFlightBox ${
                  fareIdentifier === currentFareIdentifier
                    ? 'AdditionalFaresFlightBoxSelected'
                    : ''
                }`}
                key={index}
                style={{ marginBottom: 20 }}
              >
                <div className="different-fares">
                  {uniqueFlightFromFares?.map(
                    (flightFrom: any, index: number) => {
                      return (
                        <Amenities
                          key={index}
                          flightFrom={flightFrom}
                          isShowDirection
                        />
                      );
                    }
                  )}

                  {uniqueFlightBackFares?.map(
                    (flightFrom: any, index: number) => {
                      return (
                        <Amenities
                          key={index}
                          flightFrom={flightFrom}
                          isShowDirection
                        />
                      );
                    }
                  )}
                </div>
                <div className="price-wrapper">
                  <div
                    className={`price-header`}
                    onClick={() => {
                      setDynamicFlights((prev: any) => {
                        const newFlights = [...prev];
                        if (isFlightFrom) newFlights[0] = flight;
                        else newFlights[1] = flight;
                        setIsFareModalOpen(false);
                        message.success(viewFlight_t.fareChangedSuccessfully);
                        return newFlights;
                      });
                    }}
                  >
                    {viewFlight_t.choose}
                  </div>
                  <div className="info">
                    <div></div>
                    <div>
                      <div className="price-diff">
                        <div className="small-text">{t.extension}</div>
                      </div>
                      <div className="total-price">
                        {flight.price.currency_sign}
                        {packagePrice}
                        <div className="small-text">{t.totalToBePaid}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );

          return (
            <div
              className={`AdditionalFaresFlightBox ${
                fareName === currentFareName
                  ? 'AdditionalFaresFlightBoxSelected'
                  : ''
              }`}
              key={index}
              style={{ marginBottom: 20 }}
            >
              <Amenities
                flightFrom={flight.flightFrom[0]}
                flightBack={flight.flightFrom[flight.flightFrom.length - 1]}
                uniqueNames={uniqueNames}
              />
              <div className="price-wrapper">
                <div
                  className={`price-header`}
                  onClick={() => {
                    setDynamicFlights((prev: any) => {
                      const newFlights = [...prev];
                      if (isFlightFrom) newFlights[0] = flight;
                      else newFlights[1] = flight;
                      setIsFareModalOpen(false);
                      message.success(viewFlight_t.fareChangedSuccessfully);
                      return newFlights;
                    });
                  }}
                >
                  {viewFlight_t.choose}
                </div>
                <div className="info">
                  <div></div>
                  <div>
                    <div className="price-diff">
                      <div className="small-text">{t.extension}</div>
                    </div>
                    <div className="total-price">
                      {flight.price.currency_sign}
                      {packagePrice}
                      <div className="small-text">{t.totalToBePaid}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </Modal>
  );
};

export default AdditionalFaresFlightModal;

import React, { useState, useEffect } from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import "./acceptCookies.less";

const AcceptCookies = () => {
  const [showMessage, setShowMessage] = useState(false);
  const { general } = useSelector(
      (data: RootState) => data.config.translations
  );

  useEffect(() => {
    // Check if the message has already been shown
    const messageShown = localStorage.getItem('cookieMessageShown');
    if (!messageShown) {
      setShowMessage(true);
    }
  }, []);

  const handleAccept = () => {
    // Set the flag in localStorage to indicate the message has been shown
    localStorage.setItem('cookieMessageShown', 'true');
    setShowMessage(false);
  };

  if (!showMessage) {
    return null;
  }

  return (
      <div className="cookie-message-container">
        <p className="cookie-message-text">{general.acceptCookiesTitle}</p>
        <button className="cookie-message-accept-button" onClick={handleAccept}>{general.acceptCookiesBtn}</button>
      </div>
  );
};

export default AcceptCookies;

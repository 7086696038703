import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCustomerInfo } from '../ShoppingCartSliceV2';
import { isEmpty } from 'lodash';

const useInitializeCurrentCustomer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const customerInfo = JSON.parse(
      localStorage.getItem('currentCustomer') ?? '{}'
    );

    if (isEmpty(customerInfo)) return;

    dispatch(setCustomerInfo(customerInfo));
  }, []);
};

export default useInitializeCurrentCustomer;

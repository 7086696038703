import React, { useMemo, useState } from 'react';
import {Table, Checkbox, Button, Modal, Divider, ConfigProvider} from 'antd';
import {
  Product,
  ShoppingCartV2,
} from '../../../b2c/src/modules/ShoppingCart/ShoppingCartSliceV2';
import './baggage-management.less';
import { IoIosMan } from 'react-icons/io';
import { FaBaby, FaChild, FaPlus } from 'react-icons/fa';
import { MainProductType } from '../../../b2c/src/modules/ShoppingCart/interfaces';

const getIcon = (guestType: string) => {
  switch (guestType) {
    case 'child':
      return <FaChild size={25} />;
    case 'infant':
      return <FaBaby size={25} />;
    default:
      return <IoIosMan size={32} />;
  }
};
const adjustGuestsArray = (
  guestsArray: any[],
  isBack: boolean,
  trollyPrice: number,
  suitcasePrice: number,
  relatedGuestIndexes: number[],
  flightSubProducts?: Product[]
) => {
  return guestsArray
    .filter((guest) => {
      return relatedGuestIndexes.includes(guest.g_index);
    })
    .map((g: any) => ({
      ...g,
      isBack,
      first_name: g.first_name ? (
        <>
          <span className="guest-type">
            {getIcon(g.type)} <div>-</div> {g.first_name}
          </span>
        </>
      ) : (
        <span className="guest-type">
          {getIcon(g.type)} <div>-</div> Guest {g.g_index + 1}
        </span>
      ),
      id: g.g_index,
      trollyPrice,
      suitcasePrice,
      hasSuitcase: flightSubProducts?.some(
        (subProduct: any) =>
          (subProduct.type === 'suitcase' ||
            subProduct.type_name === 'Suitcase') &&
          (subProduct.related_guest_indexes?.includes(g?.g_index) ||
            subProduct.related_guests_index?.includes(String(g.g_index)))
      ),
      hasTrolly: flightSubProducts?.some(
        (subProduct: any) =>
          (subProduct.type === MainProductType.Trolly ||
            subProduct.type_name === 'Trolly') &&
          (subProduct.related_guest_indexes?.includes(g?.g_index) ||
            subProduct.related_guests_index?.includes(String(g.g_index)))
      ),
    }));
};
interface Guest {
  id: string;
  first_name: string;
  last_name: string;
  type: string;
  hasSuitcase: boolean;
  hasTrolly: boolean;
  isBack: boolean;
  mainProduct: any;
  suitcasePrice: number;
  trollyPrice: number;
  g_index: string;
}

interface BaggageManagementProps {
  onChange: (...params: any[]) => void;
  onSave: () => void;
  onClose?: (prevCart?: ShoppingCartV2) => void;
  mainProduct: Product;
  guests: any[];
  isManageBaggageOpen: boolean;
  setIsManageBaggageOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shoppingCart?: ShoppingCartV2;
  isRoundTrip: boolean;
  flightFromBaggage: any;
  flightBackBaggage: any;
  t: Record<string, string>;
  direction:'ltr' | 'rtl'
}

const BaggageManagement: React.FC<BaggageManagementProps> = ({
  onChange,
  mainProduct,
  guests,
  shoppingCart,
  onClose,
  isManageBaggageOpen,
  setIsManageBaggageOpen,
  onSave,
  isRoundTrip,
  flightFromBaggage,
  flightBackBaggage,
  t,
  direction
}) => {
  const flightFromSuitcasePrice = flightFromBaggage?.suitcase_price;
  const flightFromTrollyPrice = flightFromBaggage?.trolly_price;
  const flightBackTrollyPrice = flightBackBaggage?.trolly_price;
  const flightBackSuitcasePrice = flightBackBaggage?.suitcase_price;
  const flightFromSuitcaseSaleAllowed = flightFromBaggage?.enable_sale_suitcase;
  const flightFromTrollySaleAllowed = flightFromBaggage?.enable_sale_trolly;
  const flightBackSuitcaseSaleAllowed = flightBackBaggage?.enable_sale_suitcase;
  const flightBackTrollySaleAllowed = flightBackBaggage?.enable_sale_trolly;

  const isSaleAllowedFlightBack =
    flightBackSuitcaseSaleAllowed || flightBackTrollySaleAllowed;

  const isSaleAllowedFlightFrom =
    flightFromSuitcaseSaleAllowed || flightFromTrollySaleAllowed;

  const getSaleStatusMessage = (
    trollySaleAllowed: boolean,
    suitcaseSaleAllowed: boolean
  ) => {
    if (trollySaleAllowed && suitcaseSaleAllowed) return '';
    if (trollySaleAllowed && !suitcaseSaleAllowed)
      return (
        <div>
          <b className="sale-status"> {t.suitcase}</b>
          {` ${t.purchaseIsNotSupported}`}
        </div>
      );
    if (!trollySaleAllowed && suitcaseSaleAllowed)
      return (
        <div>
          <b className="sale-status"> {t.trolly}</b>
          {` ${t.purchaseIsNotSupported}`}
        </div>
      );
    if (!trollySaleAllowed && !suitcaseSaleAllowed)
      return (
        <div>
          <b className="sale-status">
            {t.suitcase} {t.and} {t.trolly}
          </b>
          {` ${t.purchaseIsNotSupported}`}
        </div>
      );
  };

  const saleStatusMessageFromFlight = getSaleStatusMessage(
    flightFromTrollySaleAllowed,
    flightFromSuitcaseSaleAllowed
  );
  const saleStatusMessageBackFlight = getSaleStatusMessage(
    flightBackTrollySaleAllowed,
    flightBackSuitcaseSaleAllowed
  );

  const memoisedShoppingCart = useMemo(() => {
    return shoppingCart;
  }, []);

  const flightFromSubProducts = mainProduct?.subProducts?.filter(
    (sb) =>
      sb.type === MainProductType.Flight ||
      sb.type === MainProductType.oneWayFlight
  )?.[0]?.subProducts;
  const flightBackSubProducts = mainProduct?.subProducts?.filter(
    (sb) =>
      sb.type === MainProductType.Flight ||
      sb.type === MainProductType.oneWayFlight
  )?.[1]?.subProducts;

  const filteredGuest = adjustGuestsArray(
    guests,
    false,
    flightFromTrollyPrice,
    flightFromSuitcasePrice,
    mainProduct.related_guest_indexes,
    flightFromSubProducts
  );

  const filteredGuestBaggageBack = adjustGuestsArray(
    guests,
    true,
    flightBackTrollyPrice,
    flightBackSuitcasePrice,
    mainProduct.related_guest_indexes,
    flightBackSubProducts
  );

  const [guestBaggage, setGuestBaggage] = useState<Guest[]>(filteredGuest);
  const [guestBaggageBack, setGuestBaggageBack] = useState<Guest[]>(
    filteredGuestBaggageBack
  );

  const handleCheckboxChange = (
    guestId: string,
    baggageType: 'hasSuitcase' | 'hasTrolly',
    isBack: boolean = false
  ) => {
    const setGuestBaggageState = isBack ? setGuestBaggageBack : setGuestBaggage;
    setGuestBaggageState((prevState) =>
      prevState.map((guest: Guest) =>
        guest.id === guestId
          ? { ...guest, [baggageType]: !guest[baggageType] }
          : guest
      )
    );
  };

  const createColumns = (
    flightSuitcasePrice: number,
    flightTrollyPrice: number,
    isBack: boolean = false,
    isSuitcaseSaleAllowed: boolean = true,
    isTrollySaleAllowed: boolean = true
  ) => {
    const columns: any = [
      { title: t.guest, dataIndex: 'first_name', key: 'first_name' },
    ];

    if (isSuitcaseSaleAllowed) {
      columns.push({
        title: `${t.suitcase} (${flightSuitcasePrice?.toFixed(0)}$)`,
        dataIndex: 'hasSuitcase',
        key: 'hasSuitcase',
        render: (_: any, guest: Guest) => (
          <Checkbox
            checked={guest.hasSuitcase}
            onChange={(e) => {
              const numberOfBaggages = e.target.checked ? 1 : 0;
              handleCheckboxChange(guest.id, 'hasSuitcase', isBack);
              onChange(
                numberOfBaggages,
                isBack ? 1 : 0,
                'suitcase',
                flightSuitcasePrice,
                guest.id
              );
            }}
          />
        ),
      });
    }

    if (isTrollySaleAllowed) {
      columns.push({
        title: `${t.trolly} (${flightTrollyPrice?.toFixed(0)}$)`,
        dataIndex: 'hasTrolly',
        key: 'hasTrolly',
        render: (_: any, guest: Guest) => (
          <Checkbox
            checked={guest.hasTrolly}
            onChange={(e) => {
              const numberOfBaggages = e.target.checked ? 1 : 0;
              handleCheckboxChange(guest.id, 'hasTrolly', isBack);
              onChange(
                numberOfBaggages,
                isBack ? 1 : 0,
                'trolly',
                flightTrollyPrice,
                guest.id
              );
            }}
          />
        ),
      });
    }
    return columns;
  };
  const columnsFrom = createColumns(
    flightFromSuitcasePrice,
    flightFromTrollyPrice,
    false,
    flightFromSuitcaseSaleAllowed,
    flightFromTrollySaleAllowed
  );

  const columnsBack = createColumns(
    flightBackSuitcasePrice,
    flightBackTrollyPrice,
    true,
    flightBackSuitcaseSaleAllowed,
    flightBackTrollySaleAllowed
  );

  const handleCloseModal = () => {
    setIsManageBaggageOpen(false);
    onClose && onClose(memoisedShoppingCart);
  };

  const productDataFlights =
    mainProduct.product_data.flightsInfo ?? mainProduct.product_data.flights;

  return (
    <ConfigProvider direction={direction}>

      <Modal
        open={!!isManageBaggageOpen}
        onCancel={handleCloseModal}
        footer={null}
        className="baggage-management-modal"
      >
        <div id="baggage-management">
          <h2 className="modal-title">{t.manageBaggage}</h2>
          <Divider></Divider>
          <span>
            <h3 className="flight-direction">
              {productDataFlights.flightFrom[0].from.name} →{' '}
              {
                productDataFlights.flightFrom[
                  productDataFlights.flightFrom.length - 1
                ].to.name
              }
              {isRoundTrip && <FaPlus />}
              {isRoundTrip && (
                <div>
                  {
                    productDataFlights.flightFrom[
                      productDataFlights.flightFrom.length - 1
                    ].to.name
                  }{' '}
                  → {productDataFlights.flightFrom[0].from.name}
                </div>
              )}
            </h3>
            <span className="info-text">
              <div>{saleStatusMessageFromFlight}</div>
              {isRoundTrip && <div>{t.baggageIsForBothFlights}</div>}
            </span>
          </span>
          {isSaleAllowedFlightFrom && (
            <Table
              columns={columnsFrom}
              dataSource={guestBaggage}
              rowKey="id"
              pagination={false}
            />
          )}
          <br />

          {flightBackSubProducts && !isRoundTrip && (
            <>
              <hr />
              <br />
              <h3 className="flight-direction">
                {
                  productDataFlights.flightFrom[
                  productDataFlights.flightFrom.length - 1
                      ].to.name
                }{' '}
                → {productDataFlights.flightFrom[0].from.name}
              </h3>
              <div className="info-text">{saleStatusMessageBackFlight}</div>
              {isSaleAllowedFlightBack && (
                <Table
                  columns={columnsBack}
                  dataSource={guestBaggageBack}
                  rowKey="id"
                  pagination={false}
                />
              )}
            </>
          )}
          <Button
            type="primary"
            onClick={onSave}
            className="save-btn btn-style-5"
          >
            {t.save}
          </Button>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default BaggageManagement;

const appConfig = {
    "siteConfig": {
        "defaultLang": "he_IL",
        "direction": "rtl",
        "languages": [
            "he_IL",
            "en_US"
        ],
        "name": "IPC Tour - סבב הפוקר הישראלי",
        "domain": "gr.familybreak.co.il",
        "defaultDate":  {
            "from": 30,
            "range": 30
        },

        "style": {
            "topClass": 'ipc'
        },
        "dateFormat":"DD/MM/YYYY",
        "logo":"/assets/images/logo-large.png",
        "logoSmall":"/assets/images/ipc/card.png",
        "rightImage":"",
        "boardBasis":[
            "full_board",
            "sleep_only",
            "breakfast_kidush",
            "all_include",
            "breakfast",
            "half_board",
            "half_board_kosher",
        ],
        "hotelStar":[
            '3', '4', '5'
        ],
        "destinations": {
            "local": [],
            "abroad":[
                    {
                        "label": "מלטה",
                        "value": 3424
                    },
                    {
                        "label": "בורגאס",
                        "value": 3424
                    },
                    {
                        "label": "ברטיסלבה",
                        "value": 3424
                    },

              ]
        },
        "occupancy": [
            { adult: 2, child: 0},
            { adult: 3, child: 0},
            { adult: 2, child: 1},
            { adult: 2, child: 2},
          ],
        "email": {
            "value": "pokership@gmail.com",
            "title": "זמינים במייל",
        },
        "phone": {
            "value": "052-8414477",
            "title": "התקשרו או כתבו לנו בוואטסאפ",
            "hours": "שעות פעילות 09:00-17:00"
        },
        "facebook":{
            "value":"https://www.facebook.com/ipc09",
            "title":"פרגנו לנו בעוקב בפייסבוק"
        },
        "instagram":{
            "value":"https://www.instagram.com/",
            "title":"פרגנו לנו בעוקב באינסטגרם"
        },
        "whatsapp": {
            "phone":"052-8414477",
            "message":"",
        },
        "companyInfo": {
            "title":"IPC Tour",
            "value":"צוות IPC מפיק ומנהל אירועי פוקר חיים והפלגות פוקר לשחקנים מישראל משנת 2009.\n" +
                "\n" +
                "במסגרת הסבב מתקיימים אירועי פוקר לשחקנים ישראלים בניהול צוות IPC בחדרי פוקר מוכרים באירופה. עבור כל אירוע, אנו \"תופרים\" חבילות נופש מותאמות ליצירת חווית חופשת פוקר מושלמת.\n" +
                "\n" +
                "כמפיקי אירועי הפוקר החוקיים והגדולים ביותר לישראלים, אנו מחויבים לחדשנות, למקצועיות, לרמת ארגון גבוהה ועמידה בסטנדרטים בינלאומיים להבטחת חווית פוקר מקצוענית לאורחינו.\n" +
                "\n" +
                "תתכוננו כבר לחגיגות פורק בשנת 2020\n" +
                "\n",
            "address": ""
        }
    },
    "theme": {
        "primary": "#1f3983",
        "secondary": "#d2222a",
        "tertiary": "#c2c2c2",
        "background": "#fff",
        "button": "btn-style-5",
        "fontFamily": "Heebo",
        "title": "title-style-2"
    },
    "layouts": {
        "default": {
            "topModules": [
                "Header",
            ],
            "bottomModules": [
                "Footer",
            ]
        },
        "noFooter": {
            "topModules": [

            ],
            "bottomModules": [
                "Footer"
            ]
        },
        "empty": {
            "topModules": [

            ],
            "bottomModules": [

            ]
        }
    },
    "pages": [
        {
            "path": "/",
            "modules": [
                // "SearchDatePickerMainPage",
                // "DestinationListLight",
                // "PackageListView",
                // "PackageListViewFilters",
                // "BannerLargeGirl",
                "BannerLarge",
                "PackageListViewNextWeek",
                "BannerLarge",
                "Socials"
            ],
            "name": "Main Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": ["/prom/:promSecret/package/:id",'/package/view','/package/:id', "/p/:alias"],
            "modules": [
                "PackageDetails",
                "PackageRoomPricing",
                "Activities"
            ],
            "name": "Package Info",
            "type": "packagePage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/prom/:promSecret",
            "modules": [
                "PromoPage",
            ],
            "name": "Main Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/room/:id",
            "modules": [
                "ImgCarousel",
                "SearchDatePicker",
                "RoomInfo",
                "OrderNow",
                "Activities",
                "RoomGeneralDetails"
            ],
            "name": "Room Info",
            "type": "roomPage",
            "layout": "empty",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/packages",
            "modules": [
                "SearchDatePickerSearchPage"
            ],
            "name": "Packages Search",
            "type": "searchResultPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/checkout/:id",
            "modules": [
                "Checkout",
            ],
            "name": "Checkout",
            "type": "checkoutPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/order/manage",
            "modules": [
                "ManageOrder",
            ],
            "name": "Manage Order",
            "type": "orderPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/contact",
            "modules": [
                "SearchDatePickerMainPage",
                "ContactUs",
            ],
            "name": "ContactUs",
            "type": "ContactUsPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/about",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentAboutUs",
            ],
            "name": "AboutUs",
            "type": "AboutUsPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/takanon",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentTakanon",
            ],
            "name": "Takanon",
            "type": "ContentPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/passenger-info",
            "modules": [
                "SearchDatePickerMainPage",
                "PageContentPassenger",
            ],
            "name": "PassengerInfo",
            "type": "ContentPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/hotel/:id/:name",
            "modules": [
                "HotelDetails",
                "HotelRoomPricing",
            ],
            "name": "Package Info",
            "type": "packagePage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            },

        },
        {
            "path": "/destinations",
            "modules": [
                "SearchDatePickerMainPage",
                "DestinationList",
            ],
            "name": "Destination Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/404",
            "modules": [
                "NotFound404",
                // "SearchDatePickerMainPage",
            ],
            "name": "NotFound404",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
    ],
    "modulesConfigs": {
        "Header": {
            "links": [
                {
                    "text": "עמוד הבית",
                    "url": "/"
                },
                {
                    "text": "אירועים קרובים",
                    "url": "/packages"
                },
                {
                    "text": "מי אנחנו",
                    "url": "/about"
                },
                // {
                //     "text": "על הצימר",
                //     "url": "/bandb"
                // },
                // {
                //     "text": "החדרים",
                //     "url": "/rooms"
                // },
                // {
                //     "text": "אטרקציות",
                //     "url": "/attractions"
                // },
                {
                    "text": "צור קשר",
                    "url": "/contact"
                }
            ],
            "theme": {
                "tertiary": "black",
                "primary": "#2f7bb9",
                "background": "#fff"
            },
            "set": "HeaderSet2",
            "logo": "http://www.ipctour.co.il/wp-content/themes/ipc/assets/img/layout/logo-ne.png",
            "widgetSocial": {
                "isActive": true,
                "socials": [{
                    "key": "instagram",
                    "value": "https://www.instagram.com/familybreak.co.il/",
                }, {
                    "key": "facebook",
                    "value": "https://www.facebook.com/familybreak.co.il/"
                }, {
                    "key": "whatsapp",
"value": "whatsapp://send?text=ברוכים הבאים למענה של פמילי ברייק!&phone=+972777729055"
                }
                ],
            },
            "module": "Header"
        },
        "Footer": {
            "columns": [
                {
                    "header": "צור קשר",
                    "links": [
                        {
                            "text": "כתבו לנו",
                            "url": "/contact"
                        },
                        {
                            "text": "מי אנחנו?",
                            "url": "/about"
                        },
                        {
                            "text": "תקנון האתר",
                            "url": "/takanon"
                        },
                    ]
                },
                // {
                //     "header": "אטרקציות",
                //     "links": [
                //         {
                //             "text": "ספא",
                //             "url": "/"
                //         },
                //         {
                //             "text": "מסעדות",
                //             "url": "/"
                //         },
                //         {
                //             "text": "ספא משפחתי",
                //             "url": "/"
                //         },
                //         {
                //             "text": "לראות את הצפון",
                //             "url": "/"
                //         },
                //         {
                //             "text": "טיול קניות",
                //             "url": "/"
                //         },
                //         {
                //             "text": "טיול טבע",
                //             "url": "/"
                //         },
                //         {
                //             "text": "טיול למדבר",
                //             "url": "/"
                //         }
                //     ]
                // },
                // {
                //     "header": "החדרים",
                //     "links": [
                //         {
                //             "text": "חדר סטנדטנ",
                //             "url": "/"
                //         },
                //         {
                //             "text": "חדר אקזקיוטיב",
                //             "url": "/"
                //         },
                //         {
                //             "text": "חדר משפחה",
                //             "url": "/"
                //         },
                //         {
                //             "text": "חדר סוויטה",
                //             "url": "/"
                //         }
                //     ]
                // }
            ],
            "set": "FooterSet2",
            "module": "Footer",
            "widgetScrollToTop": {
                "isActive": true
            },
            //"logo": "/assets/images/logo-large.png",
            "logo": "http://www.ipctour.co.il/wp-content/themes/ipc/assets/img/layout/logo-ne.png",

            "theme": {
                "background": "#384f8a",
                "tertiary": "#fff"
            }
        },
        "SearchDatePickerMainPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1"
            },
            "set": "SearchDatePickerSet2",
            "showSearchResults":{
                "isActive":false
            },
            "backgroundImg": "/assets/images/searchDatepickerPackground.jpg",
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
        "DestinationListLight":{
            "set": 'DestinationListLightSet1',
            "linkType": 'packageSearch',
            "title": 'היעדים שלנו',
        },
        "DestinationList":{
            "set": 'DestinationListSet1',
            "linkType": 'packageSearch',
            "title": 'היעדים שלנו',
        },
        "BannerLarge": {
            "module": "BannerLarge",
            "img": "/assets/images/ipc/banner.png",
            "button": false,
            "contentWidth": true,
            "backSize": "contain",
        },
        "BannerLargeGirl": {
            "module": "BannerLarge",
            "key": "BannerLargeGirl",
            "img": "/assets/images/baby-girl.png",
            "button": false,
            "textLine1":"נופש בחגים זה אנחנו.",
            "theme":{
                background:"#00CCFF"
            }
        },
        "ImgCarousel": {
            "carousel": [
                {
                    "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
                    "text": "צימר רמת הגולן המקום המושלם."
                },
                {
    "img": "https://cdn2.hubspot.net/hubfs/439788/Blog/Featured%20Images/Best%20Hotel%20Website%20Designs.jpg",
                    "text": "צימר רמת הגולן המקום המושלם."
                }
            ],
            "theme": {
                "title": "title-style-1"
            },
            "module": "ImgCarousel"
        },
        "DatesSuggestion": {
            "buttonText": "לעוד דילים טובים ›",
            "url": "#",
            "title": "תאריכי חופשה",
            "titleLineTwo": "פופולאריים",
            "theme": {
                "title": "title-style-2"
            },
            "module": "DatesSuggestion"
        },
        "Description": {
            "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
            "description": "המקום המושלם לחופש, שקט והנאה למשפחות.",
            "theme": {
                "title": "title-style-2"
            },
            "module": "Description"
        },
        "ContactUs": {
            "img": "https://www.africanbusinessreview.co.za/wp-content/uploads/2021/06/take-a-vacation.jpeg",
            "set":"ContactUsSet2",
            "title":"צרו איתנו קשר.",
            "titleLineTwo": "צרו איתנו קשר.  נשמח לעזור לכם להזמין חופשה מושלמת.",

            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "ContactUs"
        },
        "PageContentAboutUs": {
            "title":"צרו איתנו קשר.",
            "set":"PageContentSet1",
            "desc_id": 3,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentTakanon": {
            "title":"תקנון האתר",
            "set":"PageContentSet1",
            "desc_id": 1,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "PageContentPassenger": {
            "title":"מידע לנוסע",
            "set":"PageContentSet1",
            "desc_id": 2,
            "theme": {
                "button": "btn-style-5",
                "tertiary":"black",
                "title": "title-style-5"
            },
            "module": "PageContent"
        },
        "SearchDatePickerSearchPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1",
                "background": "#00ccff33"
            },
            "set": "SearchDatePickerPackageIndex",
            "setConfig": {
                "promotionText": "בחרו מספר יעדים  בין תאריכים ונמצא לכם את החופשה המושלמת!",
            },
            "showSearchResults":{
                isActive:true
            },
            "widgetDatePickerCarousel": {
                isActive: false
            },
            "widgetVacationTypes": {
                isActive: false
            },
        },
        "NotFound404":{
            "image":"/assets/images/404.png",
            "title":'אופס.. הדף שחיפשת לא קיים',
            "redirect_title":'הנך מועבר לדף הבית',
            "redirect_seconds":7000,
        },
        "RoomInfo": {
            "theme": {
                "title": "title-style-1"
            },
            "set": "RoomInfoSet1",
            "widgetRoomDetails": {
                "isActive": true
            },
            "widgetRoomFacilities": {
                "isActive": true
            },
            "widgetRoomPictures": {
                "isActive": true
            },
            "facilities": [
                "TV",
                "Telephone",
                "Card",
                "Bed",
                "Radio",
                "BreakFast",
                "Porter",
                "FiveStar",
                "Juice",
                "HouseKeeping",
                "DoNotDisturb",
                "Hostess"
            ],
            "module": "RoomInfo"
        },
        "OrderNow": {
            "theme": {
                "background": "#42595d"
            },
            "module": "OrderNow"
        },
        "ActivitiesSuggestion": {
            "buttonText": "לכל האטרקציות ›",
            "url": "#",
            "title": "אטרקציות וחוויות",
            "titleLineTwo": "בקרבת מקום",
            "theme": {
                "title": "title-style-3"
            },
            "module": "ActivitiesSuggestion"
        },
        "SearchDatePicker": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1"
            },
            "set": "SearchDatePickerSmallPackage",
            "showSearchResults":{
                "isActive":true
            },
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
        "PromoPage": {
            "module": "PromoPage",
            "packageListConfig": {
                "set": "PackageListViewSet2",
                "backgroundType":"type1",
                "widgetFilterMenu": {
                    "isActive": false,
                },
                "widgetMorePackages": {
                    "isActive": false
                },
                "widgetPackageBoxHeader": {
                    "isActive": true,
                    "title": ""
                }
            },
        },
        "PackageListView": {
            "set": "PackageListViewSet2",
            "module": "PackageListView",
            "backgroundType":"type1",
            "defaultFilters":{
                "staticFilter": "type=indexList",
                "limit":3,
                "dates": {
                    "from": 1,
                    "range": 14
                }
            },
            "widgetFilterMenu": {
                "isActive": false,
            },
            "widgetMorePackages": {
                "isActive": false
            },
            "widgetPackageBoxHeader": {
                "isActive": true,
                "title":"חבילות הנופש הנצפות ביותר"
            }
        },
        "PackageListViewFilters": {
            set: "PackageListViewSet2",
            "module": "PackageListView",
            "theme": {
                "button": "btn-style-5",
            },
            "backgroundType":false,
            "widgetFilterMenu": {
                "isActive": true,
                "listFilters": [
                    {
                        "name": "פסח",
                        "filters": {
                            "dates": "01/04/2023-15/04/2023",
                            "limit": "6",
                        }
                    },
                    {
                        "name": "מאמיה",
                        "filters": {
                            "dates": "01/07/2023-01/09/2023",
                            "limit": "6",
                            "destination":"3423"
                        }
                    },
                    {
                        "name": "קפריסין הצפונית",
                        "filters": {
                            "dates": "01/05/2023-01/09/2023",
                            "limit": "6",
                            "destination":"3396"
                        }
                    },
                    {
                        "name": "יולי אוגוסט למקדימים",
                        "filters": {
                            "dates": "01/07/2023-01/09/2023",
                            "limit": "12",
                        }
                    }
                ],
                "title":"חבילות נופש לחגים",
                "titleLineTwo":"ביעדים והמלונות שלנו!"
            },
            "widgetMorePackages": {
                "isActive": true
            },
            "widgetPackageBoxHeader": {
                "isActive": false
            }

        },
        "PackageListViewNextWeek": {
            "set": "PackageListViewSet2",
            "module": "PackageListView",
            "backgroundType":false,
            "defaultFilters":{
                "staticFilter": "type=upcoming",
                "limit":3,
                "dates": {
                    "from": 7,
                    "range": 21
                }
            },
            "widgetFilterMenu": {
                "isActive": false,
            },
            "widgetMorePackages": {
                "isActive": false
            },
            "widgetPackageBoxHeader": {
                "isActive": true,
                "title":"האירועים הקרובים שלנו !"
            }
        },
        "PackageDetails": {
            "theme": {
                "button": "btn-style-5",
                "primary": "#1c6098",
                "secondary": "#FFD62D",
                "tertiary": "#000"
            },
            "widgetFlights": {
                "isActive": true,
                "widgetSet": "FlightsSet1",
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet1",
            },
            "widgetActivities": {
                "isActive": true,
                "widgetSet": "ActivitiesSet1",
            }

        },
        "HotelDetails": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet1",
            },
            "widgetDatePicker": {
                "isActive": true,
                "widgetSet": "HotelDatePickerSet1",
            },
        },
        "RoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "rgba(35,61,68,0.2)"
            },
        },
        "HotelRoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
        },
        "PackageRoomPricing": {
            "theme": {
                "button": "btn-style-5",
                "tertiary": "black",
                "background": "#00ccff33"
            },
        },
        "Activities": {
            "theme": {
                "tertiary": "black"
            },
            "set": "ActivitiesSet1",
            "module": "Activities"
        }
    }
};
export default appConfig;
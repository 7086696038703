import React from 'react';
import { useSelector } from 'react-redux';
import { roundUpCurrency } from '@skygroup/shared/utils/roundUpCurrency';
import './cancellationTooltip.less';
import ConstructPolicyText from './ConstructPolicyText';

// helpers =>
function getTimezoneOffsetString() {
  const offsetMinutes = new Date().getTimezoneOffset();
  const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
  const offsetMinutesRemainder = Math.abs(offsetMinutes % 60);
  const sign = offsetMinutes < 0 ? '+' : '-';

  const offsetString = `UTC ${sign}${offsetHours
    .toString()
    .padStart(2, '0')}:${offsetMinutesRemainder.toString().padStart(2, '0')}`;

  return offsetString;
}
// helpers <=

const CancellationTooltip = ({
  cancellation_penalties,
  currency_code,
  roomPricingT,
}: {
  cancellation_penalties?: any;
  currency_code: string;
  roomPricingT: any;
}) => {
  // const { roomPricing: t } = useSelector(
  //   (data: any) => data.config.translations
  // );

  return (
    <div className="cancellation-tooltip">
      <h2>{roomPricingT.cancellation_policy}</h2>
      <div className="policy-items-wrapper">
        {cancellation_penalties?.policies &&
          cancellation_penalties?.policies.map((pol: any, index: number) => {
            let type = '';
            if (Number(pol?.amount_show) === 0) {
              type = 'free';
            } else if (pol?.end_at && Number(pol?.amount_show) > 0) {
              type = 'half';
            } else if (pol?.start_at && Number(pol?.amount_show) > 0) {
              type = 'full';
            } else {
              type = 'non-refund';
            }
            return (
              <span key={type + index}>
                <ConstructPolicyText
                  type={type}
                  date_start={pol.start_at}
                  date_end={pol.end_at}
                  chargePrice={roundUpCurrency(pol.amount_show, 2)}
                  currency_code={currency_code}
                  policyT={roomPricingT?.policy}
                />
              </span>
            );
          })}
      </div>
      <div>
        {roomPricingT.your_local_time} ({getTimezoneOffsetString()})
      </div>
    </div>
  );
};

export default CancellationTooltip;

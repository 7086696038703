import React, { useEffect } from 'react';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleCustomerInfoChange } from '../ShoppingCartSliceV2';
export const generateUUID = () => {
  const currentTime = Date.now();
  const number = Math.floor(Math.random() * 10000000000000 + 1);
  // CREATION TIME + RANDOM NUMBER
  return String('t' + currentTime + '_' + number);
};
const useHandleCheckout = (isCheckoutPage: boolean) => {
  const { shoppingCart } = useSelector(
    (state: RootState) => state.shoppingCart
  );
  const { settings } = useSelector((state: RootState) => state.shoppingCart);
  const { customerInfo } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );

  const customerInfoModifiedKeys = {
    customer_phone: customerInfo.phone,
    customer_first_name: customerInfo.first_name,
    customer_last_name: customerInfo.last_name,
    customer_email: customerInfo.email,
    user_comment: customerInfo.comment,
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleCheckout = () => {
    const uuid = generateUUID();
    const prevData = localStorage.getItem('cart');
    const parsedPrevData = JSON.parse(prevData ?? '{}');
    localStorage.setItem(
      'cart',
      JSON.stringify({
        ...parsedPrevData,
        [uuid]: { shoppingCart, time: Date.now(), settings },
      })
    );

    navigate('/checkout/' + uuid);
  };

  useEffect(() => {
    if (!isCheckoutPage) {
      dispatch(handleCustomerInfoChange(customerInfoModifiedKeys));
    }
  }, [customerInfo, dispatch]);

  return handleCheckout;
};

export default useHandleCheckout;

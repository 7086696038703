import React from 'react';
const CheckTrue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_125_57)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 0C6.27813 0 0 6.27813 0 14C0 21.7219 6.27813 28 14 28C21.7219 28 28 21.7219 28 14C28 6.27813 21.7219 0 14 0Z"
          fill="#16C150"
        />
        <path
          d="M11.3039 20.4039C9.51562 18.6156 7.73828 16.8109 5.94453 15.0227C5.74766 14.8258 5.74766 14.4977 5.94453 14.3008L8.00625 12.2391C8.20312 12.0422 8.53125 12.0422 8.72813 12.2391L11.6758 15.1867L19.2555 7.60156C19.4578 7.40469 19.7805 7.40469 19.9828 7.60156L22.05 9.66875C22.2523 9.87109 22.2523 10.1938 22.05 10.3906L12.0258 20.4039C11.8289 20.6063 11.5062 20.6063 11.3039 20.4039Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_125_57">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckTrue;

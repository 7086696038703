import apiClient from '@skygroup/shared/API/apiClient';
import { useQuery } from '@tanstack/react-query';
import endpoints from '../../utils/endpoints';
import { ApiStatus } from '@skygroup/shared/components/crud-helpers/ModelApiMessage';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAffiliate,
  updatePromotion,
} from '../ShoppingCart/ShoppingCartSlice';
import { RootState } from '../../store/store';
import isNumeric from "antd/lib/_util/isNumeric";

export const useGetPackageRoomPricing = () => {
  const params = useParams();

  let packageId: any = params.id;
  let packageAlias: any = params.alias;
  const hotelData = useQuery(
    ['package-room-pricing', packageId],
    async () => {
      const qParam = new URLSearchParams();
      if (packageId) {
        qParam.append('id', packageId);
      }
      if (params.promSecret) {
        qParam.append('promSecret', params.promSecret);
      }
      if (packageAlias) {
        qParam.append('alias', packageAlias);
      }
      return apiClient
        .get(`${endpoints.PACKAGE.GET_PRICE}?${qParam.toString()}`)
        .then((res) => {
          if (res.data?.status == ApiStatus.success) {
            return res?.data?.data;
          } else {
            throw res;
          }
        });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return hotelData;
};

export const useGetPackageInfo = () => {
  const params = useParams();
  const search = useLocation().search;
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(search);
  let packageId: any = params.id;
  let packageAlias: any = params.alias;
  if (!packageId) {
    packageId = searchParams.get('id');
  }
  if (!packageId) {
    packageId = '';
  }
  const packageIdentifiers = searchParams.get('tid');
  const qParam = new URLSearchParams();
  if (packageId) {
    qParam.append('id', packageId);
  }
  if (packageIdentifiers) {
    qParam.append('identifier', packageId);
  }
  if (params.promSecret) {
    qParam.append('promSecret', params.promSecret);
  }
  if (params.affiliate) {
    qParam.append('affiliate', params.affiliate);
  }
  if (packageAlias) {
    qParam.append('alias', packageAlias);
  }
  const hotelData = useQuery(
    ['get-package', packageId],
    async () => {
      const qParam = new URLSearchParams();
      if (packageId) {
        qParam.append('id', packageId);
      }
      if (params.promSecret) {
        qParam.append('promSecret', params.promSecret);
      }
      if (packageAlias) {
        qParam.append('alias', packageAlias);
      }

      if (params.promSecret) {
        dispatch(updatePromotion({ prom: params.promSecret }));
      }

      if (params.affiliate) {
        dispatch(updateAffiliate({ affiliate: params.affiliate }));
      }

      return apiClient
        .get(`${endpoints.PACKAGE.GET}?${qParam.toString()}`)
        .then((res) => {
          if (res.data?.status == ApiStatus.success) {
            return res?.data?.data;
          } else {
            throw res.data?.data;
          }
        });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return hotelData;
};



export const useGetPackageInfoMS = () => {
  const params = useParams();
  let packageId: any = params.id;
  const { adult, child, infant } = useSelector((state: RootState) => state.search.searchParams);

  const packageData = useQuery(
    ['get-package-ALP',packageId],
    async () => {
      const body = {
     identifier : packageId,
     tenantId: 8,
          //Number(process.env.REACT_APP_TENANT_ID),
     adult,
     child,
     infant,
     };
      return apiClient
        .post(`${endpoints.PACKAGE.GET_MS}`,body )
        .then((res) => {
            return res?.data;
        });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled:false
    }
  );

  return packageData;
};

export const useGetProductTermsMS = (identifier: string) => {
  const data = useQuery(
    ['get-product-terms-ms', identifier],
    async () => {
      return apiClient
        .post(`${endpoints.PACKAGE.GET_PRODUCT_TERMS_MS}`,{identifier: identifier})
        .then((res) => {
            return res?.data;
        });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled:false
    }
  );

  return data;
};


import React from 'react';

const SeatingSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="39"
      viewBox="0 0 34 39"
      fill="none"
    >
      <g clipPath="url(#clip0_11_53)">
        <path
          d="M24.8687 32.7097H11.3291C11.2788 32.7097 11.2284 32.7097 11.178 32.6908C2.18522 30.4452 0.081867 11.5868 0 10.7879V4.10758C0 2.9942 0.459715 1.90597 1.25949 1.13226C2.04668 0.371132 3.07946 -0.0314487 4.16262 0.00629322C8.45749 0.14468 8.81645 2.87468 8.81645 5.66129C8.81645 8.44791 7.4499 10.3539 6.92721 10.9452V12.9581C9.79885 16.0026 13.1743 23.5069 14.1693 25.7966H24.875C26.7831 25.7966 28.3386 27.3503 28.3386 29.2563C28.3386 31.1623 26.7831 32.716 24.875 32.716H24.8687V32.7097ZM11.411 31.4516H24.8687C26.0841 31.4516 27.0728 30.464 27.0728 29.25C27.0728 28.036 26.0841 27.0484 24.8687 27.0484H13.7474C13.4955 27.0484 13.2688 26.8974 13.168 26.6647C12.7335 25.6456 8.84164 16.6442 5.84405 13.65C5.72439 13.5305 5.66142 13.3732 5.66142 13.2034V10.6873C5.66142 10.5174 5.73069 10.3476 5.85664 10.2281C5.86924 10.2155 7.55066 8.56742 7.55066 5.655C7.55066 2.74258 7.07205 1.35242 4.11224 1.25807C3.36285 1.23291 2.67012 1.50968 2.12854 2.03178C1.56807 2.57274 1.2532 3.32758 1.2532 4.10129V10.681C1.35395 11.5553 3.48879 29.3632 11.411 31.4516Z"
          fill="black"
        />
        <path
          d="M23.2943 23.249C22.9479 23.249 22.6646 22.966 22.6646 22.62V21.5821C22.6646 20.7832 21.9781 20.129 21.1406 20.129H6.29115C5.94479 20.129 5.66141 19.846 5.66141 19.5C5.66141 19.154 5.94479 18.871 6.29115 18.871H21.1406C22.6772 18.871 23.924 20.085 23.924 21.5821V22.62C23.924 22.966 23.6407 23.249 23.2943 23.249Z"
          fill="black"
        />
        <path
          d="M18.8861 15.0968C15.7626 15.0968 13.2184 12.5555 13.2184 9.43548V5.66129C13.2184 2.54129 15.7626 0 18.8861 0C22.0096 0 24.5538 2.54129 24.5538 5.66129V9.43548C24.5538 12.5555 22.0096 15.0968 18.8861 15.0968ZM18.8861 1.25806C16.4553 1.25806 14.4779 3.23323 14.4779 5.66129V9.43548C14.4779 11.8635 16.4553 13.8387 18.8861 13.8387C21.3169 13.8387 23.2943 11.8635 23.2943 9.43548V5.66129C23.2943 3.23323 21.3169 1.25806 18.8861 1.25806Z"
          fill="black"
        />
        <path
          d="M13.8481 5.03226H23.9241V6.29032H13.8481V5.03226Z"
          fill="black"
        />
        <path
          d="M18.8861 8.80645C18.5397 8.80645 18.2563 8.52339 18.2563 8.17742V5.66129C18.2563 5.31532 18.5397 5.03226 18.8861 5.03226C19.2325 5.03226 19.5158 5.31532 19.5158 5.66129V8.17742C19.5158 8.52339 19.2325 8.80645 18.8861 8.80645Z"
          fill="black"
        />
        <path
          d="M18.8861 39C18.8294 39 18.7727 39 18.7097 38.9748C14.0055 37.635 12.6579 32.4518 12.6012 32.2316C12.5193 31.8919 12.7209 31.5523 13.0609 31.4705C13.3947 31.3887 13.741 31.59 13.8229 31.9234C13.8355 31.9674 15.0572 36.6223 19.0498 37.7608C19.3836 37.8552 19.5788 38.2074 19.4843 38.5408C19.4025 38.8176 19.1506 39 18.8798 39H18.8861Z"
          fill="black"
        />
        <path
          d="M33.3703 39H4.40193C4.05557 39 3.77219 38.7169 3.77219 38.371C3.77219 38.025 4.05557 37.7419 4.40193 37.7419H33.3703C33.7166 37.7419 34 38.025 34 38.371C34 38.7169 33.7166 39 33.3703 39Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_53">
          <rect width="34" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SeatingSVG;

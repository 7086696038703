import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { CommissionType } from '../ShoppingCartSliceV2';

const useUpdateCommissionNeeded = () => {
  const { ShoppingCartV2, agent_commission }: any = useSelector(
    (state: RootState) => state.shoppingCartV2
  );
  const isUpdateCommissionNeeded = () => {
    if(!ShoppingCartV2?.agent_commission) return false;
    if (agent_commission.type === ShoppingCartV2?.agent_commission?.type) {
      if (agent_commission.type === CommissionType.PerPax) {
        if (agent_commission.per_pax === ShoppingCartV2?.agent_commission?.per_pax)
          return false;
        return true;
      }
      if (agent_commission.percentage === ShoppingCartV2?.agent_commission?.percentage)
        return false;
      return true;
    }
    return true;
  };

  return isUpdateCommissionNeeded;
};

export default useUpdateCommissionNeeded;

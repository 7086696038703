import React, { useState } from 'react';
import { Product, handleRemoveFlightBundle, handleAdjustGuestIndexes, handleAddBaggageToShoppingCart, CommissionType, setWholeCart } from '../../ShoppingCartSliceV2';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Link } from 'react-router-dom';
import { flightLink } from '../../../../utils/LinkGenerator';
import { FlightType } from '../../../../interfaces/flightInterface';
import moment from 'moment';
import { IoClose } from 'react-icons/io5';
import './shoppingCartFlightPackage.less';
import FlightCard2 from '../../../SearchDatePicker/widgets/SearchResults/FlightCard2';
import ProductPreviewComponent from '../../widgets/ProductPreviewComponent';
import { Button } from 'antd';
import BaggageManagement from '@skygroup/shared/components/baggageManagment/baggageManagment';
import useHandleManageBaggage from '../../hooks/useHandleManageBaggage';
import useSiteLang from '../../../../hooks/useSiteLang';

interface Props {
  product: Product;
  isCheckoutPage: boolean;
}

const ShoppingCartFlightPackage = ({ product, isCheckoutPage }: Props) => {
  const { shoppingCartTranslations: t, manageBaggage } =
    useSelector((data: RootState) => data.config.translations);

  const { ShoppingCartV2 } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );

  const { siteConfig } = useSelector((data: RootState) => data.config.config);

  const generateFlightLink = (data: any) => {
    const startDate = moment(data.flightsInfo.flightFrom.departure_date * 1000);

    const departureDate = startDate.format(siteConfig.dateFormat);
    const from = encodeURIComponent(departureDate);

    const adultCount = data.pax.adult;
    const childCount = data.pax.child;
    const infantCount = data.pax.infant;

    const departureAirport = data.flightsInfo.flightFrom[0].from.code;
    let link = `?from=${from}&adult=${adultCount}&oneWay=1&departure=${departureAirport}&child=${childCount}&infant=${infantCount}`;

    if (data.flightsInfo.flightBack) {
      const endDate = moment(data.flightsInfo.flightBack[0].departure_date * 1000);
      const returnDate = endDate.format(siteConfig.dateFormat);
      const to = encodeURIComponent(returnDate);
      const destinationAirport = data.flightsInfo.flightBack[0].to.id;
      link += `&to=${to}&oneWay=0&destination=${destinationAirport}`;
    }
    return link;
  };

  const searchParams = generateFlightLink(product.product_data);

  const { config } = useSelector((data: RootState) => data.config);

  const { direction } = useSiteLang({
    siteConfig: config?.siteConfig,
  });

  const dispatch = useDispatch();

  const handleRemoveFlight = () => {
    dispatch(handleRemoveFlightBundle(product));
    dispatch(handleAdjustGuestIndexes(product));
  };

  const flightFrom = product.product_data.flightsInfo.flightFrom[0];
  const flightBack = product.product_data.flightsInfo.flightBack[0]

  const flightFromSubProducts = product.subProducts?.[0].subProducts;
  const flightBackSubProducts = product.subProducts?.[1].subProducts;

  const [isManageBaggageOpen, setIsManageBaggageOpen] = useState(false);
  const {
    handleAddBaggage,
    flightBackBaggage,
    flightFromBaggage,
    isRoundTrip,
    isShowManageBaggageButton,
  } = useHandleManageBaggage(product);
  return (
    <div>
      <div className="shopping-cart-flights shopping-cart-flights-package">
      <div>
          <b
            className="product-title"
          >
            {t?.flights}{' '}
            {isShowManageBaggageButton && <Button
              onClick={() => setIsManageBaggageOpen(true)}
              className="btn-style-4"
            >
              {t.manageBaggage}
            </Button>}
          </b>
        </div>

        {isManageBaggageOpen && (
          <BaggageManagement
            guests={ShoppingCartV2.guests}
            onChange={handleAddBaggage}
            flightBackBaggage={flightBackBaggage}
            flightFromBaggage={flightFromBaggage}
            mainProduct={product}
            shoppingCart={ShoppingCartV2}
            onClose={(cart: any) => {
              dispatch(setWholeCart(cart));
            }}
            isManageBaggageOpen={isManageBaggageOpen}
            setIsManageBaggageOpen={setIsManageBaggageOpen}
            onSave={() => setIsManageBaggageOpen(false)}
            isRoundTrip={isRoundTrip}
            t={manageBaggage}
            direction={direction}
          />
        )}

        {/* <Link
          to={flightLink(
            FlightType.BundleFlight,
            product.identifier,
            searchParams
          )}
          className="flight-link"
        >
          {flightFrom.from.name} →{' '}
          {flightFrom.to.name}
          {flightBack
            ? ` → ${flightFrom.from.name}`
            : ''}
        </Link> */}
        <ProductPreviewComponent
          productPath={flightLink(
            FlightType.BundleFlight,
            product.identifier,
            generateFlightLink(product.product_data)
          )}
          label={
            <>
              {flightFrom.from.name} →{' '}
              {flightFrom.to.name}
              {flightBack
                ? ` → ${flightFrom.from.name}`
                : ''}
            </>
          }
            labelClassName={'flight-link'}
        />

        <div className="flights-package-wrapper">
          <FlightCard2
            flightData={product.product_data.flightsInfo}
            isShowFlightButton={false}
            handlePurchase={() => {}}
            flightBackSubProducts={flightBackSubProducts}
            flightFromSubProducts={flightFromSubProducts}
            isMinimized/>

          {!isCheckoutPage && (
            <IoClose
              size={15}
              onClick={handleRemoveFlight}
              className="delete-product ease"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartFlightPackage;

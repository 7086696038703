import { FaLink, FaSms, FaWhatsapp } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import React from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
// @ts-ignore
import shareIcon from '../../assets/share.png';
import { AgentActionTypes } from '../AgentActions';
import { Product } from '../../ShoppingCartSliceV2';
import moment from 'moment';
import { MainProductType } from '../../interfaces';
import './agentActionShare.less';
import {message} from "antd";

interface Props {
  setSelectedAction: React.Dispatch<
    React.SetStateAction<AgentActionTypes | null>
  >;
}

const AgentActionShare = ({ setSelectedAction }: Props) => {
  const { shoppingCartTranslations: t, general: general_t, boards: t_boards, } = useSelector(
    (data: RootState) => data.config.translations
  );
  const { ShoppingCartV2, customerInfo } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );
  const { siteConfig } = useSelector((data: RootState) => data.config.config);

  function formatProduct(product: Product, depth: number) {
    let result = '';
    const dashPrefix = '-'.repeat(depth);
    switch (product.type) {
      case MainProductType.Package:
        result += `${dashPrefix} ${product.name ?? t.package}, ${
          product.product_data.nights
        } ${t.nights}, ${moment(product.product_data.dates.start * 1000).utc().format(
          siteConfig.dateFormat
        )} - ${moment(product.product_data.dates.end * 1000).utc().format(
          siteConfig.dateFormat
        )}\n`;
        break;
      case MainProductType.Hotel:
        result += `${dashPrefix} מלון ${product.name}\n`;
        // product.subProducts?.forEach((subProduct) => {
        //   result += formatProduct(subProduct, depth + 1);
        // });
        break;
        case MainProductType.Room:
          debugger
            const board = product.product_data?.price?.board ?? product.product_data.board ?? '';
            const boardText = `בסיס ${t_boards?.[board] ?? board}`;
            const firstRoom =  { adult: product.product_data.price.adult, child:product.product_data.price.child, infant:product.product_data.price.infant};
            const allRooms = product.product_data.multi_room_guests ?
                [ firstRoom, ...product.product_data.multi_room_guests] : [ firstRoom ];

            allRooms.map(pax => {
                result += `${dashPrefix} חדר ${product.name}\n`;
                result += ` ${pax.adult} ${general_t.adults}`;

                if (pax.child) {
                    result += `, ${pax.child} ${general_t.children}`;
                }
                if (pax.infant) {
                    result += `, ${pax.infant} ${general_t.infants}`;
                }
                result += " - " + boardText;
                    // result += ` => ${general_t.price}: ${product.total_price}$\n`;
                result += `\n`;
            });
        break;
      case MainProductType.Flight:
      case MainProductType.oneWayFlight:
        const flight =
          product.product_data.flightFrom ?? product.product_data.flightBack;

        const isSuitCaseIncluded = flight[0].baggage.is_include_suitcase;
        const isTrollyIncluded = flight[0].baggage.is_include_trolly;

        let hasAdditionalTrolly = 0;
        let hasAdditionalSuitCase = 0;

        let suitcaseText = "";
        product.subProducts?.forEach((subProduct) => {
              switch (subProduct.type) {
                  case MainProductType.Suitcase:
                      hasAdditionalSuitCase = subProduct.related_guest_indexes.length;
                      break;
                  case MainProductType.Trolly:
                      hasAdditionalTrolly = subProduct.related_guest_indexes.length;
                      break;
              }
        });
           // ${isTrollyIncluded ? general_t. : general_t.}\n\n`;
        if (isSuitCaseIncluded) {
            suitcaseText += general_t.suitcaseIsIncluded;
            if (hasAdditionalSuitCase > 0) {
                suitcaseText += ' + ';
                suitcaseText += general_t.additionalSuitcases.replace("X",`${hasAdditionalSuitCase}`);
            }
        } else {
            if (hasAdditionalSuitCase > 0) {
                suitcaseText += general_t.additionalSuitcases.replace("X",`${hasAdditionalSuitCase}`);
            } else {
                suitcaseText += general_t.suitcaseIsNotIncluded;
            }
        }

          suitcaseText += ", ";

          if (isTrollyIncluded) {
              suitcaseText += general_t.trollyIsIncluded;
              if (hasAdditionalTrolly > 0) {
                  suitcaseText += ' + ';
                  suitcaseText += general_t.additionalTrolly.replace("X",`${hasAdditionalTrolly}`);
              }
          } else {
              if (hasAdditionalTrolly > 0) {
                  suitcaseText += general_t.additionalTrolly.replace("X",`${hasAdditionalTrolly}`);
              } else {
                  suitcaseText += general_t.trollyIsNotIncluded;
              }
          }

        result += `${dashPrefix} ${product.type == MainProductType.oneWayFlight ? general_t.flight : (product.product_data.flightFrom ? general_t.outboundFlight : general_t.returnFlight)} ${flight[0].from.name} → ${flight[flight.length - 1].to.name} 
 ${flight[0].airline.name}  ${flight[0].airline.id}-${flight[0].flight_number}
 ${moment(flight[0].departure_date * 1000 ).utc().format(siteConfig.dateFormat + ' HH:mm')}
 ${suitcaseText}\n\n`;

        break;
      case MainProductType.Flights:
        result += `${dashPrefix} ${general_t.bundleFlight}\n`;

        product.subProducts?.forEach((subProduct) => {
          formatProduct(subProduct, depth + 1);
        });
        break;
      case MainProductType.Activity:
        result += `${dashPrefix} - ${product.name}\n`;
        break;
      default:
        break;
    }
    if (product.subProducts && product.subProducts.length > 0) {
      product.subProducts.forEach((subProduct) => {
        result += formatProduct(subProduct, depth + 1);
      });
    }
    return result;
  }

  function formatAllProducts(shoppingCartV2: any) {
    let result = '';
      shoppingCartV2.products.forEach((product: Product) => {
      result += formatProduct(product, 0);
    });
      result += 'סה״כ ' + Math.round(shoppingCartV2.total_price) + String.fromCharCode(160) + shoppingCartV2.currency_sign + ' (נכון להיום) \n';
      if (shoppingCartV2.orderSecret) {
          result += 'לינק להצעה \n' + window.location.origin + `/order/manage?id=${shoppingCartV2.orderSecret}`;
      }
    return result;
  }

  const handleCopy = () => {
    const formattedProductInfo = formatAllProducts(ShoppingCartV2);
    navigator.clipboard.writeText(formattedProductInfo).then( (r) => {
        message.success("הצעת המחיר הועתקה בהצלחה");
    }).catch( (e) => {
        window.parent.postMessage({ action: 'copy', text: formattedProductInfo }, "*");
    });

  };


  const handleWhatsappClick = () => {
    const formattedProductInfo = formatAllProducts(ShoppingCartV2.products);
    const phone = customerInfo.phone.slice(1);
    const link = `whatsapp://send?text=${encodeURIComponent(formattedProductInfo)}&phone=+972${phone}`;
    window.open(link);
  };

  return (
    <div className="agent-action-share">
      <div className="share-header">
        <span>
          <img src={shareIcon} />
          <div className="text">
            <span>{t.findQuoteFor}</span>
            <span>{t.linkIsGive}</span>
          </div>
        </span>
        <IoClose size={20} onClick={() => setSelectedAction(null)} />
      </div>

      {customerInfo.phone && (
        <div className="option" onClick={handleWhatsappClick}>
          <FaWhatsapp size={20} color="#15bd25"/>
          <span>{t.sendByWhatsApp}</span>
        </div>
      )}
      <div className="option" onClick={handleCopy}>
        <FaLink size={20} color="#0062ff" />
        <span>{t.copyText} </span>
      </div>
      {customerInfo.phone && (
        <div className="option">
          <FaSms size={20} color="#000" />
          <span>{t.sendBySMS}</span>
        </div>
      )}
    </div>
  );
};

export default AgentActionShare;

import { Badge } from 'antd';
import React from 'react';
import { FaSuitcase } from 'react-icons/fa';
import { MdLuggage } from 'react-icons/md';
import './flightBaggage.less';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';

interface Props {
  isIncludeTrolly?: boolean | number;
  insIncludeSuitCase?: boolean | number;
  color?: string;
}

const FlightBaggage = ({
  isIncludeTrolly,
  insIncludeSuitCase,
  color,
}: Props) => {
  const { searchDatePicker: t } = useSelector(
    (data: RootState) => data.config.translations
  );
  return (
    <div className="baggage">
      <Badge
        size="small"
        color={insIncludeSuitCase ? '#8cca56' : '#f58087'}
        count={insIncludeSuitCase ? insIncludeSuitCase : undefined}
        dot
      >
        <FaSuitcase size={20} color={color ?? undefined} />
        <span
          style={{
            color: insIncludeSuitCase ? '#8cca56' : '#f58087',
          }}
        >
          {insIncludeSuitCase ? t.suitcaseIsIncluded : t.suitcaseIsNotIncluded}
        </span>
      </Badge>
      <Badge
        size="small"
        color={isIncludeTrolly ? '#8cca56' : '#f58087'}
        count={isIncludeTrolly ? isIncludeTrolly : undefined}
        dot
      >
        <MdLuggage size={20} color={color ?? undefined} />
        <span
          style={{
            color: isIncludeTrolly ? '#8cca56' : '#f58087',
          }}
        >
          {isIncludeTrolly ? t.trollyIsIncluded : t.trollyIsNotIncluded}
        </span>
      </Badge>
    </div>
  );
};

export default FlightBaggage;

import React from 'react';
import './flightVerticalOutLineSection.less';

import { FaPlane} from 'react-icons/fa';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';

interface Props {
  flightData: any;
}

const FlightVerticalOutLineSection: React.FC<Props> = ({
  flightData,
}) => {
  const { siteConfig } = useSelector((state: RootState) => state.config.config);
  const { searchDatePicker: t, general: t_general } = useSelector(
    (data: RootState) => data.config.translations
  );
  const renderTimelineItems = () => {
    if (flightData.length < 1) {
      return <></>;
    }
    const itemsToRender: JSX.Element[] = [];

    for (let i = 0; i < flightData.length; i++) {
      const depMomentTime = moment(flightData[i]?.departure_date * 1000);
      const arrMomentTime = moment(flightData[i]?.arrival_date * 1000);

      const departureData = {
        date: depMomentTime.format('HH:mm'),
        location: flightData[i]?.from?.name,
      };
      const arrivalData = {
        date: arrMomentTime.format('HH:mm'),
        location: flightData[i]?.to?.name,
      };

      const operatedAirline = flightData[i]?.operated_airline;
      const airlineImage = flightData[i]?.airline?.image;

      const flightDuration = moment.duration(arrMomentTime.diff(depMomentTime));
      const flightDurationHours = Math.floor(flightDuration.asHours());
      const flightDurationMinutes = flightDuration.minutes();
      const flightDurationDays = Math.floor(flightDuration.asDays());

      itemsToRender.push(
        <>
          <div className="timeline-title">
            {airlineImage ? (
              <img src={airlineImage} className="logo" />
            ) : (
              <FaPlane style={{ fontSize: '24px' }} />
            )}
            {operatedAirline && <div className='names-wrapper'>
              <span className="airlineName">{operatedAirline?.name}</span>
              <span className="flight-number">{`${t.flight_number}: ${flightData?.[i]?.flight_number}`}</span>
            </div>}
          </div>
          <Timeline mode={siteConfig?.direction === 'ltr' ? 'right' : 'left'}>
            <Timeline.Item
              key={i + departureData?.date + departureData?.location}
              color={'rgba(0, 0, 0, .06)'}
            >
              <div className={'timeline-item-row'}>
                <div className="hour">{departureData?.date}</div>
                <div className="location">{departureData?.location}</div>
              </div>
              <div className="flight-duration">
                <ClockCircleOutlined style={{ fontSize: '16px' }} />
                {`${flightDurationHours}${t_general.h} ${flightDurationMinutes}${t_general.min}`}
              </div>
            </Timeline.Item>
            <Timeline.Item
              key={i + arrivalData?.date + arrivalData?.location}
              color={'rgba(0, 0, 0, .06)'}
            >
              <div className={'timeline-item-row'}>
                <div className="hour">
                  {arrivalData?.date}
                  {!!flightDurationDays && (
                    <div className="days-difference">+{flightDurationDays}</div>
                  )}
                </div>
                <div className="location">{arrivalData?.location}</div>
              </div>
            </Timeline.Item>
          </Timeline>
        </>
      );

      // // CALCULATE WAIT TIME
      if (i !== flightData.length - 1) {
        const nextDepMomentTime = moment(
          flightData[i + 1]?.departure_date * 1000
        );
        const waitTime = moment.duration(nextDepMomentTime.diff(arrMomentTime));
        const waitTimeHours = Math.floor(waitTime.asHours());
        const waitTimeMinutes = waitTime.minutes();

        const waitType = waitTimeHours < 3 ? t.short_wait : t.long_wait;

        itemsToRender.push(
          <div
            className={`airport-wait-duration ${
              waitTimeHours < 3 ? '' : 'long-wait'
            }`}
          >
            <span className="time">
              {waitTimeHours}
              {t_general.h} {waitTimeMinutes}
              {t_general.min}
            </span>
            <span>{t.connect_in_airport}</span>
            <span className="wait-type">{waitType}</span>
          </div>
        );
      }
    }

    return itemsToRender;
  };

  const arrivalTime = moment(
    flightData[flightData.length - 1].arrival_date * 1000
  ).format('ddd, MMM D, YYYY');

  const durationMomentObj = moment.duration(
    moment(flightData[flightData.length - 1].arrival_date * 1000).diff(
      moment(flightData[0].departure_date * 1000)
    )
  );
  const fullDuration = `${Math.floor(
    durationMomentObj.asHours()
  )}h ${durationMomentObj.minutes()}min`;

  return (
    <div className={'flight-stop-details-wrapper'}>
      <div className={'flight-timeline-wrapper'}>
        {renderTimelineItems()}
        <div className={'flight-general-info-wrapper'}>
          <div>
            <strong>{t.arrives}: </strong>
            {arrivalTime}
          </div>
          <div>
            <strong>{t.journey_duration}: </strong>
            {fullDuration}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightVerticalOutLineSection;

import React from 'react';

const CheckFalse = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0C6.27813 0 0 6.27813 0 14C0 21.7219 6.27813 28 14 28C21.7219 28 28 21.7219 28 14C28 6.27813 21.7219 0 14 0Z"
        fill="#ED5E5E"
      />
      <path
        d="M9.80001 18.2L18.2 9.8"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.80001 9.80008L18.2 18.2001"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckFalse;

import React from 'react';
import './agentCommissionSearch.less';
import { AgentAdditionalInfo } from '../../modules/ShoppingCart/agentActions/AgentActions';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { PriceView } from '../../hooks/useGetGrossPrice';
import { Tooltip } from 'antd';

interface Props {
  centered?: boolean;
  marginTop?: number;
  agentAdditionalInfo: AgentAdditionalInfo;
  isMoreDealsVisible?: boolean;
  isInInternalPackageBox?: boolean;
}

const AgentCommissionSearch = ({
  centered = false,
  marginTop = 0,
  agentAdditionalInfo,
  isMoreDealsVisible,
  isInInternalPackageBox,
}: Props) => {
  const { agentMode, agent } = useSelector(
    (data: RootState) => data.config.agentInfo
  );
  const { agentCommission: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const price_view = agent?.price_view;

  if (!agentMode) return null;
  return (
    <div
      className={`agent-commission-search ${
        isInInternalPackageBox ? 'internal-package-box-view' : 'default-view'
      } ${centered ? 'centered' : ''} ${
        isMoreDealsVisible ? 'isMoreDealsVisible' : ''
      }`}
      style={{
        marginTop: marginTop,
      }}
    >
      <div className="supplier">
        <b>
          {t.supplier}
          {isInInternalPackageBox ? ': ' : ''}
        </b>
        <span>{agentAdditionalInfo?.supplier_name}</span>
      </div>
      <div className="commission">
        {/* <Tooltip
          title={
            price_view === PriceView.Net
              ? `${agentAdditionalInfo?.suggested_commission} ${t.commission}`
              : ''
          }
        > */}
        <b>
          {price_view === PriceView.Net ? t.netPrice : t.commission}
          {isInInternalPackageBox ? ': ' : ''}
        </b>
        {/* </Tooltip> */}

        {price_view === PriceView.Gross && (
          <span>{agentAdditionalInfo?.suggested_commission}%</span>
        )}
      </div>
    </div>
  );
};

export default AgentCommissionSearch;

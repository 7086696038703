const translations = {
    "status": "success",
    "data": {
        "lang": "he_IL",
        "app": "hotel",
        "translation": {
            "crud": {
                "validationFailed" : "Validation failed",
                "operationError" : "Error occurred",
                "emptyList" : "No Data Found",
                "failToLoad" : "Error occurred on loading",
                "modelNotFound" : "Not Found",
            },
            "contactUs": {
                "requestForQuotation":"בקשה להצעת מחיר",
                "problemWithExistingOrder":"בעיה בהזמנה קיימת",
                "customerService":"שירות לקוחות",
                "other":"אחר",
                "messageReceivedSuccessfully":"ההודעה נשלחה בהצלחה",
                "email": "אימייל",
                "phone": "טלפון",
                "fullName": "שם מלא",
                "writeUsMessage": "כתבו לנו הודעה",
                "send": "שלח",
                "phoneRequired": "נא להזין מספר טלפון",
                "emailRequired": "נא להזין כתובת אימייל",
                "fullNameRequired": "נא להזין שם מלא",
                "messageRequired": "נא להזין הודעה",
                "aboutUs":'עלינו'
            },
            "cart": {
                "addToCart": "הוסף לסל",
            },
            "customerSupport": {
                "customerService": "שירות לקוחות",
                "sendMessageAndGetNotified": "שלחו לנו הודעה ונציג יחזור אליכם בהקדם",
                "callUs": "צלצלו אלינו - ",
            },
            "roomInfo": {
                "info": "תאור החדר",
                "viewAllThePhotos": "צפו בכל התמונות",
                "roomSize": "מ״ר - גודל חדר",
                "peopleInTheRoom": "אנשים בחדר",
                "doubleBed": "מיטה זוגית",
                "flights":"טיסות",
            },
            "conceptsList":{
                "conceptsListTitle":"חבילות הנופש שלנו, מהיום רוכשים מלון עם אטרקציות!",
                "includesActivities":"אטרקציות מתנה",
                "includesActivity":"כולל אטרקציה מתנה",
                "includeInPackage":"החבילה כוללת",
                "transfer": "כולל העברות",
                "includesTransfer": "כולל העברות",
                "transferContent":"העברות הלו״ש במחיר החבילה",
                "baseBoard": "בסיס אירוח",
                "autoApprove": "באישור מיידי",
                "airways": "חברת תעופה",
                "flightFrom": "טיסה הלוך",
                "flightBack": "טיסה חזור",
                "toOrder": "להזמנה",
                "nights":"לילות",
                "noPackagesFound":"לא נמצאו חבילות",
                "moreDeals":"אופציות נוספות",
                "pricePerPerson":"תוספת לאדם",
            },
            "viewFlight":{
                "show_tickets_with_different_fare_combinations": "הצג סוגי כרטיס עם הלוך חזור שונה",
                "ticketFare":"כרטיס",
                "ticketInfo":"פירוט כרטיס",
                "termsAndConditions": "תנאים והגבלות",
                "ifTicketIncludeSeat": "אם הכרטיס כולל מושב - הלקוח יצטרך לבחור אותו באתר חברת התעופה",
                "inCaseOfChange": "במקרה של שינוי - הלקוח יצטרך לשלם תוספת אם הכרטיס המעודכן יקר יותר, כמו כן ישנה עמלת שירות נוספת בהתאם לתנאי האתר והשירותים",
                "inCaseOfCancellation": "במקרה של ביטול - תהיה עמלת שירות נוספת בהתאם לתנאי האתר והשירותים",
                "allFares":"כל התעריפים",
                "fareChangedSuccessfully":"מחיר הנסיעה השתנה בהצלחה",
                "openFares":"תעריפים פתוחים",
                "selectedFare":"החלף סוג כרטיס ",
                "error4305Title":"אופס, אזלו המקומות לטיסה",
                "error4305Content":`אנו מתנצלים, אך כנראה שנגמרו המקומות להצעה זו ולכן אין באפשרותנו להתקדם לרכישה.
                הנך מועבר לעמוד הראשי.`,
                "TC":"תנאים",
                "flights":"טיסות",
                "title":"הטיסה הנבחרת, ודאו פרטים והמשיכו לתשלום",
                "addBaggage":"הוסף מזוודה",
                "closeAddBaggage":"הסתר אופציות",
                "addTrolly":"הוסף טרולי",
                "somethingWetWrong":"משהו השתבש",
                "suitcasePrice":"מחיר מזוודה",
                "trollyPrice":"מחיר טרולי",
                "totalPerPassenger":"סך הכל לנוסע",
                "search": "חיפש",
                "updatePassengers":"עדכן כמות נוסעים",
                "seating":"הושבה",
                "cancelation":"ביטול כרטיס",
                'meal':"ארוחות",
                'trolly':"תיק יד",
                'suitcase':"מזוודה",
                'changeFlight':"שינוי כרטיס",
                "extension":"תוספת",
                "totalToBePaid":"סה״כ לתשלום",
                "choose":"בחרו",
                "checkAmenities":"לבדוק מתקנים",
                "description":"תיאור",
                "amenityType":"סוג תוספת",
                "fullInfo":"מידע מלא",
                "flightFromAmenities":"טיסה מהשירותים",
                "flightBackAmenities":"שירותי טיסה חזרה",
                "BAGGAGE": "מטען",
                "2 CHECKED BAGS UP TO 32KG EACH": "2 תיקי מטען מסומנים עד 32 ק״ג כל אחד",
                "2 CABIN BAGS UP TO 8KG": "2 תיקי תא הנוסעים עד 8 ק״ג",
                "BRANDED_FARES": "כרטיסים ממותגים",
                "STANDARD SEAT RESERVATION": "הזמנת מושב סטנדרטי",
                "MILEAGE ACCRUAL": "צבירת נקודות",
                "MEAL": "ארוחה",
                "SNACK": "חטיף",
                "COMPLIMENTARY FOOD AND BEV": "אוכל ושתייה ללא תשלום",
                "ENTERTAINMENT": "בידור",
                "MAGAZINES / NEWSPAPER": "מגזינים / עיתונים",
                "LOUNGE": "טרקלין",
                "LOUNGE ACCESS": "גישה לטרקלין",
                "TRAVEL_SERVICES": "שירותי נסיעות",
                "PRIORITY BAGGAGE": "תיקי עדיפות",
                "PRIORITY CHECK IN": "מעבר מהיר צ'ק אין",
                "PRIORITY SECURITY": "מעבר מהיר ביטחון",
                "PRIORITY BOARDING": "מעבר מהיר עלייה למטוס",
                "IN FLIGHT ENTERTAINMENT": "בידור בטיסה",
                "CHANGEABLE TICKET": "שינוי כרטיס",
                "BASIC SEAT": "מושב בסיסי",
                "PRE_RESERVED_SEAT": "מושב שמור מראש",
                "PRIVACY SEAT BUSINESS CLASS": "מושב פרטיות מחלקת עסקים",
                "CHANGE BEFORE DEPARTURE": "שינוי לפני היציאה",
                "CHANGE AFTER DEPARTURE": "שינוי לאחר היציאה",
                "REFUND BEFORE DEPARTURE": "החזר לפני היציאה",
                "REFUND AFTER DEPARTURE": "החזר לאחר היציאה"

            },
            "room": {
                "roomSize": "גודל החדר",
                "bedType": "סוג מיטה",
                "breakfastIncluded": "כולל ארוחת בוקר",
                "noAvailabilityOnSelectedDates": "אין זמינות לחדר זה בתאריכים הנבחרים"
            },
            "promotion": {
                "modelNotFound": "דף לא נמצא",
                "failToLoad": "לא הצלחנו לטעון את העמוד!",
            },
            "searchDatePicker": {
                "approveSelection": "אישר נוסעים",
                "room":"חדר",
                'addRoom':"הוסף חדר",
                "removeRoom":'להסיר חדר',
                "indirectFlight": "עם עצירות",
                "directFlightLabel": "טיסות ישירות",
                "city": "City",
                "hotel": "Hotel",
                "searchForDestination":"חפש יעד",
                "searchByDestinationAndHotel": "חיפוש יעד או מלון",
                "noResultsForThisDate":"לא נמצא חבילות לתאריך זה",
                "searchDestination":"חפש יעד",
                "pleaseSelectDestination":"אנא בחר יעד",
                "airlineInformation":"מידע על חברת התעופה",
                "airlineCode":"קוד",
                "title":"הזמינו את חופשת החלומות שלכם",
                "hotelOnly":"חיפוש חופשה בתאריכים",
                "hotelWithFlight":"חיפוש חופשה כולל טיסה",
                "oneWay":"כיוון אחד",
                "roundTrip":"הלוך ושוב",
                "selectDate":"בחרו תאריכים",
                "startDate":"תאריך התחלה",
                "endDate":"תאריך סיום",
                "flights":"חיפוש טיסות",
                "packages":"חבילות נופש",
                "hotels":"מלונות בחול",
                "israelPackage":"חופשה בארץ",
                "specialPackage":"חבילות מיוחדות",
                "passengers":"נוסעים",
                "adults":"מבוגרים",
                "adult":"מבוגר",
                "child":"ילד",
                "children":"ילדים",
                "infant":"תינוק",
                "infants":"תינוקות",
                "infantsWarningMessage":"תינוק צריך להיות פחות משנתיים מיום הטיסה הלוך וחזור",
                "select":"בחר",
                "noResults":"אין תוצאות",
                "checkout":"בצע הזמנה",
                "regularPrice":"מחיר רגיל",
                "from":"מ",
                "purchase":"לִרְכּוֹשׁ",
                "selectNewFlight":"בחר טיסה חדשה",
                "search": "חיפוש",
                "rooms": "חדרים",
                "composition": "הרכבים",
                "chooseComposition": "בחרו הרכבים",
                "numberOfGuests": "כמות אורחים",
                "checkOut": "צ׳ק אאוט",
                "checkIn": "צ׳ק אין",
                "whatVacation": "איזה חופשה",
                "youWant": "אתם רוצים",
                "vacationFriendsWith": "חופשה  חברים עם",
                "familyVacation": "חופשה משפחתית",
                "romanticVacation": "חופשה רומנטית",
                "notAvailable": "אין זמינות",
                "roomAvailableDuringTheWeek": "זמינות חדרים במהלך השבוע",
                "followingDates": "תאריכים הבאים",
                "vacation":"חופשה",
                "abroad":"בחו״ל",
                "inIsrael":"בארץ",
                "destination":"כל היעדים",
                "chooseDestination":"בחרו יעדים",
                "findDreamVacation":"חפשו את החופשה המושלמת בקליק.",
                "searchResTitle":"בחרו את החבילה המתאימה עבורכם",
                "directFlight":"טיסה ישירה ",
                "stop_1": "עצירה אחת",
                "stops": "עצירות",
                "activeFlight":"הטיסה מופעלת",
                "flightSearch":"חיפוש טיסה",
                "nights":"לילות",
                "until":"עד תאריך",
                "date":"תאריך",
                "untilYouSearchForFlightDate":"עד חיפוש טיסות תאריך",
                "destinationFrom":"המראה",
                "packageSearch":"חיפוש חבילה",
                "destinationTo":"נחיתה",
                "selectDestination":"בחר יעד",
                "checkHotel":"מעבר להזמנה",
                "noRating": "ללא דירוג",
                "toOrder":"להזמנה",
                "selectAgain": "בחר מחדש",
                "selectFlight": "בחר טיסה",
                "reselectFlight": "בחר טיסות מחדש",
                "flightSelectedSuccessfully": "טיסה נבחרה בהצלחה",

                "dynamicFlight":"בחרו את הטיסות בעצמכם",
                "bundle": "טיסות מוגדרות מראש",
                "freeCancelation":"ביטול חופשי",
                "trollyIsIncluded":"טרולי כלול",
                "trollyIsNotIncluded":"ללא טרולי",
                "suitcaseIsIncluded":"מזוודה כלולה",
                "suitcaseIsNotIncluded":"ללא מזוודה",

                "partially_operated_by_airlines": "מופעלת חלקית ע״י",
                "operated_by_airlines": "מופעלת ע״י",
                "connect_in_airport": "עצירה בשדה ",
                "arrives": "הגעה",
                "journey_duration": "משך המסלול",
                "short_wait": "עצירה קצרה",
                "long_wait": "עצירה ארוכה",
                "flight_number": "מספר טיסה",
            },
            "specialDeal": {
                "closeTheDeal": "סגרו את הדיל ›",
                "endsIn": "מסתיים בעוד",
                "specialDealForTheWeekend": "דיל מיוחד לסופ״ש",
                "from": "מתאריך",
                "until": "עד תאריך"
            },
            "footer": {
                "backToTop": "חזור למעלה",
                "allRightsReserved": " © כל הזכויות שמורות, אין להעתיק תוכן מהאתר ללא רשות.",
                "developBy": "by SKY 360 Technology",
                "thePurchaseOnTheWebsiteIsSecure": "הרכישה באתר מאובטחת.",
                "talkToUs": "איך מדברים איתנו?",
                "address": "כתובת",
                "phone": "טלפון",
                "whatsapp": "וואטסאפ",
                "phoneAndWhatsapp": "טלפון או בוואטסאפ",
            },
            "packages": {
                "availableSeats":"כיסאות פנויים",
                "isRoundTrip":"מחיר מזוודה לטיסה הלוך חזור * ",
                "from": "מתאריך",
                "until": "עד תאריך",
                "breakfastIncluded": "כולל ארוחת בוקר",
                "includesMassageAndSpa": "כולל מסאג׳ וספא!",
                "lastRoom": "חדר אחרון!",
                "includesTransfers": "כולל העברות",
                "includesFlights": "כולל טיסות",
                "includesActivities": "כולל אטרקציות",
                "nights": "לילות",
                "withoutBaggage":"ללא כבודה",
                "directFlight":"טיסה ישירה",
                "oneStop":"עצירה אחת",
                "numOfStops":"עצירות",
                "flightDetails":"פרטי הטיסות",
                "flightFrom":" from -> to ",
                "flightBack":" from -> to ",
                "hours":"שעות",
                "flightNumber":"מס׳ הטיסה",
                "returnFlight":"טיסה חזור",
                "outboundFlight":"טיסה הלוך",
                "airLine":"חברת התעופה",
                "suitcaseWeight":" הטיסה כוללת מזוודה weight ק״ג",
                "trollyWeight":"  הטיסה כוללת טרולי weight ק״ג",
                "trollyWeight2":"weight ק״ג טרולי / תיק",
                "suitcaseWeight2":"weight ק״ג מזוודה",
                "included":"כלול",
                "notIncluded":"לא כלול",
                "perPax":"לאדם",
                "addSuitcaseLater":"יהיה ניתן להוסיף בהמשך",
                "includedActivitiesTitle":"אטרקציות וטיולים בחבילה",
                "checkAllPictures":"הצג את כל התמונות",
                "affiliateWith": "בשיתוף עם",
                "addButtonDisabledTooltip": "הזמנה מוגבלת לכמות חדרים - לא ניתן להוסיף חדר נוסף. כדי לשנות חדר יש להסיר חדר מהעגלה",
                'holidaySchedule':"לו״ז הנופש",
                "passengers":"נוסעים",
                "clickForDetails":"לחצו כאן לפרטי האטרקציה",
                "includedInPackage":"כלול בחבילה!",
            },
            "roomPricing": {
                "for": "ל",
                "addPassenger": "הוסף נוסע",
                "autoApprove": "באישור מידי",
                "pricePerPerson": "מחיר לאדם",
                "pricePerRoom": "מחיר לחדר",
                "priceForAllRooms": "מחיר לכל החדרים",
                "payOnSpot": "לתשלום במקום",
                "resort_fee": "מס תייר",
                "occupancy_tax": "מס לינה",
                "totalPrice": "מחיר לחדר ",
                "pricePerInfant": "מחיר לתינוק",
                "composition": "הרכב",
                "adultsInTheRoom": "מבוגרים",
                "children": "ילדים",
                "adults":"מבוגרים",
                "single":"מבוגר יחיד",
                "child":"ילד",
                'selectInfantCount':"בחר מרשימה",
                'infant':"תינוק",
                '1infant':"תינוק אחד",
                '2infants':"2 תינוקות",
                'infants':"תינוקות",
                "noInfants":"ללא תינוקות",
                "all_include": "הכל כלול",
                "breakfast":"ארוחת בוקר",
                "activities":"סיור לבחירה",
                "half_board":"חצי פנסיון",
                "half_board_kosher":"חצי פנסיון כשר",
                "full_board" : "פנסיון מלא",
                "sleep_only" : "לינה בלבד",
                "breakfast_kidush" : "א.בוקר וא.ערב שישי",
                "addToCard": "הוסיפו להזמנה",
                "inPackageDates": "בתאריכי החבילה",
                "chooseRoom": "בחרו סוג חדר",
                "nights": "לילות",
                "boardType":"בסיס לינה",
                "filters":"מסננים",
                "noAvailableRooms": "אין חדרים זמינים לתאריכים הנבחרים",
                "noAvailableRoomsForDates": "חדר לא זמין בתאריכים הנבחרים",
                "addInfant":"הוספת תינוק",
                "roomFacilities":"מתקני החדר",
                "show_images": "לכל תמונות החדר",

                //// UPDATE IT LATERRR
                "until": "עד",
                "no": "ללא ביטול חינם",
                "vat": "מע״מ",
                "is": "",
                "not": "לא",
                "includedInPrice": "כלול במחיר",
                "city_tax": "מס מקומי",
                "cancellation_policy": "תנאי ביטול",
                "your_local_time": "* זמן מקומי",
                "additional_price_info": "מידע נוסף",
                "policy": {
                    "freeCancallationUntil": "ביטול חינם עד לתאריך",
                    "freeCancallationUntil2": "ביטול חינם עד",
                    "cancallationBeCharged": "דמי ביטול בסך",
                    "ifYouCancelBetween": "במידה ותבטל מתאריך",
                    "ifYouCancelTill": "במידה ותבטל עד תאריך",
                    "ifYouCancelModifiedBetween": "במידה ותבטל או תשנה את ההזמנה לאחר ה ",
                    "cancelFullPrice": "ייגבו דמי ביטול מלאים",
                    "till": "עד",
                    "rateNonRefundable": "מחיר זה הוא עם דמי ביטול מלאים",
                }
            },
            "boards" : {
                "RO": "לינה בלבד",
                "BB": "ארוחת בוקר",
                "HB": "חצי פנסיון",
                "FB": "פנסיון מלא",
                "AI": "הכל כלול",
                "all-inclusive": "הכל כלול",
                "UA": "אולטרה הכל כלול",
                "UI": "אולטרה הכל כלול",
                "UAI": "אולטרה הכל כלול",
                "BF": "ארוחת בוקר בופה",
                "KB": "ארוחת בוקר כשרה",
                "KHB": "חצי פנסיון כשרה",
                "CB": "ארוחת בוקר קונטיננטלית",
                "AB": "ארוחת בוקר אמריקאית",
                "DR": "ארוחת ערב וחדר",
                "EB": "ארוחת בוקר אנגלית",
                "FT": "פנסיון מלא + טיפולים",
                "F+": "פנסיון מלא פלוס",
                "GA": "ארוחת גלה",
                "H6": "חצי פנסיון + 6 טיפולים",
                "HT": "חצי פנסיון + טיפולים",
                "IB": "ארוחת בוקר ישראלית",
                "KO": "כשר",
                "KS": "ארוחת בוקר כשרה",
                "MI": "הכל כלול מקסימום",
                "RB": "חדר וארוחת בוקר",
                "NN": "ראה את המסלול",
                "SI": "הכל כלול רך",
                "UL": "אולטרה הכל כלול",
                "half_board":"חצי פנסיון",
                "half-board":"חצי פנסיון",
                "half_board_kosher":"חצי פנסיון כשר",
                "full_board" : "פנסיון מלא",
                "full-board" : "פנסיון מלא",
                "sleep_only" : "לינה בלבד",
                "breakfast_kidush" : "א.בוקר וא.ערב שישי",
                "breakfast": "ארוחת בוקר",
                "breakfast-for-2": "ארוחת בוקר לזוג",
                "nomeal": "לינה בלבד",
                "all_include": "הכל כלול",
            },
            "activitiesTranslations": {
                "addToOrder": "הוסיפו להזמנה",
                "passenger": "לנוסע",
                "showMore": "הצג עוד",
                "addAttractionsAndTrips": "הוסיפו אטרקציות וטיולים",
                "doYouWantToAddAttractions":" רוצים להוסיף אטרקציות לחופשה שלכם?",
                "itsTime":"זה הזמן!",
                "pricePerPerson":"מחיר לנוסע",
            },
            "modal": {
                "viewAllImages": "להציג את כל התמונות",
                "galleryTitle": "גלריית תמונות",
                "cancelationTerms": "תנאי ביטול - ",
                "nameOfTheHotel": "פרטי המלון",
                "hotelInfo": "על המלון",
                "hotelWebsite": "קישור לאתר המלון",
                "showMore": "הצג את כל המידע",
                "showLess": "הסתר",
            },
            "manageBaggage":{
                'manageBaggage':"ניהול כבודה",
                "and": "ו",
                "save": "שמור",
                "purchaseIsNotSupported": "לא ניתן להוסיף לטיסה עם ספק זה",
                "baggageIsForBothFlights": "ניתן להוסיף כבודה לטיסה הלוך ושוב",
                "suitcase":"מזוודה",
                'trolly':"טרולי",
                'guest':'נוסע',
            },
            "shoppingCartTranslations":{
                'manageBaggage':"ניהול כבודה",
                "youCanAddGuestsOnlyUpToTheAvailableSeats":"ניתן להוסיף לסל על פי כמות המקומות הזמינים לטיסה",
                "infantAdditionalPrice":"תוספת מחיר תינוק",
                "netPriceWarning":"מחיר נטו, יש להוסיף עמלה!",
                "copyOrderLink":"העתק קישור להזמנה",
                "createOrder":"שמור הצעה",
                "updateCommission":"שמור",
                "commissionNotSaved":"עמלה חדשה לא נשמרה, יש ללחץ על שמור",
                "clearCart":"לחץ להסרת הלקוח והמוצרים",
                "assignCustomer":"שיוך לקוח",
                "firstName":"שם פרטי",
                "lastName":"שם משפחה",
                "phone":"טלפון",
                "comment":"תגובה",
                "email":"אימייל",
                "wrongEmailFormat":"פורמט אימייל שגוי",
                "saveCustomer":'שמור לקוח',
                "updateCustomerTitle":"עדכון לקוח",
                "createCustomerTitle":"צור לקוח חדש",
                "asignCustomer":"שיוך לקוח",
                "sendByWhatsApp":"שלח ב WhatsApp",
                "copyText":"העתק הצעה",
                "sendBySMS":"שלח ב SMS",
                "findQuoteFor":"שלח הצעת מחיר באמצעות",
                "linkIsGive":"הצעה הינה Live ",
                "dates":"תאריכים",
                "hotelName":"שם המלון",
                "flightPrice":"מחיר טיסה",
                "suitcase":"מזוודה",
                'trolly':"טרולי",
                "passengers":"נוסעים",
                "shoppingCartButtonTooltip":"יש לבחור הרכב ולהוסיף להזמנה",
                "totalPrice":"סה״כ לתשלום",
                "totalAgentComm":"סה״כ עמלת סוכן",
                "proceedToPayment":"המשך לתשלום",
                "placeOrder":"מעבר לרישום",
                "NoHotelWasChosenForTheVacation":" לא נבחר מלון לחופשה",
                "betweenDates":"בין התאריכים",
                "packageInformation":"פרטי החבילה",
                "rooms":"פירוט החדרים",
                "transfers":"העברות",
                "reg_transfers":"העברות / שאטלים למלון הלוך ושוב",
                "shuttle_transfers":"שאטלים מהגבול למלון וחזור",
                "flights":"טיסות",
                "activities":"אטרקציות",
                "shoppingCart":"סל מוצרים",
                "package":"חבילת נופש",
                "nights":"לילות",
                "inHotel":"במלון",
                "apply":"אישור",
                "promoCode":"קוד קופון",
                "yourShoppingCart":"סל הקניות שלכם",
                "isEmpty":"עדיין ריק...",
                "myVacation":"החופשה שלי",
                "showAll":"הצג הכל »",
                "beforeDiscount": "סה\"כ לפני הנחה -",
                "agentCommission": "עמלת סוכן -",
                "agentCommissionNet": "עמלת סוכן (תשלום נטו) -",
                "discountFromCode": "הנחת קוד -",
                "affiliateWith": "בשיתוף עם -",
                "tpId": "מזהה הזמנה -",
                "chargeTypeFullCC": "תשלום מלא - כולל עמלה",
                "chargeTypeNetCC": "תשלום עבור מחיר נטו",
                "chargeTypeCash": "עסקה במזומן",
                "agentCommissionPrec": "אחוז עמלת סוכן",
                "priceOff": "הנחה",
                "percentageOff": "אחוז הנחה",
                "selectedItems":"פריטים נבחרים",
                "agentOptions":"אופציות סוכן",
                "compareDisableForAgent":"ניתן להשוות מחיר כאשר יש 2 הצעות בעגלה",
                "createNewUser":"צור הצעת מחיר חדשה",
                "comparePrice":"השוואת מחירים",
                "send":"שלח הצעת מחיר",
                "profitPercentageChange": "שינוי אחוז רווח",
                "or":"או",
                "approval":"אישור",
                "profitPerPerson":"רווח לאדם",
                "grossProfitPercentage":"אחוז רווח כולל",
                "viewProduct": "לצפייה",
                "actionNotAllowed":"לא ניתן להוסיף מוצר לסל",
                "orderIsAlreadySaved":"ההזמנה שמורה וכעת לא ניתן להוסיף מוצר נוסף",
                "clickToClear":"לחץ לריקון העגלה",
                "roomsByRequestSubjectOfHotelAvailability":"לא ניתן להוסיף חדדר מאותו סוג, נא בחר חדר מסוג אחר",
                'roomsByRequestSubjectOfHotelAvailabilityTitle':"הוספת חדר נכשלה"
            },
            "checkout":{
                "newGuest":"נוסע חדש",
                "chooseExistingGuest":"בחר נוסע קיים",
                "additionalBaggage":"מטען נוסף",
                "free":"חינם",
                "baggageIsForBothFlights":"הוספת כבודה לטיסה הלוך וחזור יחד",
                "additionalBaggageForThisGuest": 'כבודה נרכשה לנוסע זה בתוספת תשלום',
                "orderFailedToLoad":"התרחשה שגיאה בעת טעינת ההצעה",
                "cartNoLongerValid":"העגלה כבר לא תקפה",
                "room": 'חדר',
                "personalInfo":"פרטי הנופשים",
                "paymentDetails":"פרטי התשלום",
                "orderCompletion":"סיום הזמנה",
                "userDetails":"פרטי המזמין",
                "name":"שם פרטי",
                "lastName":"שם משפחה",
                "passport":"מס׳ דרכון",
                "inEnlgish":"באנגלית",
                "birthdate":"תאריך לידה",
                "phone":"טלפון",
                "email":"דוא״ל",
                "notesForTheOrder":"הערות להזמנה",
                "namesOfGuests":"שמות הנוסעים",
                "namesMustMatchPassport":"* יש להקליד את כל השמות באנגלית בדיוק כפי שמופיעים בדרכון",
                "goToPayments":'שליחה ומעבר לתשלום',
                "saveOrder":'שמור הזמנה',
                "agreement":"אני מאשר/ת תקנון האתר, תנאי ההזמנה ואת תנאי ביטול ההזמנה",
                "men":"זכר",
                "women":"נקבה",
                "pleaseInputYourPhone":"נא להזין מספר טלפון",
                "pleaseInputYourLastName":"נא להזין שם המשפחה",
                'pleaseInputYourFirstName':"נא להזין את שמך הפרטי",
                "pleaseInputYourEmail":"נא להזין את המייל",
                "pleaseInputYourPassportNumber":"אנא הזן את מספר הדרכון שלך",
                "pleaseInputYourDateOfBirth":"נא להזין תאריך הלידה",
                "pleaseSelectGender":"נא לבחור מין",
                "maxChildAge":"ילד בין הגילאים 2-",
                "maxInfantAge":"תינוקות חייבים להיות מתחת לגיל שנתיים במועד החופשה",
                'pleaseAgreeWithT&C':"נא להסכים לתנאי השימוש ותקנון האתר",
                'viewTerms':"לצפיה בתקנון",
                "invalidEmail":"אימייל שגוי",
                "orderCreateSuccess": "הזמנתך נשמרה בהצלחה, הנך מועבר לתשלום",
                "orderCreateFree": "הזמנתך נשמרה בהצלחה!",
                "orderUpdateSuccess": "הזמנתך עודכנה בהצלחה!",
                "mainRoomGuestTitle" :"חדר על שם",
                "uploadPassportImage":"העלאת צילום דרכון",
                "addInfant":"הוסף תינוק",
                "updateInfant": "עדכון כמות תינוקות",
                "howManyInfantsDoYouWantToAdd":"בחר את כמות התינוקות לחדר זה",
                "add":"לְהוֹסִיף",
                "setInfantCountForRoom": "עדכן כמות תינוקות",
                "setInfantCountForRoomSuccess": "כמות התינוקות עודכנה בהצלחה",
                "infantsAddedToTheRoom":"תינוקות שנוספו לחדר",
                "selectNumberOfInfants":"בחר מספר תינוקות",
                "pricePerInfant":"מחיר לתינוק",
                "infantsRemoved":"הוסרו התינוקות לחדר זה",
                "requiredUploadPassportImage":"חובה להעלאת צילום דרכון",
                "infantError4403":"לא ניתן לשנות כמות תינוקות - צור קשר עם מוקד השירות",
                "docketId":"* Docket ID",
                "agencyId":"* Agency ID",
                "noTrolly":"בלי טרולי",
                "noSuitcase":"ללא מזוודה",
                "updateBaggages":"עדכון מזוודות",
                "manageBaggagesFor":"נהל מזוודות עבור אורח",
                "addBaggage":"הוספת כבודה",
                "closeAddBaggage":"הסתר אופציות",
                "close":"סגור"
            },
            "manageOrder": {
                "moveToPayment": "הוסף אמצעי תשלום",
                "backToManage": "חזור לניהול הזמנה",
                "paymentTitle": "הוספת אמצעי תשלום",
                "manageTitle": "עדכון פרטי הנופשים",
                "modelNotFound": "הזמנה  לא נמצאה",
                "orderId":"ניהול הזמנה"
            },
            "completionForm": {
                "paymentFinish": {
                    "title": "ההזמנה בוצעה בהצלחה - התשלום הושלם",
                    "info": "מייל עם פרטי ההזמנה נשלח אליך לכתובת",
                },
                "waitingToApprove": {
                    "title": "ההזמנתך הועברה לאישור",
                    "info1": "פרטי ההזמנה נשמרו במערכת והועברו לאישור.",
                    "info2": 'תשלום התבצע רק לאחר אישור ההזמנה.',
                    "info3": 'מייל עם אישור ההזמנה ותשלום ישלח לאחר אישור ההזמנה.',
                },
                "paymentFail": {
                    "title": "התשלום נכשל",
                    "info1": "התשלום נכשל :(",
                    "infoFail": "לאחר מספר נסיונות, החיוב נכשל - אנה נסה שנית",
                    "infoWhatsapp": "במידה והתרחשה שגיאה, ואינך מצליח לשלם - יש ליצור קשר בוואטסאפ במספד: ",
                },
                "orderNum": "מספר ההזמנה",
                "thanksForChoose": "תודה שבחרת ב",
                "free": {
                    "title": "הרישום בוצע בהצלחה",
                    "info": "נשלח אליך מייל עם סיכום ההזמנה",
                } ,
                "pay_later": {
                    "title": "הרישום בוצע בהצלחה",
                    "info": "נשלח אליך מייל עם אישור ההזמנה",
                }
            },
            "paymentForm": {
                "priceCanNotBeOverBalance":"מחיר לתשלום לא יכול להיות מעל גובה היתרה",
                "total": "סה״כ קישורים לתשלום",
                "balance": "יתרה לתשלום",
                "updatedBalance":"יתרה עדכנית",
                "copyLink":"העתק קישור",
                "linkFor": "קישור עבור",
                "back": "חזרה",
                "createLink": "צור קישור",
                "setSpecificPrice":"יצירת לינק לתשלום",
                "setPriceAndSendPaymentLinkToClient":"הזן סכום לתשלום וצור לינק לתשלום ללקוח",
                "clientName":"שם הלקוח",
                "paymentPageFor":"דף תשלום עבור ",
                "forPayment":"לתשלום",
                "price":"מְחִיר",
                "copyPaymentLink":"העתק קישור לתשלום",
                "pleaseFillInBothFields":"נא למלא את שני השדות.",
                "paymentLinkCopiedToClipboard":"קישור התשלום הועתק ללוח!",
                "createLinkForPrice":"צור לינק לתשלום",
                "creditNotSaveInBd": " פרטי האשראי אינם נשמרים האתר - הינך משלים תשלום בדרך מאובטחת",
                "title": "הזן אמצעי תשלום",
                "splitBtn": "לחץ לפיצול כרטיסי אשראי",
                "splitTitle": "פיצול כרטיסי אשראי",
                "splitDesc": "בחר את הכמות כרטיסי אשראי לפיצול ולחץ אישור",
                "noSplit": "ללא פיצול",
                "noPayments": "ללא תשלומים",
                "payments": "תשלומים",
                "paymentsNum": "מספר תשלומים",
                "withDebit": "עם קרדיט",
                "splitBy": "כרטיסי אשראי",
                "toPay": "לתשלום",
                "splitApprove": "אישור",
                "currencyRate": " חיוב מתבצע בשקלים - לפי שער תיירות",
                "splitCurrentCardNum": "פיצול אשראי",
                "errorMessageToRetry": "נסיון מס׳ {retryNum} - תשלום נכשל מול מחברת האשראי, יש להזין שוב את פרטי כרטיס אשראי או כרטיס אחר. אתר מקבל כרטיס VISA ו MASTERCARD בלבד.",
                "splitCurrentCardNumFrom": "מתוך",
            },
            "hotelTypes": {
                "Hotel": "מלון",
                "Apartment": "דירות",
                "Apart-hotel": "מלון דירות",
                "Guesthouse": "בית הארחה",
                "Resort": "ריזורט",
                "Boutique_and_Design": "מלון בוטיק",
            },
            "hotelDetails":{
                "updateDates":"עדכון תאריכים",
                "roomTypesToChooseFrom":"סוגי חדרים לבחירתך",

                "metapolicy": "מדיניות המלון",
                "metaExtraInfo": "פרטים נוספים",
                "not_included": "לא כלול",
                "included": "כלול",
                "price": "בעלות של",
                "address": "כתובת",
                "other": "כתובת",
                "Location": "מיקום מלון",
                "At the boutique hotel ": "על המלון",
                "Room amenities": "שירות חדרים",

                "parking": "חניה",
                "pets": "חיות מחמד",
                "shuttle": "הסעה לשדה (לכיוון)",
                "internet": "שירותי אינטרנט",
                "extra_bed": "מיטה נוספת",
                "add_fee": "תוספת תשלום",
                "children_meal": "ארוחת ילדים",
                "check_in_check_out": "צ׳ק אין וצ׳ק אווט",

            },
            "sideFilters": {
                "clearSideFilters":"ניקוי מסננים",
                "ts_type": "סוגי טיסות",
                "auto_approve": "אישור מידי",
                "auto_approve_checked": "הצגת תוצאות אישור מידי",
                "from_date_hours": "שעת טיסה הלוך",
                "return_date_hours": "שעת טיסה חזור",
                "airline": "חברת תעופה",
                "2": "טיסות שכר",
                "3": "טיסות סדירות",
                "nonStop": "טיסה ישירה",
                "stop": "עצירה אחת",
                "stops_plural": "עצירות",
                "hotel_type": "סוגי אירוח",
                "stops": "מס׳ עצירות",
                "freeCancellation": "ביטול חינם?",
                "freeCancellationLabels": {
                    "1": "ביטול חינם בלבד",
                    "0": "ללא ביטול חינם",
                },
            },
            "hotelAmenities": {
                // "dummy": "dummy"
            },
            "general":{
                "usd": "USD",
                "euro": "EURO",
                "availableSeats":"מקומות פנויים",
                "showGrossPrice":"הצג מחיר ברוטו",
                "showNetPrice":"הצג מחיר נטו",
                "noRating": "ללא דירוג",
                "address": "כתובת",
                "type": "סוג",
                "boardBasis":"בסיס אירוח",
                "supplier":"ספק",
                "airLine":"חברת תעופה",
                "price":"מחיר",
                "totalRoomPrice": "סה״כ מחיר לחדר",
                "priceForXRooms": "מחיר ל X חדרים",
                "hotel":"מלון",
                "hotel_stars":"כוכבי מלון",
                "minPrice":"החל מ",
                "BB":"ארוחת בוקר כלולה",
                "HB":"חצי פנסיון",
                "cancel":"ביטול",
                "replaceShoppingCart":"עגלת קניות לא נשמרת האם ברצונך להחליף אותה",
                "replace":"החלף",
                "searchForUser":"חפש לקוח לפי שם או טלפון",
                "fieldRequired":"שדה נדרש",
                "bundleFlight":"טיסה הלוך ושוב",
                "trollyIsIncluded":"טרולי כלול",
                "trollyIsNotIncluded":"ללא טרולי",
                "suitcaseIsIncluded":"מזוודה כלולה",
                "suitcaseIsNotIncluded":"ללא מזוודה",
                "additionalSuitcases":"כולל X מזוודות",
                "additionalTrolly":"כולל X טרולי",
                "returnFlight":"טיסה חזור",
                "outboundFlight":"טיסה הלוך",
                "agentMode":"מצב סוכן",
                "userMode":"מצב משתמש",
                "suitcase":"מזוודה",
                'trolly':"טרולי",
                "packageSearchResult":"החבילות שמצאנו עבורך",
                "day":"יום",
                "selectDate":"בחרו תאריכים",
                "search":"חיפוש",
                "adults":"מבוגרים",
                "adult":"מבוגר",
                "child":"ילד",
                "children":"ילדים",
                "infant":"תינוק",
                "infants":"תינוקות",
                "noResults":"אין תוצאות",
                "nights":"לילות",
                "includesActivities":"{NUMBER} אטרקציות מתנה!",
                "includesActivity":"כולל אטרקציה מתנה!",
                "all_include": "הכל כלול",
                "breakfast":"ארוחת בוקר",
                "half_board":"חצי פנסיון",
                "half_board_kosher":"חצי פנסיון כשר",
                "full_board" : "פנסיון מלא",
                "sleep_only" : "לינה בלבד",
                "breakfast_kidush" : "א.בוקר וא.ערב שישי",
                "addToCard": "הוסיפו להזמנה",
                "chooseRoom": "בחרו סוג חדר",
                "boardType":"בסיס לינה",
                "filters":"מסננים",
                "rating":"דירוג מלון",
                "toDate" : "עד",
                "transfers" : "העברות",
                "from" : "החל מ-",
                "perPerson" : "לאדם",
                "include" : "כולל",
                "noIncludeText" : "חבילות נופש",
                "flights" : "טיסות",
                "flight" : "טיסה",
                "vacationAt": "חופשה ב{DEST}",
                "showAll":"הצג הכול",
                "showLess":"הצג פחות",
                "h": " ש׳",
                "min": " ד׳",
                "acceptCookiesTitle":"אתר זה משתמש בקובצי Cookie. על-ידי שימוש באתר ובשירות שלנו אתה מסכים עם מדיניות השימוש שלנו בקובצי Cookie, כמתואר בתקנון האתר.",
                "acceptCookiesBtn":"קבל",
            },
            "subsidizedForm":{
                "title":"הוספת סבסוד",
                "employee_id":"מספר עובד",
                "person_id":"ת.ז (9 ספרות)",
                "validate":"בדוק קוד עובד",
                "alreadyApplied":"הסבסוד כבר חל",
                "addOneMore":"הוסף סבסוד נוסף",
                "addOneMoreId":"הוסף עובד נוסף",
                "fieldRequired":"שדה חובה",
                "priceReducedBy":"מחיר מופחת ב",
                "forEmployee":"עבור עובד",
                "employeeIs":"זוהה בהצלחה ",
                "error4401":'נכשל בזיהוי, נסה שנית',
                "error4402":'קוד זוהה, אך כבר בשימוש',
                "guestIdentifiedId": 'מזוהה לפי ת.ז',
                "guestNotIdentified": 'יש להזדהות לפני רישום',
                "clickToIdentify":"לחץ להזדהות"
            },
            "uploadImage":{
                "uploadPassportImage":"העלאת צילום דרכון",
                "somethingWentWrong":"Something went wrong"
            },
            "flightButton":{
                "flightTotalPrice":"מחיר טיסה לכיוון",
                "total":"",
                "continueToOrder":"מעבר להזמנה",
                "addToCart":"הוסף לסל",
                "lastPlace":"מקומות אחרונים",
                "pricePerPassenger":"מחיר לנוסע",
                "cardConditions": "תנאי כרטיס",
            },
            "agentCommission":{
                'supplier':"ספק",
                'commission':"עמלה",
                "netPrice":"מחיר נטו",
                "recommendedFee":"עמלה",
                "transactionDetailsForAgent":"פרטי עסקה לסוכן",
                "supplierNotes":"הערות ספק",
                "cancellationConditions":"ותנאי ביטול",
                "addToCartToChangeFree":"הוסף לסל לשינוי עמלה",
                "pricesShownAreNetPrices":"המחירים המוצגים הינם מחירי נטו",
                "pricesShownAreGrossPrices":"המחירים המוצגים הם מחירים ברוטו",
                "packageRemarks": "הערות לחבילה",
            }
        }
    }
};

export default translations;
export function roundUpCurrency(value: string | number | undefined, decimals?: number): string {
    try {
        if (!value) {
            return '0';
        }
        // If the value is a number, convert it to a string
        if (typeof value === 'number') {
            value = value.toString();
        }

        // Regular expression to capture the number with optional currency symbols before and after
        const regex = /^(\D*)\s*([\d.]+)\s*(\D*)$/;
        const match = value.match(regex);

        if (match) {
            const beforeSymbol = match[1] || '';
            const number = parseFloat(match[2]);
            const afterSymbol = match[3] || '';

            // If decimals is provided, round to that many decimal places; otherwise, round to the nearest integer
            const factor = decimals !== undefined ? Math.pow(10, decimals) : 1;
            const roundedNumber = Math.ceil(number * factor) / factor;

            // Format the rounded number with the specified number of decimals if provided
            const formattedNumber = decimals !== undefined ? roundedNumber.toFixed(decimals) : roundedNumber;

            return `${beforeSymbol}${formattedNumber}${afterSymbol}`;
        } else {
            console.log("roundUpCurrency: Invalid input format");
            return `0`;
        }
    } catch (error) {
        console.log('roundUpCurrency error: ', error);
        return `0`;
    }
}
import { createSlice } from '@reduxjs/toolkit';
import { Flight, FlightInterface, MainProductType } from './interfaces';
import { flatten, isEmpty, isEqual, omit } from 'lodash';
import { current } from '@reduxjs/toolkit';
import { PriceView } from '../../hooks/useGetGrossPrice';
import {SubsidizeConfInterface} from "../PackageDetails/packageDataInterface";
import { generateUUID } from './hooks/useHandleCheckout';

export interface Product {
  identifier: string;
  type: MainProductType;
  subProducts?: Product[];
  name: string;
  date_start?: string;
  date_end?: string;
  product_data: any;
  product_amount: number;
  product_price: number;
  product_net_price: number;
  total_price: number;
  total_net_price: number;
  related_guest_indexes: number[];
  model_id?:string | number
}

export interface ShoppingCartV2 {
  orderId?: string;
  orderSecret?: string;
  products: Product[];
  isSaved: boolean;
  guests: any[];
  customerInfo: Record<string, string>;
  total_price?: number;
  total_net_price: number;
  totalNumberOfGuests?: number;
  currency_sign: string;
  paid_currency_sign: string;
  prom?: string;
  agency?: { id: string; name: string };
  tp_id?: string;
  affiliate?: string;
  cartNotValid?: boolean;
  appliedSubsidize?: any;
  settings?: any;
}

export enum CommissionType {
  Percentage = 'percentage',
  PerPax = 'per_pax',
}
interface AgentCommission {
  commission_per_order: number;
  type: CommissionType;
  per_pax?: number;
  per_pax_currency?: boolean;
  percentage?: number;
}

interface customerInfo {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  comment: string;
  customer_secret: string;
  order_secret: string;
}
interface PaymentFormData {
  transactionId?: string;
  paymentRetries?: number;
}
interface cartSettingsInterface {
  // checkout_info: string;
  // promo_text?: string;
  // hide_flight_from?: boolean;
  // hide_flight_back?: boolean;
  // flight_only?: boolean;
  // info_box?: any;
  // activities_title: string;
  // activities_semi_title: string;
  // price_option_type: packagePriceOption;
  // show_affiliate_info: boolean;
  // show_infants: boolean;
  // show_cancellation_terms: boolean;
  // show_coupon_code: boolean;
  // show_guest_birthday: boolean;
  // show_guest_passport: boolean;
  // is_guest_passport_required: boolean;
  // subsidize_conf:SubsidizeConfInterface
  // infant_price:number
  // show_guest_passport_attachment:boolean
  // is_required_guest_passport_attachment:boolean
  // max_optional_activities:number
  // show_optional_activities:boolean
  // flight_comment: any,
  // rooms_comment: any,
  // is_customer_email_required: boolean;
  // show_order_comment: boolean;
  // guest_icon_to_override_by_text: string;
  // manage_order_back_image: string;
  // show_flights_cart: boolean;
  show_terms: boolean;
  terms_text?: string;
  terms_link?: string;
  // guests_extra_fields: ExtraField[];
  // order_extra_fields: ExtraField[];
  // show_room_filters: boolean;
  // limit_cart_max_rooms:number
  // allow_only_guest_from_subsidize:boolean
  // package_info_as_schedule:boolean
  // show_agency_id:boolean
  // show_docket_id:boolean
  // agencies:Record<string | number, string>
  // redirect?: string,
  // popup_title?: string,
  // popup_content?: string,
}

const initialState: {
  ShoppingCartV2: ShoppingCartV2;
  agent_commission: AgentCommission;
  customerInfo: customerInfo;
  paymentFormData: PaymentFormData;
  cartSettings?: cartSettingsInterface;
} = {
  ShoppingCartV2: {} as ShoppingCartV2,
  agent_commission: {
    type: CommissionType.Percentage,
    per_pax: 0,
    percentage: 7,
  } as AgentCommission,
  customerInfo: {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    comment: '',
    order_secret: '',
    customer_secret: '',
  },
  paymentFormData: {} as PaymentFormData,
  cartSettings: {
    show_terms: true,
    terms_link: "/takanon",
  } as cartSettingsInterface
};

const calculateTotals = (state: any) => {
  const totalPriceGross =
    current(state).ShoppingCartV2.products?.reduce(
      (accumulator: number, currentValue: Product) => {
        // return Math.ceil(accumulator + currentValue.total_price);
        if(currentValue.total_price === 0) {
          const subProductsTotalPrice = currentValue?.subProducts?.reduce(
            (subProductAccumulator: number, subProductValue: Product) => {
              return subProductAccumulator + subProductValue.total_price;
            }, 0) ?? 0;
          return accumulator + subProductsTotalPrice;
        }

        return accumulator + currentValue.total_price;
      },
      0
    ) ?? 0;
  const totalPriceNet =
    current(state).ShoppingCartV2.products?.reduce(
      (accumulator: number, currentValue: Product) => {
        // return Math.ceil(accumulator + currentValue.total_net_price);
        if(currentValue.total_net_price === 0) {
          const subProductsTotalPriceNet = currentValue?.subProducts?.reduce(
            (subProductAccumulator: number, subProductValue: Product) => {
              return subProductAccumulator + subProductValue.total_net_price;
            }, 0) ?? 0;
          return accumulator + subProductsTotalPriceNet;
        }
        return accumulator + currentValue.total_net_price;
      },
      0
    ) ?? 0;

  state.ShoppingCartV2.total_price = totalPriceGross;
  state.ShoppingCartV2.total_net_price = totalPriceNet;
};

const initialStateShoppingCart = (
  state: any,
  currency_sign: string,
  paid_currency_sign: string,
  agent_commission_percent_from_api:number,
  agent:any
) => {
  const isShoppingCartEmpty = isEmpty(state.ShoppingCartV2) || !state.ShoppingCartV2.orderId;

  const agent_commission_percent = agent?.price_view === PriceView.Gross ? agent_commission_percent_from_api : 0;

  // INITIALIZE SHOPPING CART
  if (isShoppingCartEmpty) {
    state.agent_commission.percentage = agent_commission_percent;

    state.ShoppingCartV2 = {
      orderId: 'ORDER_ID',
      orderSecret: '',
      currency_sign: currency_sign,
      // paid_currency_sign: paid_currency_sign ?? currency_sign,
      paid_currency_sign:'₪',
      isSaved: false,
      customerInfo: {
        customer_first_name: state.ShoppingCartV2.customerInfo?.customer_first_name ?? '',
        customer_last_name: state.ShoppingCartV2.customerInfo?.customer_last_name ?? '',
        customer_phone: state.ShoppingCartV2.customerInfo?.customer_phone ?? '',
        customer_email: state.ShoppingCartV2.customerInfo?.customer_email ?? '',
        tp_id: '',
        agency_id: '',
        user_comment: state.ShoppingCartV2.customerInfo?.user_comment ?? '',
      },
    } as any;
  }
};

const addNewGuests = (
  state: any,
  action: any,
  numberOfAdults: number,
  numberOfChildren: number,
  numberOfInfants: number
) => {
  const prevGuests = state.ShoppingCartV2.guests ?? [];

  const generateGuests = (
    pax: string,
    guestNumber: number,
    startIndex: number
  ) => {
    return Array.from(Array(guestNumber)).map((_, i) => {
      const guest: any = {
        id: '',
        g_index: startIndex + i,
        first_name: '',
        last_name: '',
        gender: '',
        type: pax,
        birthdate: null,
        passport_id: '',
        attachment: '',
      };
      action.payload.packageData?.settings?.guests_extra_fields &&
        action.payload.packageData?.settings?.guests_extra_fields.forEach(
          (field: any) => {
            guest[field.name] = '';
          }
        );

      return guest;
    });
  };

  const startIndexOfChildren = prevGuests.length + numberOfAdults;
  const startIndexOfInfants = startIndexOfChildren + numberOfChildren;

  const newAdultGuests = generateGuests(
    'adult',
    numberOfAdults,
    prevGuests.length
  );
  const newChildGuests = generateGuests(
    'child',
    numberOfChildren,
    startIndexOfChildren
  );
  const newInfantGuests = generateGuests(
    'infant',
    numberOfInfants,
    startIndexOfInfants
  );

  state.ShoppingCartV2.guests = [
    ...prevGuests,
    ...newAdultGuests,
    ...newChildGuests,
    ...newInfantGuests,
  ] as any;

  return { prevGuests, newAdultGuests, newChildGuests, newInfantGuests };
};

const createSubProductAttraction = (
  activities: any,
  numberOfAdults: number,
  numberOfChildren: number,
  prevGuestIndexes: number[],
  newGuestIndexes: number[]
) => {
  // SUB PRODUCT ATTRACTION
  const subProductAttraction =
    activities?.map((activity: any) => {
      return {
        id: activity.id,
        type: MainProductType.Activity,
        name: activity.name,
        product_amount: numberOfAdults + numberOfChildren,
        product_price: 0,
        total_price: 0,
        total_net_price: 0,
        related_guest_indexes: [...prevGuestIndexes, ...newGuestIndexes],
      };
    }) ?? [];

  return subProductAttraction;
};

const createSubProductFlight = (
  flights: FlightInterface,
  prevGuestIndexes: number[],
  newGuestIndexes: number[],
  numberOfAdults: number,
  numberOfChildren: number
) => {
  const { flightBack, flightFrom, ...flightSharedData } = flights;

  const subProductFlights: any[] = [];

  subProductFlights[0] = {
    id: flightFrom[0].id,
    type: MainProductType.Flight,
    product_amount: numberOfAdults + numberOfChildren,
    product_price: 0,
    total_price: 0,
    total_net_price: 0,
    product_data: { flightFrom, ...flightSharedData },
    subProducts: [],
    related_guest_indexes: [...prevGuestIndexes, ...newGuestIndexes],
  };

  subProductFlights[1] = {
    id: flightBack[0].id,
    type: MainProductType.Flight,
    product_amount: numberOfAdults + numberOfChildren,
    product_price: 0,
    total_price: 0,
    total_net_price: 0,

    product_data: { flightBack, ...flightSharedData },
    subProducts: [],
    related_guest_indexes: [...prevGuestIndexes, ...newGuestIndexes],
  };


  return subProductFlights;
};

const createSubProductRoom = (
  roomData: any,
  newGuestIndexes: number[],
  productPrice: number,
  totalPrice: number,
  room:any,
  infantGuestIndexes:any,
  infantPrice:any
) => {
  const subProductRoom = {
    id: roomData.id,
    type: MainProductType.Room,
    name: room.name,
    product_amount: 1,
    product_price: productPrice,
    total_price: productPrice,
    total_net_price: productPrice,
    product_data: {
      price: roomData,
      room
    },
    related_guest_indexes: newGuestIndexes,
    subProducts:infantGuestIndexes.length > 0 ? [
      {
        type: MainProductType.roomInfant,
        product_amount: infantGuestIndexes.length,
        product_price: infantPrice,
        total_price: infantGuestIndexes.length * infantPrice,
        total_net_price: infantGuestIndexes.length * infantPrice,
        related_guest_indexes: infantGuestIndexes
      }
    ] : []
  } as any ;

  return subProductRoom;
};

const createSubProductHotel = (
  state: any,
  hotel: any,
  totalPrice: number,
  productPrice: number,
  prevGuestIndexes: number[],
  newGuestIndexes: number[],
  subProductRoom: Product[],
  packageIndex?: number
) => {

  const allHotelSubProducts =
    current(state).ShoppingCartV2.products?.[
      packageIndex ?? -1
    ]?.subProducts?.find(
      (product: any) => product.type === MainProductType.Hotel
    )?.subProducts ?? [];

  const subProductHotel = {
    id: hotel.id,
    type: MainProductType.Hotel,
    name: hotel.name,
    product_amount: 1,
    product_price: productPrice,
    total_price: totalPrice,
    total_net_price: totalPrice,
    subProducts: [...allHotelSubProducts, subProductRoom],
    related_guest_indexes: [...prevGuestIndexes, ...newGuestIndexes],
  } as any;

  return subProductHotel;
};

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {

    addPackageToCart: (state, action) => {
      const agent_commission = action.payload?.packageData?.agent_additional_info?.suggested_commission ?? 0;
      const agent = action.payload.agent;
      initialStateShoppingCart(
        state,
        action.payload.packageData.currency_sign,
        action.payload.packageData.paid_currency_sign,
        agent_commission,
        agent
      );

      const numberOfAdults = action.payload.roomData.adult ?? 0;
      const numberOfChildren = action.payload.roomData.child ?? 0;
      const numberOfInfants = action.payload.roomData.infant ?? 0;
      const totalNumberOfGuests =
        numberOfAdults + numberOfChildren + numberOfInfants;

      const { prevGuests } = addNewGuests(
        state,
        action,
        numberOfAdults,
        numberOfChildren,
        numberOfInfants
      );

      const packageIndex =
        state.ShoppingCartV2.products?.findIndex((product) => {
          return product.identifier === action.payload.packageData.identifier;
        }) ?? 0;

      const prevGuestIndexes =
        current(state).ShoppingCartV2.products?.[packageIndex]
          ?.related_guest_indexes ?? [];

      const newGuestIndexes = [
        ...Array.from(Array(totalNumberOfGuests)).map(
          (_, i) => prevGuests.length + i
        ),
      ];

      function takeLastNItems(array:any, n:number) {
        if (n <= 0) {
            return [];
        }
        return array.slice(-n);
    }

      const infantGuestIndexes = takeLastNItems(newGuestIndexes, numberOfInfants)

      const newProductTotalPrice =
        action.payload.roomData.room_price +
        (action.payload.roomData.infant ?? 0) *
          (action.payload.packageData.settings.infant_price ?? 0);
      const allSubProducts = current(state).ShoppingCartV2.products?.[
        packageIndex
      ]?.subProducts?.filter((product: any) => product.type !== 'hotel');
      const oldPrice =
        current(state).ShoppingCartV2.products?.[packageIndex]?.total_price ??
        0;
      const oldPriceNet =
        current(state).ShoppingCartV2.products?.[packageIndex]
          ?.total_net_price ?? 0;

      const priceOfWholePackage = newProductTotalPrice + oldPrice;
      const priceOfWholePackageNet = newProductTotalPrice + oldPriceNet;

      const mainProduct = {
        identifier: action.payload.packageData.identifier,
        type: MainProductType.Package,
        name: action.payload.packageData.name,
        date_start: action.payload.packageData.dates.start,
        date_end: action.payload.packageData.dates.end,
        product_data: {
          ...action.payload.packageData,
        },
        related_guest_indexes: [...prevGuestIndexes, ...newGuestIndexes],
        total_price: priceOfWholePackage,
        total_net_price: priceOfWholePackageNet,
      } as any;

      // Check If Package is already added as a product in cart
      const isPackageAlreadyAdded = state.ShoppingCartV2.products?.find(
        (product) =>
          product.identifier === action.payload.packageData.identifier
      );
      if (!isPackageAlreadyAdded) {
        const prevProducts = state.ShoppingCartV2.products ?? [];
        state.ShoppingCartV2.products = [...prevProducts, mainProduct];
      }

      // SUB PRODUCT ATTRACTION
      const subProductAttraction = createSubProductAttraction(
        action.payload.packageData.activities,
        numberOfAdults,
        numberOfChildren,
        prevGuestIndexes,
        newGuestIndexes
      );

      // SUB PRODUCT FLIGHTS
      const subProductFlights = createSubProductFlight(
        action.payload.packageData.flights,
        prevGuestIndexes,
        newGuestIndexes,
        numberOfAdults,
        numberOfChildren
      );

      // const newProductTotalPrice =
      //   action.payload.roomData.room_price +
      //   (action.payload.roomData.infant ?? 0) *
      //     (action.payload.packageData.settings.infant_price ?? 0);

      // SUB PRODUCT ROOM
      const subProductRoom = createSubProductRoom(
        action.payload.roomData,
        newGuestIndexes,
        action.payload.roomData.room_price,
        newProductTotalPrice,
        action.payload.room,
        infantGuestIndexes,
        action.payload.packageData.settings.infant_price
      );

      // SUB PRODUCT HOTEL
      const subProductHotel = createSubProductHotel(
        state,
        action.payload.packageData.hotel,
        newProductTotalPrice,
        newProductTotalPrice,
        prevGuestIndexes,
        newGuestIndexes,
        subProductRoom,
        packageIndex
      );

      // const allSubProducts = current(state).ShoppingCartV2.products?.[
      //   packageIndex
      // ]?.subProducts?.filter((product: any) => product.type !== 'hotel');

      // const oldPrice =
      //   allSubProducts?.reduce(
      //     (accumulator, currentValue) => accumulator + currentValue.total_price,
      //     0
      //   ) ?? 0;
      // const priceOfWholePackage = newProductTotalPrice + oldPrice;

      const isFlightOrAttractionAdded = allSubProducts?.find((product) => {
        return (
          product.type === MainProductType.Flight ||
          product.type === MainProductType.Activity
        );
      });

      const packageIndexAfterMainProduct =
        state.ShoppingCartV2.products?.findIndex((product) => {
          return product.identifier === action.payload.packageData.identifier;
        }) ?? 0;

      if (state.ShoppingCartV2.products?.[packageIndexAfterMainProduct]) {
        state.ShoppingCartV2.products[packageIndexAfterMainProduct] =
          mainProduct;
      }
      // Update Attractions and Flight PAX and Add Room
      if (!!isFlightOrAttractionAdded) {
        const updatedSubProducts = allSubProducts?.map((product) => {
          if (
            product.type === MainProductType.Flight ||
            product.type === MainProductType.Activity
          ) {
            return {
              ...product,
              product_amount:
                product.product_amount +
                action.payload.roomData.adult +
                action.payload.roomData.child,
              related_guest_indexes: [...prevGuestIndexes, ...newGuestIndexes],
            };
          }
          return product;
        });

        state.ShoppingCartV2.products[
          packageIndexAfterMainProduct
        ].subProducts = [
          subProductHotel,
          ...(updatedSubProducts as any),
        ] as any;
      } else {
        state.ShoppingCartV2.products[
          packageIndexAfterMainProduct
        ].subProducts = [
          subProductHotel,
          ...subProductAttraction,
          ...subProductFlights,
        ] as any;
      }
      // state.ShoppingCartV2.products[packageIndex].total_price =
      //   priceOfWholePackage;
      // state.ShoppingCartV2.products[packageIndex].total_net_price =
      //   priceOfWholePackage;

      // (state.ShoppingCartV2.products[packageIndex].related_guest_indexes = [
      //   ...prevGuestIndexes,
      //   ...newGuestIndexes,
      // ]),
      calculateTotals(state);
    },
    updateInfants: (state, action) => {

        const {
          productIndex,
          newNumberOfInfants,
          infantModalIndex,
          infantPrice
        } = action.payload;

        const findProductByIdentifier = (products:Product[], id:string) =>
          products.find((product) => product.identifier === id);
        const productToUpdate = findProductByIdentifier(state.ShoppingCartV2.products, productIndex);

        if (!productToUpdate) {
          console.error(`Product with productIndex ${productIndex} not found`);
          return;
        }

        const roomLevelToUpdate = productToUpdate?.subProducts?.[0]?.subProducts?.[infantModalIndex];
        if (!roomLevelToUpdate) {
          console.error(`Room level product with infantModalIndex ${infantModalIndex} not found`);
          return;
        }

        const guestIndexesInfantsOnlyRoomLevel = roomLevelToUpdate.subProducts?.[0]?.related_guest_indexes ?? []
        const guestIndexesWithoutInfantsPackageLevel = productToUpdate.related_guest_indexes.filter((gestIndex) => {
          return !guestIndexesInfantsOnlyRoomLevel.includes(gestIndex)
        }) ?? []
        const guestIndexesWithoutInfantsRoomLevel = roomLevelToUpdate.related_guest_indexes.filter((gestIndex) => {
          return !guestIndexesInfantsOnlyRoomLevel.includes(gestIndex)
        }) ?? []


        const currentInfantCountRoomLevel = guestIndexesInfantsOnlyRoomLevel.length;

        state.ShoppingCartV2.guests = current(state).ShoppingCartV2.guests.filter(
          (guest) => !guestIndexesInfantsOnlyRoomLevel.includes(guest.g_index)
        );

        roomLevelToUpdate.related_guest_indexes = guestIndexesWithoutInfantsRoomLevel;

        roomLevelToUpdate.subProducts = [];

        productToUpdate.related_guest_indexes = guestIndexesWithoutInfantsPackageLevel;
        productToUpdate.subProducts?.map((productToUpdateSubProduct) => {
          productToUpdateSubProduct.related_guest_indexes = guestIndexesWithoutInfantsPackageLevel;
        })
        productToUpdate.total_price -= currentInfantCountRoomLevel * infantPrice;
        productToUpdate.total_net_price -= currentInfantCountRoomLevel * infantPrice;

        if (newNumberOfInfants > 0) {
          const startIndexOfInfants = Math.max(...state.ShoppingCartV2.guests.map((guest) => guest.g_index)) + 1;

          const newInfantGuests = Array.from({ length: newNumberOfInfants }, (_, i) => ({
            id: '',
            g_index: startIndexOfInfants + i,
            first_name: '',
            last_name: '',
            gender: '',
            type: 'infant',
            birthdate: null,
            passport_id: '',
            attachment: ''
          }));

          state.ShoppingCartV2.guests.push(...newInfantGuests);


          const newGuestIndexes = newInfantGuests.map((guest) => guest.g_index);

          productToUpdate.related_guest_indexes.push(...newGuestIndexes);

          // productToUpdate.subProducts?.map((x) => {
          //   console.log('xxxxxxxxxxxxxxxxxxxx', current(x))
          //   // x.related_guest_indexes.push(...newGuestIndexes);
          // })
          productToUpdate.total_price += newNumberOfInfants * infantPrice;
          productToUpdate.total_net_price += newNumberOfInfants * infantPrice;

          roomLevelToUpdate.related_guest_indexes.push(...newGuestIndexes);

          roomLevelToUpdate.subProducts.push({
            type: MainProductType.roomInfant,
            product_amount: newNumberOfInfants,
            product_price: infantPrice,
            total_price: newNumberOfInfants * infantPrice,
            total_net_price: newNumberOfInfants * infantPrice,
            related_guest_indexes: newGuestIndexes
          } as any);
        }
        calculateTotals(state);
    },
    // Add Hotel room to cart
    addHotelToCart: (state, action) => {
      const roomData = action.payload.roomData;
      const roomPricing = action.payload.roomPricing;
      const agent_commission = action.payload.roomPricing?.agent_additional_info?.suggested_commission;
      const agent = action.payload.agent;
      initialStateShoppingCart(
          state,
          roomPricing.currency_sign,
          roomPricing.paid_currency_sign,
          agent_commission,
          agent
      );
      const isMultiRoom = roomPricing?.multi_room_guests?.length > 0;

      const multiRoomNumberOfAdults = roomPricing?.multi_room_guests?.reduce(
        (total: any, room: any) => total + room.adult,
        0
      ) ?? 0;
      const multiRoomNumberOfChildren = roomPricing?.multi_room_guests?.reduce(
        (total: any, room: any) => total + room.child,
        0
      ) ?? 0;
      const multiRoomNumberOfInfants = roomPricing?.multi_room_guests?.reduce(
        (total: any, room: any) => total + room.infant,
        0
      ) ?? 0;

      const numberOfAdults =( roomPricing.adult ?? 0 )+ multiRoomNumberOfAdults;
      const numberOfChildren = (roomPricing.child ?? 0) + multiRoomNumberOfChildren;
      const numberOfInfants = (roomPricing.infant ?? 0) + multiRoomNumberOfInfants;

      const totalNumberOfGuests =
        numberOfAdults + numberOfChildren + numberOfInfants;

      const prevGuests = state.ShoppingCartV2.guests ?? [];

      const generateGuests = (
        pax: string,
        guestNumber: number,
        startIndex: number
      ) => {
        return Array.from(Array(guestNumber)).map((_, i) => {
          const guest: any = {
            id: '',
            g_index: startIndex + i,
            first_name: '',
            last_name: '',
            gender: '',
            type: pax,
            birthdate: null,
            passport_id: '',
            attachment: '',
          };
          action.payload.hotelInfo?.settings?.guests_extra_fields &&
            action.payload.hotelInfo?.settings?.guests_extra_fields.forEach(
              (field: any) => {
                guest[field.name] = '';
              }
            );

          return guest;
        });
      };

      const startIndexOfChildren = prevGuests.length + numberOfAdults;
      const startIndexOfInfants = startIndexOfChildren + numberOfChildren;

      const newAdultGuests = generateGuests(
        'adult',
        numberOfAdults,
        prevGuests.length
      );
      const newChildGuests = generateGuests(
        'child',
        numberOfChildren,
        startIndexOfChildren
      );
      const newInfantGuests = generateGuests(
        'infant',
        numberOfInfants,
        startIndexOfInfants
      );

      state.ShoppingCartV2.guests = [
        ...prevGuests,
        ...newAdultGuests,
        ...newChildGuests,
        ...newInfantGuests,
      ] as any;

      // Check If Package is already added as a product in out cart
      const isHotelAlreadyAdded = state.ShoppingCartV2.products?.find(
        (product) => product.identifier === action.payload.hotelInfo.identifiers
      );

      const total = 0;
      const mainProductHotel = {
        identifier: action.payload.hotelInfo.identifiers,
        model_id: action.payload.hotelInfo.id,
        type: MainProductType.Hotel,
        name: action.payload.hotelInfo.name,
        product_amount: 0,
        product_price: 0,
        total_price: 0,
        total_net_price: 0,
      } as any;

      if (!isHotelAlreadyAdded) {
        const prevProducts = state.ShoppingCartV2.products ?? [];
        state.ShoppingCartV2.products = [...prevProducts, mainProductHotel];
      }

      const newProductTotalPrice =
        roomPricing.room_price +
        (roomPricing.infant ?? 0) *
          (action.payload.hotelInfo?.settings?.infant_price ?? 0);

      const packageIndexTemp =
        state.ShoppingCartV2.products?.findIndex((product) => {
          return product.identifier === action.payload.hotelInfo.identifiers;
        }) ?? 0;

      const prevGuestIndexes =
        current(state).ShoppingCartV2.products?.[packageIndexTemp]
          ?.related_guest_indexes ?? [];

      const combined_multi_room = [{adult:roomPricing.adult,child:roomPricing.child,infant:roomPricing.infant}, ...roomPricing.multi_room_guests]

      const newGuestIndexes:any = isMultiRoom
      ? combined_multi_room.map((room: any, roomIndex: number) => {
          const startIndex = prevGuests.length + combined_multi_room.slice(0, roomIndex).reduce(
            (acc: any, currRoom: any) => acc + currRoom.adult + currRoom.child + currRoom.infant,
            0
          );
          const roomGuestIndexes = Array.from(Array(room.adult + room.child + room.infant)).map(
            (_, i) => startIndex + i
          );
          return roomGuestIndexes;
        })
      : Array.from(Array(totalNumberOfGuests)).map(
            (_, i) => prevGuests.length + i
          );

      // SUB PRODUCT ROOM
      const subProductRoom = {
        // add uuid - for delete
        model_id: roomData.id,
        type: MainProductType.Room,
        name: roomData.name,
        product_amount: 1,
        product_price: 0,
        total_price: newProductTotalPrice,
        total_net_price: newProductTotalPrice,
        date_start: roomPricing.date_range.start,
        date_end: roomPricing.date_range.end,
        product_data: {
          ...roomPricing,
          room:roomData,
          price_hash: roomPricing.price_hash ? roomPricing.price_hash : '',
          pricingInfo: roomPricing,
          price:roomPricing,
        },
        related_guest_indexes: newGuestIndexes,
      } as any;

      const hotelProductIndex = state.ShoppingCartV2.products?.findIndex(
        (product) => {
          return product.identifier === action.payload.hotelInfo.identifiers;
        }
      );

      const prevSubProducts =
        state.ShoppingCartV2.products[hotelProductIndex].subProducts ?? ([] as any);

      state.ShoppingCartV2.products[hotelProductIndex].subProducts = [
        ...prevSubProducts,
        subProductRoom,
      ] as any;

      // in hotel level we don't need the guests - it's just for the rooms
      // state.ShoppingCartV2.products[hotelProductIndex].related_guest_indexes = [
      //   ...prevGuestIndexes,
      //   ...newGuestIndexes,
      // ];


        state.ShoppingCartV2.products[hotelProductIndex].related_guest_indexes = [...prevGuestIndexes, ...flatten(newGuestIndexes) as any]


      const totalPrice =
        state.ShoppingCartV2.products[hotelProductIndex].subProducts?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.total_price,
          0
        ) ?? 0;
      state.ShoppingCartV2.products[hotelProductIndex].total_price = totalPrice;
      state.ShoppingCartV2.products[hotelProductIndex].total_net_price = totalPrice;
      calculateTotals(state);
    },
    addFlightToCart: (state, action) => {
      const flight = action.payload.flights[0];
      const isBundleFlight = !!(action.payload.flights.length === 1 && flight.flightsInfo.flightBack);
      const isCombinedOneWay = action.payload.flights.length === 2 && !flight.flightsInfo.flightBack
      const uuidForCombinedOneWay = generateUUID()
      const agent_commission = flight.flightsInfo?.agent_additional_info?.suggested_commission ?? 0;
      const agent = action.payload.agent;
   
      initialStateShoppingCart(
        state,
        flight.flightsInfo.price.currency_sign,
        flight.flightsInfo.price.currency_sign,
        agent_commission,
        agent
      );

      const numberOfAdults = flight.pax.adult ? Number(flight.pax.adult) :0;
      const numberOfChildren = flight.pax.child ? Number( flight.pax.child) :  0;
      const numberOfInfants = flight.pax.infant ? Number(flight.pax.infant) :0;
      const totalNumberOfGuests =
        numberOfAdults + numberOfChildren + numberOfInfants;

      const packageIndexTemp =
        state.ShoppingCartV2.products?.findIndex((product) => {
          return product.identifier === flight.flightsInfo.identifier;
        }) ?? 0;

      const prevGuests = state.ShoppingCartV2.guests ?? [];

      const prevGuestIndexes =
        current(state).ShoppingCartV2.products?.[packageIndexTemp]
          ?.related_guest_indexes ?? [];

      const newGuestIndexes = [
        ...Array.from(Array(totalNumberOfGuests)).map(
          (_, i) => prevGuests.length + i
        ),
      ];

      const generateGuests = (
        pax: string,
        guestNumber: number,
        startIndex: number
      ) => {
        return Array.from(Array(guestNumber)).map((_, i) => {
          const guest: any = {
            id: '',
            g_index: startIndex + i,
            first_name: '',
            last_name: '',
            gender: '',
            type: pax,
            birthdate: null,
            passport_id: '',
            attachment: '',
          };
          flight.packageData?.settings?.guests_extra_fields &&
            flight.packageData?.settings?.guests_extra_fields.forEach(
              (field: any) => {
                guest[field.name] = '';
              }
            );

          return guest;
        });
      };

      const startIndexOfChildren = prevGuests.length + numberOfAdults;
      const startIndexOfInfants = startIndexOfChildren + numberOfChildren;

      const newAdultGuests = generateGuests(
        'adult',
        numberOfAdults,
        prevGuests.length
      );
      const newChildGuests = generateGuests(
        'child',
        numberOfChildren,
        startIndexOfChildren
      );
      const newInfantGuests = generateGuests(
        'infant',
        numberOfInfants,
        startIndexOfInfants
      );

      state.ShoppingCartV2.guests = [
        ...prevGuests,
        ...newAdultGuests,
        ...newChildGuests,
        ...newInfantGuests,
      ] as any;

      // Check If Package is already added as a product in out cart
      const isProductAlreadyAdded = state.ShoppingCartV2.products?.find(
        (product) =>
          product.identifier === flight.flightsInfo.identifier
      );

      // if it's one way - so it will date the time of depart & arrival
      // if it's bundle way - so it will date the time of depart & arrival
      const date_start = flight.flightsInfo.flightFrom ?
         flight.flightsInfo.flightFrom[0].departure_date:
          null;

      const date_end = !flight.flightsInfo.flightBack ?
         flight.flightsInfo.flightFrom[0].arrival_date:
          flight.flightsInfo.flightBack[
              (flight.flightsInfo.flightBack.length - 1)
              ].arrival_date;

      const total = 0;
      const mainProduct = {
        identifier: isBundleFlight ? flight.flightsInfo.identifier : isCombinedOneWay ? uuidForCombinedOneWay :flight.flightsInfo.identifier,
        type:  !isBundleFlight ? MainProductType.oneWayFlights : MainProductType.Flights,
        name: flight.flightsInfo.name,
        product_data: {
          flightsInfo: omit(flight.flightsInfo, 'flights_terms'),
          pax: flight.pax,
        },
        total_price: total,
        total_net_price: total,
        product_amount: 1,
        date_start: date_start,
        date_end: date_end,
        ts_booking_data:flight.flightsInfo?.ts_booking_data
      } as any;

      const isAlreadyIncludeOneWayFlight = state.ShoppingCartV2.products?.some((product) => product.identifier === null)
      if (!isProductAlreadyAdded) {
        const prevProducts = state.ShoppingCartV2.products ?? [];
          if(!isBundleFlight && prevProducts?.length > 0 && isAlreadyIncludeOneWayFlight){
            state.ShoppingCartV2.products = [...prevProducts];
          }
        else{
          state.ShoppingCartV2.products = [...prevProducts, mainProduct];
        }
      }

      const { flightBack, flightFrom, flight_terms, ...flightSharedData } =
        flight.flightsInfo;

        const flightSubProducts = current(state).ShoppingCartV2.products?.[packageIndexTemp]?.subProducts;

      // SUB PRODUCT FLIGHTS
      const subProductFlights: any[] = flightSubProducts ? [...flightSubProducts] : [];

      !isBundleFlight && action.payload.flights.map((singleFlight:any) => {

        const calculatedPrice =
          numberOfAdults * singleFlight.flightsInfo.price.adult +
          numberOfChildren * (singleFlight.flightsInfo.price.child ?? singleFlight.flightsInfo.price.adult) +
          numberOfInfants * singleFlight.flightsInfo.price.infant;
          const tPrice = singleFlight.flightsInfo.price.grandTotal ?? calculatedPrice;

        subProductFlights.push({
          id: singleFlight.id,
          identifier:singleFlight.flightsInfo.identifier,
          type: MainProductType.oneWayFlight,
          product_amount: 1,
          product_price: 0,
          total_price: tPrice,
          total_net_price: tPrice,
          related_guest_indexes: newGuestIndexes,
          product_data: {
            singleFlight,
            ...omit(singleFlight.flightsInfo , 'flights_terms'),
            ...singleFlight.pax,
          },
          subProducts: [],
        });
      });

      if (flight.flightsInfo && isBundleFlight) {

        subProductFlights[0] = {
          id: flightFrom.id,
          type: MainProductType.Flight,
          product_amount: 1,
          product_price: 0,
          total_price: 0,
          total_net_price: 0,
          related_guest_indexes: newGuestIndexes,
          product_data: {
            flightFrom,
            ...flightSharedData,
            ...flight.pax,
          },
          subProducts: [],
        };

        if (flightBack) {
          subProductFlights[1] = {
            id: flightBack.id,
            type: MainProductType.Flight,
            product_amount: 1,
            product_price: 0,
            total_price: 0,
            total_net_price: 0,
            related_guest_indexes: newGuestIndexes,
            product_data: {
              flightBack,
              ...flightSharedData,
              ...flight.pax,
            },
            subProducts: [],
          };
        }
      }

      const flightPrice = flight.flightsInfo.price;
      const newProductTotalPrice = isBundleFlight ? flightPrice.total ?? (
        numberOfAdults * (flightPrice.adult ?? 0) +
        numberOfChildren * (flightPrice.child ?? 0) +
        numberOfInfants * (flightPrice.infant ?? 0)
      ) : action.payload.flights.reduce(
        (accumulator: number, oneWayFlight: any) => {
          const oneWayFlightPrice = oneWayFlight.flightsInfo.price;
          const calculatedTotalPrice =
          numberOfAdults * oneWayFlightPrice.adult +
          numberOfChildren * (oneWayFlightPrice.child ?? oneWayFlightPrice.adult) +
          numberOfInfants * oneWayFlightPrice.infant;
          const tPrice = oneWayFlightPrice.grandTotal ?? calculatedTotalPrice;
          return accumulator + tPrice;
        },
        0
      );

      const packageIndex = state.ShoppingCartV2.products.findIndex(
        (product) => {
          if(isCombinedOneWay) return product.identifier === uuidForCombinedOneWay
          return product.identifier === flight.flightsInfo.identifier;
        }
      );

      const allSubProducts =
        current(state).ShoppingCartV2.products?.[packageIndex]?.subProducts;

      const oldPrice =
        allSubProducts?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.total_price,
          0
        ) ?? 0;
      const priceOfWholePackage = newProductTotalPrice + oldPrice;

      state.ShoppingCartV2.products[packageIndex].subProducts = [
        ...subProductFlights,
      ] as any;
      if(isBundleFlight){
        state.ShoppingCartV2.products[packageIndex].total_price =
          priceOfWholePackage;
        state.ShoppingCartV2.products[packageIndex].total_net_price =
          priceOfWholePackage;
      }
      state.ShoppingCartV2.products[packageIndex].related_guest_indexes = [
        ...prevGuestIndexes,
        ...newGuestIndexes,
      ];

      state.ShoppingCartV2.settings = {
        show_guest_passport: true
      }
      calculateTotals(state);
    },

    addActivityToCart: (state, action) => {
      const existingProduct: any = state.ShoppingCartV2.products?.find(
        (product) => product.identifier === action.payload.id
      );
      let product_amount = 1;
      let total_price = parseFloat(action.payload.min_price);

      if (isEmpty(state.ShoppingCartV2)) {
        state.ShoppingCartV2 = {
          orderId: 'ORDER_ID',
          orderSecret: 'ORDER_SECRET',
        } as any;
      }

      if (existingProduct) {
        product_amount = existingProduct.product_amount + 1;
        total_price *= product_amount;
        existingProduct.product_amount = product_amount;
        existingProduct.total_price = total_price.toString();
        existingProduct.total_net_price = total_price.toString();
      } else {
        const newProduct: any = {
          identifier: action.payload.id,
          type: MainProductType.Activity,
          name: action.payload.name,
          product_amount: product_amount,
          product_price: action.payload.min_price,
          total_price: total_price.toString(),
          total_net_price: total_price.toString(),
        };
        const prevProducts = state.ShoppingCartV2.products ?? [];
        state.ShoppingCartV2.products = [...prevProducts, newProduct];
      }
    },
    handleGustInfoChange(state, action) {
      const allGuests = current(state).ShoppingCartV2.guests;
      const guestId = allGuests.findIndex((guest) => {
        return guest.g_index === action.payload.g_index;
      });
      state.ShoppingCartV2.guests[guestId] = {
        ...state.ShoppingCartV2.guests[guestId],
        ...action.payload,
      };
    },
    setCompleteShoppingCart(state, action) {
      state.ShoppingCartV2 = action.payload;
    },
    handleRemoveRoom(state, action) {
      const related_guest_indexes = action.payload.related_guest_indexes;

      const lastGuestIndex =
      flatten(related_guest_indexes)[flatten(related_guest_indexes).length - 1] as number;

      const isPackage = !!action.payload.packageId;
      const identifier = action.payload.identifier;
      const mainProductIndex = current(state).ShoppingCartV2.products.findIndex(
        (product) => product.identifier === identifier
      );
      const wholeHotel = current(state).ShoppingCartV2.products[
        mainProductIndex
      ].subProducts?.find((p) => p.type === MainProductType.Hotel);

      const otherSubProductsOfPackage = current(state).ShoppingCartV2.products[
        mainProductIndex
      ].subProducts?.filter((p) => p.type !== MainProductType.Hotel) as any;

      const rooms = isPackage
        ? current(state).ShoppingCartV2.products[
            mainProductIndex
          ].subProducts?.find((p) => p.type === MainProductType.Hotel)
            ?.subProducts
        : current(state).ShoppingCartV2.products[mainProductIndex].subProducts;

      const updatedGuests = current(state)
        .ShoppingCartV2.guests.filter(
          (guest) => !flatten(related_guest_indexes).includes(guest.g_index)
        )
        .map((guest) => {
          if (guest.g_index > lastGuestIndex) {
            return {
              ...guest,
              g_index: guest.g_index - flatten(related_guest_indexes).length,
            };
          }
          return guest;
        });

      const roomThatWasRemoved = rooms?.find((room) => {
        return isEqual(room.related_guest_indexes, related_guest_indexes);
      });
      const filteredRooms = rooms
        ?.filter((room) => {
          return !isEqual(room.related_guest_indexes, related_guest_indexes);
        })
        .map((room) => {
          if (room.related_guest_indexes) {
            if (room.related_guest_indexes[0] > lastGuestIndex) {
            }
            return room;
          } else {
            return {
              ...room,
              product_amount:
                room.product_amount - related_guest_indexes.length,
            };
          }
        });

      const isLastRoom = !filteredRooms?.find(
        (room) => room.type === MainProductType.Room
      );

      const isLastProduct = state.ShoppingCartV2.products.length === 1;

      if (isLastRoom && isLastProduct) {
        state.ShoppingCartV2 = {} as any;
      } else if (isLastRoom) {
        const filteredProducts = state.ShoppingCartV2.products.filter(
          (_, i) => i !== mainProductIndex
        );
        state.ShoppingCartV2.products = filteredProducts;
        state.ShoppingCartV2.guests = updatedGuests;
        calculateTotals(state);
      } else {
        state.ShoppingCartV2.guests = updatedGuests;
        if (isPackage) {
          state.ShoppingCartV2.products[mainProductIndex].subProducts = [
            {
              ...wholeHotel,
              subProducts: filteredRooms,
            },
            ...otherSubProductsOfPackage,
          ] as any;
        } else {
          state.ShoppingCartV2.products[mainProductIndex].subProducts =
            filteredRooms as any;
        }

        const totalPrice =
          filteredRooms?.reduce(
            (accumulator: number, currentValue: Product) => {
              return accumulator + currentValue.total_price;
            },
            0
          ) ?? 0;
        const totalPriceNet =
          filteredRooms?.reduce(
            (accumulator: number, currentValue: Product) => {
              return accumulator + currentValue.total_net_price;
            },
            0
          ) ?? 0;
        state.ShoppingCartV2.products[mainProductIndex].total_price =
          totalPrice;
        state.ShoppingCartV2.products[mainProductIndex].total_net_price =
          totalPriceNet;

        calculateTotals(state);
      }
    },
    handleAdjustGuestIndexes(state, action) {
      const related_guest_indexes = flatten(action.payload.related_guest_indexes) as number[];
      const deductAmount = related_guest_indexes.length;
      const firstGuestIndex = Math.min(...related_guest_indexes);
      const recursiveUpdate = (obj: any) => {
        if (typeof obj === 'object' && obj !== null) {
          if (Array.isArray(obj.related_guest_indexes)) {
            const correctIndexes = obj.related_guest_indexes
              .filter((index: number) => {
                return !related_guest_indexes.includes(index);
            }).map((indexes: any) => {
              if (Array.isArray(indexes)) {
                return indexes.map((index: number) => {
                  if (index >= firstGuestIndex) {
                    return index - deductAmount;
                  }
                  return index;
                });
              } else if (typeof indexes === 'number') {
                if (indexes >= firstGuestIndex) {
                  return indexes - deductAmount;
                }
                return indexes;
              }
              return indexes;
            });
            obj.related_guest_indexes = correctIndexes;
          }
          Object.values(obj).forEach((value) => recursiveUpdate(value));
        }
      };
      recursiveUpdate(state);
    },
    handleRemoveFlightBundle(state, action) {
      const isLastProduct = current(state).ShoppingCartV2.products.length === 1;
      if (isLastProduct) {
        state.ShoppingCartV2 = {} as any;
      } else {
        const related_guest_indexes = action.payload.related_guest_indexes;

        const lastGuestIndex =
          related_guest_indexes[related_guest_indexes.length - 1];

        const updatedGuests = current(state)
        .ShoppingCartV2.guests.filter(
          (guest) => !related_guest_indexes.includes(guest.g_index)
        )
        .map((guest) => {
          if (guest.g_index > lastGuestIndex) {
            return {
              ...guest,
              g_index: guest.g_index - related_guest_indexes.length,
            };
          }
          return guest;
        });
        state.ShoppingCartV2.guests = updatedGuests;
        const filteredMainProducts = current(
          state
        ).ShoppingCartV2.products.filter(
          (product) => product.identifier !== action.payload.identifier
        );
        state.ShoppingCartV2.products = filteredMainProducts as any;
        calculateTotals(state);
      }
    },

    handleCustomerInfoChange(state, action) {
      state.ShoppingCartV2.customerInfo = {
        ...state.ShoppingCartV2.customerInfo,
        ...action.payload,
      };
    },
    handleAddBaggageToShoppingCart(state, action) {
      // TODO- need to add if it's round type
      const isOneWayFlight = action.payload.isOneWayFlight;
      const isRoundTrip = action.payload.isRoundTrip;
      const flightsArray = current(state).ShoppingCartV2.products[
        action.payload.staticI
      ].subProducts?.filter(
        (product) => product.type === MainProductType.Flight || product.type === MainProductType.oneWayFlight
      );
      const selectedFlight = flightsArray?.[action.payload.direction];

      const existedSubProduct: any = {
        ...selectedFlight?.subProducts?.find(
          (product: any) => product.type === action.payload.type
        ),
      };
      const calculateSubProductsTotal = (products:any) => {
        let total = 0;
        let netTotal = 0;
        products.forEach((product:any) => {
            product.subProducts?.forEach((subProduct:any) => {
                total += subProduct.total_price;
                netTotal += subProduct.total_net_price;
            });
        });
        return { total, netTotal };
    };

    const updateTotalPrice = (productsArray:any,totalPrice:number, prevTotalPrice:number, prevTotalPriceNet:number, totalPriceNet:number) => {
      if(isOneWayFlight){
        productsArray[action.payload.direction].total_price =
        productsArray[action.payload.direction].total_price +
        +totalPrice -
        prevTotalPrice;
      productsArray[action.payload.direction].total_net_price =
        productsArray[action.payload.direction].total_net_price +
        +totalPriceNet -
        prevTotalPriceNet;
      }else{
        state.ShoppingCartV2.products[action.payload.staticI].total_price =
        state.ShoppingCartV2.products[action.payload.staticI].total_price +
        totalPrice -
        prevTotalPrice;
      state.ShoppingCartV2.products[action.payload.staticI].total_net_price =
        state.ShoppingCartV2.products[action.payload.staticI]
          .total_net_price +
        totalPriceNet -
        prevTotalPriceNet;
      }

    };

      const allSubProductsWithoutLatestProduct =
        selectedFlight?.subProducts?.filter(
          (product: any) => product.type !== action.payload.type
        ) ?? [];

      if (!isEmpty(existedSubProduct)) {
        const existingRelatedGuests =
          existedSubProduct.related_guest_indexes.filter(
            (guest: number) => guest !== action.payload.relatedGuestIndex
          );

        existedSubProduct.related_guest_indexes = [
          ...existingRelatedGuests,
          ...Array(action.payload.e).fill(action.payload.relatedGuestIndex),
        ];

        existedSubProduct.product_amount = [
          ...existingRelatedGuests,
          ...Array(action.payload.e).fill(action.payload.relatedGuestIndex),
        ].length;

        existedSubProduct.total_price =
          existedSubProduct.related_guest_indexes.length *
          action.payload.price;
        existedSubProduct.total_net_price =
          existedSubProduct.related_guest_indexes.length *
          action.payload.netPrice;

        const productsArray = state.ShoppingCartV2.products[
          action.payload.staticI
        ]?.subProducts?.filter((product) => product.type === MainProductType.Flight || product.type === MainProductType.oneWayFlight );

        if (productsArray) {
          const { total: prevTotalPrice, netTotal: prevTotalPriceNet } =
            calculateSubProductsTotal(productsArray);

          productsArray[action.payload.direction].subProducts =
            action.payload.e > 0 ||
            existedSubProduct.related_guest_indexes.length > 0
              ? [...allSubProductsWithoutLatestProduct, existedSubProduct as any]
              : [...allSubProductsWithoutLatestProduct];

          const { total: totalPrice, netTotal: totalPriceNet } =
            calculateSubProductsTotal(productsArray);

            updateTotalPrice(productsArray, totalPrice, prevTotalPrice, prevTotalPriceNet, totalPriceNet)

        }

        calculateTotals(state);
        return;
      }

      const createdSubProduct = {
        type: action.payload.type,
        product_price: action.payload.price,
        related_guest_indexes: Array(action.payload.e).fill(
          action.payload.relatedGuestIndex
        ),
        product_data:{
          is_round_trip:isRoundTrip,
        },
        product_amount: Array(action.payload.e).fill(
          action.payload.relatedGuestIndex
        ).length,
        total_price: action.payload.e * action.payload.price,
        total_net_price: action.payload.e * action.payload.netPrice,
      };

      const productsArray = state.ShoppingCartV2.products[
        action.payload.staticI
      ]?.subProducts?.filter((product) => product.type === MainProductType.Flight || product.type === MainProductType.oneWayFlight );

      if (productsArray) {
        const { total: prevTotalPrice, netTotal: prevTotalPriceNet } =
          calculateSubProductsTotal(productsArray);

        productsArray[action.payload.direction].subProducts = [
          ...allSubProductsWithoutLatestProduct,
          action.payload.e > 0 ? (createdSubProduct as any) : {},
        ];

        const { total: totalPrice, netTotal: totalPriceNet } =
          calculateSubProductsTotal(productsArray);
          updateTotalPrice(productsArray, totalPrice, prevTotalPrice, prevTotalPriceNet, totalPriceNet)
      }
      calculateTotals(state);
    },
    setWholeCart(state, action) {
      state.ShoppingCartV2 = action.payload;
    },
    addCommissionByPercent(state) {
      if (
        state.agent_commission &&
        state.agent_commission.type === CommissionType.Percentage
      ) {
        const recursiveUpdate = (obj: any) => {
          if (Array.isArray(obj)) {
            obj.forEach((item) => recursiveUpdate(item));
          } else if (typeof obj === 'object' && obj !== null) {
            if ('total_price' in obj && typeof obj.total_price === 'number') {
              // obj.total_price = Math.ceil(
              obj.total_price = Number(
                obj.total_net_price /
                  (1 - Number(state?.agent_commission?.percentage ?? 0) / 100)
              );
            }
            Object.values(obj).forEach((value) => recursiveUpdate(value));
          }
        };
        recursiveUpdate(state);
      }
    },

    addCommissionByPerPerson(state) {
      const flatValue = Number(state.agent_commission.per_pax);
      const adultAndChildGuests = current(state)
        .ShoppingCartV2.guests.filter(
          (guest: any) => guest.type === 'adult' || guest.type === 'child'
        )
        .map((g) => g.g_index);

      if (flatValue && state.agent_commission.type === CommissionType.PerPax) {
        const recursiveUpdate = (obj: any) => {
          if (Array.isArray(obj)) {
            obj.forEach((item) => recursiveUpdate(item));
          } else if (typeof obj === 'object' && obj !== null) {
            if ('total_price' in obj && typeof obj.total_price === 'number') {
              const adultsOnlyGuests =
                obj.related_guest_indexes?.filter((guest: string) => {
                  return adultAndChildGuests.includes(guest);
                }) ?? [];
              if (obj.total_price !== 0) {
                obj.total_price =
                  obj.total_net_price + flatValue * adultsOnlyGuests.length;
              }
              if (
                obj.type == MainProductType.Flight ||
                obj.type == MainProductType.Suitcase ||
                obj.type == MainProductType.Trolly
              ) {
                obj.total_price = obj.total_net_price;
              }
            }
            Object.values(obj).forEach((value) => recursiveUpdate(value));
          }
        };
        recursiveUpdate(state);
      }
      state.ShoppingCartV2.total_price =
        state.ShoppingCartV2.total_net_price +
        adultAndChildGuests.length * flatValue;
    },

    agentCommissionPercent(state, action) {
      state.agent_commission.percentage = action.payload;
      state.agent_commission.type = CommissionType.Percentage;
    },
    agentCommissionPerPerson(state, action) {
      state.agent_commission.per_pax = action.payload;
      state.agent_commission.type = CommissionType.PerPax;
    },
    setCommission(state, action) {
      state.agent_commission = action.payload;
    },
    setCustomerInfo(state, action) {
      state.customerInfo = action.payload;
    },
    handleOrderSecret(state, action) {
      state.ShoppingCartV2.orderSecret = action.payload.order_secret;
    },
    clearShoppingCart(state){
      state.ShoppingCartV2 = {} as any;
    },
    clearCustomerInfo(state) {
      state.customerInfo = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        comment: '',
        order_secret: '',
        customer_secret: '',
      };
    },
    updateOrderInfo: (state, action) => {
      state.ShoppingCartV2.orderSecret = action.payload.orderSecret;
      state.ShoppingCartV2.orderId = action.payload.orderId;
    },
    updateOrderTransactionId: (state, action) => {
      state.paymentFormData.transactionId = action.payload.transaction_id;
    },
    updatePaymentRetriesCount: (state, action) => {
      state.paymentFormData.paymentRetries = action.payload.paymentRetries;
    },
  },
});

export const {
  addPackageToCart,
  updateInfants,
  addActivityToCart,
  handleGustInfoChange,
  setCompleteShoppingCart,
  handleCustomerInfoChange,
  handleRemoveRoom,
  addFlightToCart,
  handleAddBaggageToShoppingCart,
  addHotelToCart,
  setWholeCart,
  addCommissionByPercent,
  addCommissionByPerPerson,
  agentCommissionPercent,
  agentCommissionPerPerson,
  setCustomerInfo,
  setCommission,
  handleRemoveFlightBundle,
  clearCustomerInfo,
  clearShoppingCart,
  handleOrderSecret,
  updateOrderInfo,
  updateOrderTransactionId,
  updatePaymentRetriesCount,
  handleAdjustGuestIndexes
} = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;

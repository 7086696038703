import React from 'react';

const TrollySVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="35"
      viewBox="0 0 25 35"
      fill="none"
    >
      <g clipPath="url(#clip0_11_41)">
        <path
          d="M22.1591 9.08523H18.1818V4.96094C19.1364 4.84091 19.9034 3.94957 19.8864 2.98579C19.8864 2.1669 19.37 1.41832 18.6016 1.12287C14.7067 -0.375001 10.2926 -0.375001 6.39774 1.12287C4.51421 1.7777 4.83097 4.74006 6.81748 4.95667V9.08523H2.84021C1.27416 9.08523 -0.000701904 10.3601 -0.000701904 11.9261V27.8352C-0.000701904 29.4013 1.27416 30.6761 2.84021 30.6761H4.54475V32.3807C4.64561 34.576 7.04759 34.0256 8.52203 34.0852C10.5007 33.9695 10.2358 32.1072 10.2266 30.6761H14.772V32.3807C14.8729 34.576 17.2749 34.0256 18.7493 34.0852C20.728 33.9695 20.4631 32.1072 20.4538 30.6761H22.1584C23.7244 30.6761 24.9993 29.4013 24.9993 27.8352V11.9261C24.9993 10.3601 23.7244 9.08523 22.1584 9.08523H22.1591ZM6.25001 2.9865C6.25001 2.63423 6.47302 2.31179 6.80612 2.18395C10.4396 0.78693 14.5611 0.78622 18.1939 2.18395C18.8331 2.40909 18.9439 3.33594 18.3715 3.70099C18.1449 3.85582 17.8722 3.88849 17.6243 3.7919C15.9929 3.15767 14.2692 2.83594 12.5007 2.83594C10.7323 2.83594 9.00782 3.15767 7.37714 3.7919C6.85228 4.0142 6.23225 3.54687 6.25072 2.9865H6.25001ZM7.95455 4.79545C10.858 3.71165 14.1421 3.71165 17.0455 4.79545V9.08594H7.95455V4.79545ZM9.09092 32.3814C9.09092 32.6946 8.83595 32.9496 8.52274 32.9496H6.25001C5.9368 32.9496 5.68183 32.6946 5.68183 32.3814V30.6768H9.09092V32.3814ZM19.3182 32.3814C19.3182 32.6946 19.0632 32.9496 18.75 32.9496H16.4773C16.1641 32.9496 15.9091 32.6946 15.9091 32.3814V30.6768H19.3182V32.3814ZM23.8636 27.8359C23.7607 30.0305 21.3651 29.4808 19.8864 29.5405H15.3409C11.7763 29.5305 6.37998 29.5476 2.84092 29.5405C1.90129 29.5405 1.13637 28.7756 1.13637 27.8359V11.9268C1.13637 10.9872 1.90129 10.2223 2.84092 10.2223H7.38637C10.4922 10.223 19.1797 10.2223 22.1591 10.2223C23.0987 10.2223 23.8636 10.9872 23.8636 11.9268V27.8359Z"
          fill="black"
        />
        <path
          d="M9.09092 16.4716H15.9091C16.223 16.4716 16.4773 16.2173 16.4773 15.9034V12.4943C16.4773 12.1804 16.223 11.9261 15.9091 11.9261H9.09092C8.777 11.9261 8.52274 12.1804 8.52274 12.4943V15.9034C8.52274 16.2173 8.777 16.4716 9.09092 16.4716ZM9.6591 13.0625H15.3409V15.3352H9.6591V13.0625Z"
          fill="black"
        />
        <path
          d="M19.8864 22.7216H5.11363C4.38068 22.7109 4.37357 23.8686 5.11363 23.858H19.8864C20.6193 23.8686 20.6264 22.7109 19.8864 22.7216Z"
          fill="black"
        />
        <path
          d="M19.8864 18.7443H5.11363C4.38068 18.7337 4.37357 19.8913 5.11363 19.8807H19.8864C20.6193 19.8913 20.6264 18.7337 19.8864 18.7443Z"
          fill="black"
        />
        <path
          d="M19.8864 26.6989H5.11363C4.38068 26.6882 4.37357 27.8459 5.11363 27.8352H19.8864C20.6193 27.8459 20.6264 26.6882 19.8864 26.6989Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_41">
          <rect width="25" height="34.1058" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrollySVG;

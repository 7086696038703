import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import {
  CommissionType,
  addCommissionByPerPerson,
  addCommissionByPercent,
  agentCommissionPerPerson,
  agentCommissionPercent,
} from '../../ShoppingCartSliceV2';
import {  Input } from 'antd';
import './agentActionProfit.less';

interface Props {
  isMangeOrderPage: boolean;
}

const AgentActionProfit = ({ isMangeOrderPage }: Props) => {
  const { shoppingCartTranslations: t, general: general_t } = useSelector(
    (data: RootState) => data.config.translations
  );
  const { agent_commission, ShoppingCartV2 } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );




  const dispatch = useDispatch();

  const handleCommissionPerPersonChange = (value: string | number) => {
    const nonEmptyValue = value ?? 0;

    if (!isNaN(Number(nonEmptyValue))) {
      dispatch(agentCommissionPerPerson(Number(nonEmptyValue)));

      setTimeout(() => {
        dispatch(addCommissionByPerPerson());
      }, 100);
    }
  };
  const handleCommissionPerPercentageChange = (value: string | number) => {
    let nonEmptyValue = value ?? 0;
    if(Number(nonEmptyValue) >= 100 )nonEmptyValue = 99;

    if (!isNaN(Number(nonEmptyValue))) {
      dispatch(agentCommissionPercent(Number(nonEmptyValue)));

      setTimeout(() => {
        dispatch(addCommissionByPercent());
      }, 100);
    }
  };

  return (
    <div className="agent-action-profit">
      <div
        className={`input-wrapper ${
          agent_commission?.type !== CommissionType.Percentage
            ? 'input-wrapper-disabled'
            : ''
        }`}
        onClick={(e) => {
          if (agent_commission?.type === CommissionType.PerPax) {
            handleCommissionPerPercentageChange(
              Number(agent_commission?.percentage ?? 0)
            );
          }
        }}
      >
        <p>{t.grossProfitPercentage}</p>
        <div>
          <Input
            className="agent--action-input"
            addonAfter={<div>%</div>}
            onChange={(e) =>
              handleCommissionPerPercentageChange(e.target.value)
            }
            value={agent_commission?.percentage ?? 0}
          />
        </div>
      </div>
      <span>{t.or}</span>
      <div
        className={`input-wrapper ${
          agent_commission?.type !== CommissionType.PerPax
            ? 'input-wrapper-disabled'
            : ''
        }`}
        onClick={() => {
          if (agent_commission?.type === CommissionType.Percentage)
            handleCommissionPerPersonChange(Number(agent_commission?.per_pax ?? 0));
        }}
      >
        <p>{t.profitPerPerson}</p>
        <Input
          className="agent--action-input"
          value={agent_commission?.per_pax ?? 0}
          addonAfter={<div>{ShoppingCartV2.currency_sign}</div>}
          onChange={(e) => handleCommissionPerPersonChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default AgentActionProfit;

import {useQuery} from "@tanstack/react-query";
import apiClient from '@skygroup/shared/API/apiClient';
import endpoints from "../../utils/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { clearCustomerInfo, setWholeCart } from "../ShoppingCart/ShoppingCartSliceV2";
import openNotification, { NotificationType } from "../../utils/openNotification";
import  {ApiStatus} from "@skygroup/shared/components/crud-helpers/ModelApiMessage";

export const useOrderGetApi = (orderId: string) => {
    const { checkout:t,  } = useSelector(
        (state: RootState) => state.config.translations
    );
    const dispatch = useDispatch();
    const orderGet = useQuery<any, any>(
        ['order-get', orderId],
        async () => {
                return await apiClient
                    .get(`${endpoints.ORDER.GET}?order_secret=${orderId}`)
                    .then(data => {
                        if (data.data.status == ApiStatus.error) {
                            return {
                                cartNotValid:true,
                                isSaved:true
                            };
                        } else {
                            return {...data.data, isSaved:true};
                        }
                    }).catch((err) => {
                        dispatch(clearCustomerInfo());
                        localStorage.removeItem('currentCustomer');
                        // TODO change to specific code
                        if(err.response.data.statusCode === 404){
                            return {
                                cartNotValid:true,
                                isSaved:true
                            };
                        }else{
                            openNotification(t.orderFailedToLoad, NotificationType.Error);
                            return {};
                            throw err;
                        }
                    });
        },
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled:!!orderId
        }
    );

    return orderGet;
};



export const updateGuestInfo = (formData:any, orderSecret:string, setIsLoading:any) => {

    const body = {
        "order_secret" : orderSecret,
        "customer_info":formData.customerInfo,
        "guests":formData.guests
    };
    setIsLoading(true);
    return apiClient
        .post(`${endpoints.ORDER.UPDATE_GUESTS}`, body)
        .then((res) => { return res; })
        .finally(() => {
            setIsLoading(false);
        });

};
export const updateCommission = (formData:any, orderSecret:string, setIsLoading:any) => {

    setIsLoading(true);
    return apiClient
        .post(`${endpoints.AGENT.UPDATE_COMMISSION}?orderSecret=${orderSecret}`, formData)
        .then((res) => { return res; })
        .finally(() => {
            setIsLoading(false);
        });

};

export const updateOrderApi = (formData:any, orderSecret:string, setIsLoading:any) => {

    formData["secret"] = orderSecret;

    console.log(formData);
    setIsLoading(true);
    return apiClient
        .post(`${endpoints.ORDER.UPDATE}`, formData)
        .then((res) => { return res })
        .finally(() => {
            setIsLoading(false);
        });

};

export const  useCreateOrderApi = () => {
    const dispatch = useDispatch();
    const { ShoppingCartV2} = useSelector(
        (state: RootState) => state.shoppingCartV2
    );

    const createOrderApi = (formData: any, setIsLoading:any) => {
        setIsLoading(true);
        dispatch(setWholeCart({...ShoppingCartV2, isSaved:true}));
        return apiClient
            .post(endpoints.ORDER.CREATE_MS, formData)
            .then((res) => { return res })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return createOrderApi;
};

export const loadPayment = (formData: any, setIsLoading:any, currentLang:any) => {

    setIsLoading(true);
    return apiClient
        .post(endpoints.PAYMENT.FORM_V2 + "?lang=" + currentLang, formData)
        .then((res) => { return res })
        .finally(() => {
            setIsLoading(false);
        });
}

export const updateFormCustomData = (formData: any) => {

    return apiClient
        .post(endpoints.PAYMENT.CUSTOM_DATA_UPDATE, formData)
        .then((res) => { return res })
        .finally(() => {
        });
}

export const loadTransaction = (formData: {transaction_id: string, secret: string}, setIsLoading:any) => {

    setIsLoading(true);
    return apiClient
        .get(endpoints.PAYMENT.TRANSACTION_STATUS + `?transaction_id=${formData.transaction_id}&secret=${formData.secret}`)
        .then((res) => { return res })
        .finally(() => {
            setIsLoading(false);
        });
}
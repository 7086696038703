import { Modal } from 'antd';
import './inputWithMovingPlaceholder.less';
import React, { useRef, useState } from 'react';
import { Input } from 'antd';

interface InputWithMovingPlaceholderProps
  extends React.ComponentProps<typeof Input> {
  placeholder?: string;
  onChange: any;
  value: any;
}

const InputWithMovingPlaceholder = ({
  placeholder,
  value = '',
  onChange,
}: InputWithMovingPlaceholderProps) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (value === '') {
      setFocused(false);
    }
  };

  const handleChange = (e: any) => {
    onChange(e);
  };

  const handleClickPlaceholder = () => {
    setFocused(true);
    ref.current.focus();
  };

  const ref = useRef<any>(null);
  return (
    <div
      className={`input-with-moving-placeholder ${
        focused || value !== '' ? 'ant-input-focused' : ''
      }`}
    >
      {placeholder && (
        <span className="placeholder" onClick={handleClickPlaceholder}>
          {placeholder}
        </span>
      )}
      <Input
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={ref}
      />
    </div>
  );
};

export default InputWithMovingPlaceholder;

import React from 'react';
import { roundUpCurrency } from '@skygroup/shared/utils/roundUpCurrency';
import './priceTooltip.less';

const PriceTooltip = ({ taxes, roomPricingT }: { taxes: any, roomPricingT: any; }) => {
  return  (
    <div className="taxes-tooltip">
      <h2>{roomPricingT?.additional_price_info}</h2>
      <div className="tax-items-wrapper">
        {taxes.map((tax: any, index: number) => {
            return (
              <p className="tax-item" key={index}>
                <span className="bold">{roomPricingT?.[tax?.name] || tax?.name}{String.fromCharCode(160)}</span>
                <span className="bold">
                  {roundUpCurrency(tax?.amount, 2)}
                  {String.fromCharCode(160)}
                  {tax?.currency_code}
                </span>
                {String.fromCharCode(160)}
                <span className="bold">
                  {tax?.included_by_supplier ? roomPricingT?.is : roomPricingT?.not}
                </span>{' - '}
                  {roomPricingT?.includedInPrice}.
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default PriceTooltip;

import React, { useMemo, useState } from 'react';
import './flightOutLineSection.less';

import { FaPlane, FaExclamationCircle } from 'react-icons/fa';
import { AiOutlineDown } from 'react-icons/ai';
import { Button, Col, Modal, Row, Tooltip } from 'antd';
import moment from 'moment';
import FlightBaggage from '../FlightBaggage/FlightBaggage';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import FlightVerticalOutLineSection from './FlightVerticalOutLineSection';
import { Product } from '../../../../ShoppingCart/ShoppingCartSliceV2';
import ShoppingCartAdditionalItem from '../../../../ShoppingCart/products/ShoppingCartAdditionalItem/ShoppingCartAdditionalItem';
import useGetFlightAdditionalFares from '../../../../ViewFlight/ViewFlightApi';
import Amenities from '../../../../ViewFlight/AdditionalFaresFlightBox/Amenities';
import useGetGrossPrice from '../../../../../hooks/useGetGrossPrice';
import {message} from "antd";
import useSiteLang from '../../../../../hooks/useSiteLang';
import AdditionalFaresFlightModal from '../AdditionalFaresFlightModal';
import { isEmpty } from 'lodash';


interface Props {
  flightData: any;
  flightOutlineData: any;
  flightSubProducts?: Product[];
  isMinimized?: boolean;
  flightIdentifier: string;
  tsBookingData?:any
  setDynamicFlights?:any
  isFlightFrom?:boolean,
  isDynamicFlight?:boolean
}

const HintIconWrapper = ({setShowStopDetails,showStopDetails }:any) => (
  <div
    onClick={() => setShowStopDetails((prev:any) => !prev)}
    className="hint-icon-wrapper"
  >
    <AiOutlineDown
      style={{
        transform: showStopDetails ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
      size={24}
    />
  </div>
);

const FlightOutLineSection: React.FC<Props> = ({
  flightData,
  flightOutlineData,
  flightSubProducts,
  isMinimized,
  tsBookingData,
  flightIdentifier,
  setDynamicFlights,
  isFlightFrom,
  isDynamicFlight = false
}) => {
  const {
    searchDatePicker: t,
    checkout: checkout_t,
    viewFlight: viewFlight_t,
    general: general_t,
  } = useSelector((data: RootState) => data.config.translations);
  const { siteConfig } = useSelector((state: RootState) => state.config.config);
  const [showStopDetails, setShowStopDetails] = useState(false);

  const fareData = flightData[0]?.fare;
  const depMomentTime = moment(flightData[0]?.departure_date * 1000);
  const arrMomentTime = moment(
    flightData[flightData.length - 1]?.arrival_date * 1000
  );
  const flightDuration = moment.duration(arrMomentTime.diff(depMomentTime));
  const flightDurationDays = Math.floor(flightDuration.asDays());

  const { ShoppingCartV2 } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );

  const departMoment = moment(flightOutlineData.departure_date * 1000).utc();
  const arrivalMoment = moment(flightOutlineData.arrival_date * 1000).utc();

  const memoizedBookingData = useMemo(() => tsBookingData, []);

  const {data:additionalFares} = useGetFlightAdditionalFares(memoizedBookingData, flightIdentifier);
  const [isFareModalOpen, setIsFareModalOpen] = useState(false);
  const fareName = flightData[0]?.fare?.name;
  const fareIdentifier = flightData.identifier;

  return (
    <>
      <div className="flight-outline-section">
        <Row
          align={'middle'}
          justify={'space-between'}
          style={{ width: '100%' }}
        >
          <Col md={5} xs={24}>
            <div className="time">
              <b>{flightOutlineData.from.code}</b>
              <div className="hour">
                {departMoment.format('HH:mm')}
              </div>
              <div className="week-day">
                <Tooltip
                  placement={'bottom'}
                  title={
                    t.date +
                    ' ' +
                      departMoment.format('DD/MM/YYYY' + ' HH:mm')
                  }
                >
                  {departMoment.format('dddd - DD/MM')}
                </Tooltip>
              </div>
            </div>
          </Col>
          <Col md={9} xs={24} >
            <div className="airline">
              { fareData?.name ?
                  <div className={"flight-fare"}>{viewFlight_t?.ticketFare  + " " + fareData.name}</div>
                  : "" }
              <div className="airline-line" >
                <div className="airline-horizontal-line" />
                  <FaPlane className="direction" size={35} />
              </div>
              <div className="flight-joint-name">
                {flightOutlineData.flight_joint_name}
              </div>
              {flightOutlineData.num_of_stops === 0 && (
                <>
                  <div className={'flight-type'}>{t.directFlight}</div>
                  {
                    flightOutlineData.operatedBy ?
                        <div className={`flight-type`}>
                          {`${t.operated_by_airlines}: ${flightOutlineData?.operatedBy}`}
                        </div>
                        : ""
                  }
                  <div className="logos-wrapper">
                    {flightOutlineData?.operatedByAirlineLogos &&
                      flightOutlineData?.operatedByAirlineLogos.length > 0 &&
                      flightOutlineData?.operatedByAirlineLogos.map((logo: string) => (
                        <img key={logo} src={logo} className="logo small" />
                      ))}
                  </div>
                </>
              )}

              {flightOutlineData.num_of_stops !== 0 && (
                <>
                  <Tooltip
                    placement={'top'}
                    title={flightOutlineData?.stop_location_codes}
                  >
                    <div className={`flight-type flight-stop`}>
                      {flightOutlineData.num_of_stops == 1
                        ? t.stop_1
                        : flightOutlineData.num_of_stops + t.stops}
                      <FaExclamationCircle />
                    </div>
                  </Tooltip>
                  {
                    flightOutlineData.operatedBy ?
                        <Tooltip placement={"bottom"}
                        title={<div className="logos-wrapper">
                          {flightOutlineData?.operatedByAirlineLogos &&
                              flightOutlineData?.operatedByAirlineLogos.length > 0 &&
                              flightOutlineData?.operatedByAirlineLogos.map((logo: string) => (
                                  <img key={logo} src={logo} className="logo small"/>
                              ))}
                        </div>}>
                          <div className={`flight-type`}>
                            {`${t.operated_by_airlines}: ${flightOutlineData?.operatedBy}`}
                          </div>
                        </Tooltip>
                        : ""
                  }
                  {!(isMinimized && showStopDetails) && <HintIconWrapper setShowStopDetails={setShowStopDetails} showStopDetails={showStopDetails}/>}
                </>
              )}
            </div>
          </Col>

          <Col md={5} xs={24}>
            <div className="time">
              <b>{flightOutlineData.to.code}</b>
              <div className="hour">
                {arrivalMoment.format('HH:mm')}
                {flightDurationDays ? (
                  <div className="days-difference">+{flightDurationDays}</div>
                ) : (
                  ''
                )}
              </div>

              <div className="week-day">
                <Tooltip
                  placement={'bottom'}
                  title={
                    t.date +
                    ' ' +
                    arrivalMoment.format(siteConfig.dateFormat + ' HH:mm')
                  }
                >
                  {arrivalMoment.format('dddd - DD/MM')}
                </Tooltip>
              </div>
            </div>
          </Col>

          <Col md={isMinimized ? 24 : 4} xs={24}>
            <FlightBaggage
              insIncludeSuitCase={
                !!flightOutlineData.baggage?.is_include_suitcase
              }
              isIncludeTrolly={!!flightOutlineData.baggage?.is_include_trolly}
            />
          </Col>
          {flightOutlineData.available_seats > 0 ?
              <div className={`available-seats available-seats-${flightOutlineData.available_seats}`}>{flightOutlineData.available_seats} {general_t.availableSeats}</div>
              : ""}
        </Row>
      </div>
        <ShoppingCartAdditionalItem subProducts={flightSubProducts}  text={ checkout_t.additionalBaggage} isBaggages/>

      {showStopDetails && (
        <FlightVerticalOutLineSection flightData={flightData} />
      )}

      {!isEmpty(additionalFares) && isDynamicFlight && (
        <Button
          className="btn-style-5 open-fares-modal-button"
          onClick={() => setIsFareModalOpen(true)}
        >{`${
          fareName
            ? `${viewFlight_t.selectedFare} ${fareName}`
            : viewFlight_t.openFares
        }`}</Button>
      )}

      {isMinimized && showStopDetails && <HintIconWrapper />}

      <AdditionalFaresFlightModal
        additionalFares={additionalFares}
        fareName={fareName}
        isFareModalOpen={isFareModalOpen}
        isFlightFrom={isFlightFrom}
        setDynamicFlights={setDynamicFlights}
        setIsFareModalOpen={setIsFareModalOpen}
        fareIdentifier={fareIdentifier}
       />
    </>
  );
};

export default FlightOutLineSection;
import React from 'react';

interface Props{
  className:string
}

const ShoppingCartSVG = ({className}:Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      className={className}
    >
      <path d="m83.191 496.616c-.104 4.043 1.431 7.958 4.254 10.854s6.697 4.53 10.742 4.53h315.627c4.045 0 7.918-1.633 10.741-4.53s4.357-6.811 4.254-10.854l-1.014-39.616h-343.59z" />
      <path d="m419.215 121.685c-.208-8.132-6.86-14.616-14.995-14.616h-66.72v-25.569c0-44.939-36.561-81.5-81.5-81.5s-81.5 36.561-81.5 81.5v25.569h-66.72c-8.135 0-14.787 6.484-14.995 14.616l-7.812 305.315h342.055zm-214.715-40.185c0-28.397 23.103-51.5 51.5-51.5s51.5 23.103 51.5 51.5v25.569h-103zm122.186 245.842c-2.859 4.49-7.711 6.944-12.666 6.944-2.758 0-5.546-.759-8.044-2.35l-34.976-22.275v28.757l15.604 15.604c5.858 5.858 5.858 15.355 0 21.213-2.929 2.928-6.769 4.393-10.606 4.393-3.839 0-7.678-1.464-10.606-4.394l-9.392-9.39-9.391 9.391c-5.857 5.858-15.355 5.858-21.213 0s-5.858-15.355 0-21.213l15.604-15.604v-28.757l-34.976 22.275c-2.497 1.591-5.287 2.35-8.044 2.35-4.955 0-9.806-2.454-12.666-6.944-4.45-6.987-2.393-16.259 4.595-20.709l51.091-32.539v-46.562c0-8.284 6.716-15 15-15s15 6.716 15 15v46.562l51.091 32.539c6.987 4.45 9.045 13.721 4.595 20.709z" />
    </svg>
  );
};

export default ShoppingCartSVG;

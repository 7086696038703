import React from 'react';

const ChangeFlightSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M10.1093 22.8846C9.84772 22.7857 9.55079 22.8493 9.35284 23.0472L7.66318 24.7369C7.51472 24.8924 7.43695 25.0975 7.45816 25.3166C7.47937 25.5358 7.59955 25.7196 7.78336 25.8327L12.2938 28.703L15.1641 33.2135C15.2773 33.3973 15.4681 33.5104 15.6873 33.5387C15.7156 33.5387 15.7368 33.5387 15.7651 33.5387C15.9489 33.5387 16.1327 33.468 16.267 33.3337L17.9567 31.644C18.1546 31.446 18.2183 31.1491 18.1193 30.8875L16.9386 27.8122L22.4813 23.0331L27.4372 30.2725C27.5573 30.4492 27.7482 30.5553 27.9532 30.5765C28.1653 30.5977 28.3704 30.5199 28.5188 30.3715L30.6327 28.2576C30.8235 28.0667 30.8872 27.791 30.8023 27.5365L27.7765 18.4661L30.8094 15.8503C31.6578 15.1221 32.1739 14.0687 32.2163 12.9517C32.2587 11.8347 31.8345 10.7389 31.0427 9.95413C30.2509 9.16233 29.1551 8.73108 28.0451 8.78056C26.9281 8.82298 25.8747 9.33907 25.1466 10.1874L22.5308 13.2274L13.4603 10.2016C13.2058 10.1167 12.9301 10.1804 12.7392 10.3712L10.6254 12.4851C10.4769 12.6336 10.3992 12.8386 10.4204 13.0507C10.4416 13.2628 10.5547 13.4466 10.7244 13.5668L17.9637 18.5226L13.1846 24.0653L10.1093 22.8846ZM13.9269 25.3661L19.5474 18.8478C19.6817 18.6923 19.7382 18.4943 19.71 18.2964C19.6817 18.0984 19.5756 17.9217 19.406 17.8015L12.2161 12.881L13.4179 11.6791L22.5237 14.712C22.7924 14.804 23.0964 14.7191 23.2802 14.5L26.2141 11.0994C26.6878 10.5409 27.3594 10.2157 28.0946 10.1874C28.8228 10.1662 29.5227 10.4278 30.0388 10.951C30.5549 11.4741 30.8306 12.1599 30.8023 12.8951C30.7741 13.6304 30.4488 14.2949 29.8903 14.7757L26.4969 17.7025C26.2777 17.8863 26.2 18.1903 26.2848 18.459L29.3177 27.5648L28.1158 28.7666L23.1953 21.5767C23.0822 21.4071 22.9055 21.301 22.7004 21.2727C22.5025 21.2445 22.2975 21.3081 22.149 21.4353L15.6307 27.0558C15.4045 27.2537 15.3267 27.5648 15.4328 27.8476L16.6276 30.9582L15.8852 31.7006L13.4038 27.7981C13.3472 27.7132 13.2765 27.6355 13.1846 27.5789L9.28214 25.0975L10.0245 24.3551L13.1351 25.5499C13.4109 25.656 13.729 25.5782 13.9269 25.352V25.3661Z"
        fill="black"
      />
      <path
        d="M20.5018 0C14.0401 0 8.01667 3.01877 4.14247 8.13723V4.80033C4.14247 4.4115 3.82433 4.09336 3.4355 4.09336C3.04667 4.09336 2.72853 4.4115 2.72853 4.80033V10.2581C2.72853 10.647 3.04667 10.9651 3.4355 10.9651H8.89332C9.28215 10.9651 9.60029 10.647 9.60029 10.2581C9.60029 9.86931 9.28215 9.55118 8.89332 9.55118H4.87065C8.45499 4.45392 14.2522 1.41394 20.5018 1.41394C31.0286 1.41394 39.59 9.97536 39.59 20.5022C39.59 22.5241 39.2718 24.5177 38.6497 26.4266C38.5295 26.7942 38.7346 27.1972 39.1022 27.3174C39.1729 27.3386 39.2506 27.3527 39.3213 27.3527C39.6183 27.3527 39.894 27.1618 39.993 26.8649C40.6575 24.8147 40.9969 22.6796 40.9969 20.5022C41.0039 9.19769 31.8062 0 20.5018 0Z"
        fill="black"
      />
      <path
        d="M37.5681 30.0321H32.1102C31.7214 30.0321 31.4033 30.3503 31.4033 30.7391C31.4033 31.1279 31.7214 31.4461 32.1102 31.4461H36.1329C32.5556 36.5504 26.7514 39.5904 20.5018 39.5904C9.97499 39.5904 1.41358 31.029 1.41358 20.5022C1.41358 18.4802 1.73171 16.4866 2.34678 14.5778C2.46696 14.2101 2.26194 13.8072 1.89432 13.687C1.51962 13.5668 1.12372 13.7718 1.00353 14.1394C0.33898 16.1897 -0.000366211 18.3247 -0.000366211 20.5022C-0.000366211 31.8066 9.19732 41.0043 20.5018 41.0043C26.9635 41.0043 32.9869 37.9856 36.8611 32.8671V36.204C36.8611 36.5928 37.1792 36.911 37.5681 36.911C37.9569 36.911 38.275 36.5928 38.275 36.204V30.7462C38.275 30.3573 37.9569 30.0392 37.5681 30.0392V30.0321Z"
        fill="black"
      />
    </svg>
  );
};

export default ChangeFlightSVG;

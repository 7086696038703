import React from 'react';
import { Spin } from 'antd';

import './crud-helper.less';
import { useSelector } from 'react-redux';
import { TbError404, TbFaceIdError } from 'react-icons/tb';

interface Props {
  children: any;
  isError: boolean;
  error: any;
  t?: any;
}

const ValidateModelLoaded = ({ children, error, isError, t }: Props) => {
  if (isError) {
    switch (error.response?.status) {
      case 404:
        return (
          <div className="model-not-found crud-message-box">
            <TbError404 size="100px" color="#43DC81" />
            <br />
            <span>{t?.modelNotFound ?? 'Page Not Found'}</span>
          </div>
        );
      case 400:
        return (
          <div className="model-failed-load crud-message-box">
            <TbFaceIdError size="100px" color="#43DC81" />
            <br />
            <span title={error.response.message}>
              {t?.failToLoad ?? 'Fail To Load'}
            </span>
          </div>
        );
      case 401:
        return (
          <div className="model-not-found crud-message-box">
            <TbFaceIdError size="100px" color="#43DC81" />
            <br />
            <span>
              {error?.data?.data?.message ||
                error?.message ||
                t?.permissionDenied}
            </span>
          </div>
        );
      case 0:
        return (
          <div className="model-failed-load crud-message-box">
            <TbFaceIdError size="100px" color="#43DC81" />
            <br />
            <span>{error?.response?.message}</span>
          </div>
        );
      case 403:
      default:
        return (
          <div className="model-not-found crud-message-box">
            <TbError404 size="100px" color="#43DC81" />
            <br />
            <span>{error?.data?.data?.message || error.message}</span>
          </div>
        );
    }
  }
  return children;
};

export default ValidateModelLoaded;

import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import React from 'react';
import { FcExpired } from 'react-icons/fc';

const CartNotValid = () => {
  const { checkout: t } = useSelector(
    (state: RootState) => state.config.translations
  );
  return (
    <div className="cart-not-valid">
      <FcExpired size={55} />
      <h2>{t.cartNoLongerValid}</h2>
    </div>
  );
};

export default CartNotValid;

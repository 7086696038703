import React, { useState } from 'react';
import './agentActions.less';
import { Button, Input, Tooltip } from 'antd';
// @ts-ignore
import compareIcon from '../assets/compare.png';
// @ts-ignore
import profitPercentageIcon from '../assets/profitPercentage.png';
// @ts-ignore
import shareIcon from '../assets/share.png';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import AgentActionShare from './AgentActionShare/AgentActionShare';
import AgentActionProfit from './AgentActionProfit/AgentActionProfit';
import AddUserModal from './AddUserModal/AddUserModal';
import { IoWarning } from 'react-icons/io5';
import { CommissionType } from '../ShoppingCartSliceV2';

export enum AgentActionTypes {
  Compare = 'compare',
  ProfitPercentage = 'profitPercentage',
  Share = 'share',
}

export interface AgentAdditionalInfo {
  suggested_commission: number;
  supplier_name: string;
  ts_type: string;
}

interface Props {
  isMangeOrderPage: boolean;
  addUserModal: {
    open: boolean;
    customer_secret: string;
  };
  setAddUserModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      customer_secret: string;
    }>
  >;
}

const AgentActions = ({
  addUserModal,
  setAddUserModal,
  isMangeOrderPage,
}: Props) => {
  const [selectedAction, setSelectedAction] = useState<AgentActionTypes | null>(
    AgentActionTypes.ProfitPercentage
  );
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const { agent_commission } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );

  if(!agent_commission) return null;

  const isCommissionZero =
    agent_commission?.type === CommissionType.PerPax
      ? agent_commission?.per_pax == 0
        ? true
        : false
      : agent_commission?.percentage == 0
      ? true
      : false;


  return (
    <div className="agent-actions">
      <div className="header">
        <span className="text">{t.agentOptions}</span>

        <Button className="action-add-new-cart btn-style-5">
          {t.createNewUser}
        </Button>
      </div>

      <div className="actions">
        <div
          className={`action ${
            selectedAction === AgentActionTypes.ProfitPercentage
              ? 'action-selected'
              : ''
          }`}
          onClick={() => setSelectedAction(AgentActionTypes.ProfitPercentage)}
        >
          <img src={profitPercentageIcon} alt="Profit Percentage Icon" />
          <Button className="btn-style-5">{t.profitPercentageChange}</Button>
        </div>
        <div
          className={`action ${
            selectedAction === AgentActionTypes.Share ? 'action-selected' : ''
          }`}
          onClick={() => setSelectedAction(AgentActionTypes.Share)}
        >
          <img src={shareIcon} alt="Share Icon" />
          <Button className="btn-style-5">{t.send}</Button>
        </div>
        <Tooltip
          title={t.compareDisableForAgent}
          placement={'bottom'}
          overlayClassName="cart-action-tooltip"
        >
          <div
            className={`action action-disabled ${
              selectedAction === AgentActionTypes.Compare
                ? 'action-selected'
                : ''
            }`}
            // onClick={() => setSelectedAction(ActionTypes.Compare)}
          >
            <img src={compareIcon} alt="Compare Icon" />
            <Button className="btn-style-5">{t.comparePrice}</Button>
          </div>
        </Tooltip>
      </div>

      {selectedAction === AgentActionTypes.ProfitPercentage && (
        <AgentActionProfit isMangeOrderPage={isMangeOrderPage} />
      )}

      {selectedAction === AgentActionTypes.Share && (
        <AgentActionShare setSelectedAction={setSelectedAction} />
      )}


      {isCommissionZero && <div className="net-price-warning">
        <IoWarning color='orange' size={22}/> {t.netPriceWarning}
      </div>}
    </div>
  );
};

export default AgentActions;

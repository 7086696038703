import React, { useMemo, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import ShoppingCartSVG from '../assets/ShoppingCartSVG';
import { Badge, Button, message, Tooltip } from 'antd';
import { CgTrash } from 'react-icons/cg';
import { setCustomerInfo, setWholeCart } from '../ShoppingCartSliceV2';
import { useCreateOrderApi } from '../../Checkout/CheckoutApis';

interface Props {
  shoppingCartOpen: boolean;
  checkout: boolean;
  setShoppingCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAddUserModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      customer_secret: string;
    }>
  >;
}

const ShoppingCartHeader = ({
  shoppingCartOpen,
  checkout,
  setShoppingCartOpen,
  setAddUserModal,
}: Props) => {
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const { freePackage } = useSelector((data: RootState) => data.shoppingCart);

  const { siteConfig } = useSelector((data: RootState) => data.config.config);
  const { customerInfo } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );
  const { agentMode } = useSelector((data: RootState) => data.config.agentInfo);

  const handleEditUser = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAddUserModal({
      open: true,
      customer_secret: customerInfo.customer_secret,
    });
  };

  const handleAssignUser = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAddUserModal({ open: true, customer_secret: '' });
  };

  const dispatch = useDispatch();

  const handleClearCartAndCurrentUser = (
    e: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(setWholeCart({}));
    dispatch(
      setCustomerInfo({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        comment: '',
        secret: '',
      })
    );
    localStorage.removeItem('currentCustomer');
  };
  const { ShoppingCartV2, agent_commission } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );

  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const createOrderApi = useCreateOrderApi();

  const handleCreateOrder = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const responses = await createOrderApi(
      {
        ...ShoppingCartV2,
        agent_commission: { ...agent_commission, is_commission_per_order: 1 },
      },
      setIsCreateLoading
    );

    if (responses.data.status == 'success') {
      dispatch(
        setWholeCart({
          ...ShoppingCartV2,
          agent_commission: agent_commission,
          orderSecret: String(responses.data.data.secret),
          isSaved: true,
          orderId: String(responses.data.data.order_id),
        })
      );
    }
  };

  const handleCopyOrder = () => {
    const orderURL =
      window.location.origin + `/order/manage?id=${ShoppingCartV2.orderSecret}`;
    navigator.clipboard
      .writeText(orderURL)
      .then((r) => {
        message.success('קישור הועתק בהצלחה');
      })
      .catch((e) => {
        window.parent.postMessage({ action: 'copy', text: orderURL }, '*');
        //message.warning("לא ניתן להעתיק קישור");
      });
  };

  const isShowCreateOrderButton =
    !checkout && agentMode && customerInfo.first_name && ShoppingCartV2.orderId;
  const isShowCreateUserButton =
    !checkout && agentMode && !customerInfo.first_name;

  // const selectedProductsCount = useMemo(() => {
  //   let count = 0;
  //   if (Array.isArray(ShoppingCartV2)) {
  //     count = ShoppingCartV2.reduce((sum, item) => {
  //       let valueToAdd = 0;
  //       item?.products?.forEach((prod: any) => {
  //         console.log('123 prod: ', prod);

  //         valueToAdd += prod?.subProducts?.length || 1;
  //       });

  //       return sum + valueToAdd;
  //     }, 0);
  //   } else {
  //     ShoppingCartV2?.products?.forEach((prod: any) => {
  //       console.log('123 prod: ', prod);
  //       count += prod?.subProducts?.length || 1;
  //     });
  //   }
  //   return count;
  // }, [ShoppingCartV2]);

  return (
    <div
      className={`header ${!shoppingCartOpen && 'header-closed'}`}
      onClick={() => !checkout && setShoppingCartOpen((prev) => !prev)}
    >
      {/* {
        siteConfig?.logoSmall ? <img src={siteConfig?.logoSmall} className="logo" /> :<LogoSvg className="logo"/>
      } */}
      {/* <Badge count={selectedProductsCount}> */}
      <ShoppingCartSVG className="shopping-cart-svg" />
      {/* </Badge> */}
      {shoppingCartOpen ? (
        <>
          <div className="cart-title">
            {freePackage ? t.selectedItems : t?.shoppingCart}
            {isShowCreateOrderButton && (
              <Button
                className="btn-style-5 create-order-button"
                onClick={
                  ShoppingCartV2.orderSecret
                    ? handleCopyOrder
                    : handleCreateOrder
                }
                loading={isCreateLoading}
              >
                {ShoppingCartV2.orderSecret ? t.copyOrderLink : t.createOrder}
              </Button>
            )}
          </div>
          {customerInfo.first_name && agentMode && !checkout && (
            <div className="customer-name" onClick={handleEditUser}>
              <FaEdit className="edit-user" />
              {customerInfo.first_name} {customerInfo.last_name}
            </div>
          )}
          {isShowCreateUserButton && (
            <Button
              className="btn-style-5 assign-customer-button"
              onClick={handleAssignUser}
            >
              {t.assignCustomer}
            </Button>
          )}
          {agentMode && customerInfo.first_name && !checkout && (
            <Tooltip title={t.clearCart}>
              <CgTrash
                className="clear-cart"
                size={25}
                onClick={(e) => handleClearCartAndCurrentUser(e)}
              />
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <div>{t?.myVacation}</div>
        </>
      )}
    </div>
  );
};

export default ShoppingCartHeader;

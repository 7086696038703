import React from 'react';
import MealsSvg from './assets/MealsSVG';
import TrollySVG from './assets/TrollySVG';
import SuitcaseSVG from './assets/SuitcaseSVG';
import SeatingSVG from './assets/SeatingSVG';
import CancellationCard from './assets/CancellationCard';
import ChangeFlightSVG from './assets/ChangeFlightSVG';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const getAdditionalFaresFlightIcon = (set: string) => {
  switch (set) {
    case 'MEAL':
      return <MealsSvg />;
    case 'TROLLY':
      return <TrollySVG />;
    case 'SUITCASE':
      return <SuitcaseSVG />;
    case 'SEATING':
      return <SeatingSVG />;
    case 'CANCELATION':
      return <CancellationCard />;
    case 'CHANGEFLIGHT':
      return <ChangeFlightSVG />;
    default:
      return null;
  }
};

export const useGetAdditionalFaresName = () => {
  const { viewFlight: t } = useSelector(
    (data: RootState) => data.config.translations
  );
  const getAdditionalFaresName = (set: string) => {
    switch (set) {
      case 'MEAL':
        return t.meal;
      case 'TROLLY':
        return t.trolly;
      case 'SUITCASE':
        return t.suitcase;
      case 'SEATING':
        return t.seating;
      case 'CANCELATION':
        return t.cancelation;
      case 'CHANGEFLIGHT':
        return t.changeFlight;
      default:
        return null;
    }
  };

  return getAdditionalFaresName;
};

export default getAdditionalFaresFlightIcon;

import { OptionalFilter } from "../components/SideFilters/interface";
import { AgentAdditionalInfo } from "../modules/ShoppingCart/agentActions/AgentActions";

export interface flightResponseType {
  packages: Flight[];
  total_count: string;
  next_page: number;
}

interface Baggage {
  is_include_suitcase: number;
  is_include_trolly: number;
  suitcase_weight: null;
  trolly_weight: null;
}

interface Location {
  code: string;
  name: string;
}

export interface Flight {
  agent_additional_info:AgentAdditionalInfo,
  airline: Airline;
  arrival_date: number;
  baggage: Baggage;
  currency: string;
  departure_date: number;
  duration: string;
  flight_code: string;
  flight_number: string;
  from: Location;
  num_of_stops: number;
  price: {
      adult: number,
      child: number,
      infant: number,
      currency_sign: string
  };
  stop_comment: string;
  to: Location;
  identifier:string
}

export interface FlightPrice {
  adult: number;
  infant: number;
  child: number;
  currency_sign: string;
}

export enum FlightType {
  Bundle = 'bundle',
  Dynamic = 'one_way',
  OneWay = 'one_way',
  BundleFlight = 'bundle_flight'
}

export interface FlightBundle {
  identifier: string;
  status: string;
  flightFrom: Flight[];
  flightBack: Flight[];
  price: FlightPrice;
  agent_additional_info:AgentAdditionalInfo
}

interface FlightsBundleArray {
  flights_bundle: FlightBundle[];
  optional_filters: OptionalFilter[];
  next_page: number | null;
}
export interface DynamicFlightsArray {
  flights: FlightBundle[];
  optional_filters:OptionalFilter[]
  next_page: number | null;
}

export interface FlightsBundleResponse {
  data: FlightsBundleArray;
  status: string;
}

export interface DynamicFlightResponse {
  data: DynamicFlightsArray;
  status: string;
}

type FlightIdentifier = {
  ts: number;
  t: string;
  id: number;
  fid?: number; // Optional, as it's not present in the top-level identifier
};

type FlightLocation = {
  name: string;
  code: string;
};

type Airline = {
  id: number;
  name: string;
  image: string;
  default_supplier_id: null | number;
};

export type FlightBaggage = {
  is_include_suitcase: number;
  suitcase_weight: null | number;
  enable_sale_suitcase: boolean;
  is_include_trolly: number;
  trolly_weight: null | number;
  enable_sale_trolly: boolean;
  suitcase_price?: number;
  trolly_price: number | null;
};

export type FlightView = {
  identifier: FlightIdentifier;
  from: FlightLocation;
  to: FlightLocation;
  airline: Airline;
  flight_number: string;
  num_of_stops: number;
  stop_comment: string;
  departure_date: number;
  arrival_date: number;
  duration: string;
  baggage: FlightBaggage;
};

export type PriceDetails = {
  adult: number;
  infant: number;
  child: number;
  currency_sign: string;
  full_price: number;
  avg_price: number;

  // new Amadeus API type:
  additional_services: string;
  base: number;
  grand_total: number;
  total: number;
};

type ChangeCancelTerms = {
  adult: string | number;
  child: string | number;
  infant: string | number;
};

type Terms = {
  baggage: string;
  cancel: ChangeCancelTerms;
  change: ChangeCancelTerms;
  operation: string;
  general: string[];
};

export type FightTermsType = {text: {title: string, text: string}, fareBasis: string, segment_id: string, title: string}[];

export type FlightsInfo = {
  identifier: FlightIdentifier;
  description?: string;
  status: string;
  flightFrom: FlightView;
  flightBack?: FlightView;
  flights_terms: FightTermsType;
  price: PriceDetails;
  terms: Terms;
  name: string;
  package_type: string;
  agent_additional_info:AgentAdditionalInfo
};

export type PaxCount = {
  adult: number;
  child: number;
  infant: number;
};

export type FlightViewResponse = {
  flightsInfo: FlightsInfo;
  pax: PaxCount;
};

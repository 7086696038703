import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const ShoppingCartSubsidize = () => {
  const { appliedSubsidize, freePackage} = useSelector(
    (data: RootState) => data.shoppingCart
  );

  const { subsidizedForm: t } = useSelector(
    (data: RootState) => data.config.translations
  );
  if (freePackage) {
    return null;
  }

  return (
    <div>
      <b>{t.title}</b>
      {appliedSubsidize.map((subsidize, i: number) => {
        return (
          <div key={i} className="applied-subsidize">
            <div>
              {t.forEmployee} {subsidize.first_name} {subsidize.last_name} {t.priceReducedBy}
            </div>
            <b className="price">
              {subsidize.discount}-{String.fromCharCode(160)}{subsidize.currency_sign}
            </b>
          </div>
        );
      })}
    </div>
  );
};

export default ShoppingCartSubsidize;

import React, { useEffect } from 'react';
import ScriptLoader from "./scriptLoader";

interface Props {
    siteKey: string
}
const AccessibilityPlugin = ({siteKey} : Props) => {

  const scriptContent = `
      window.args = {
        sitekey   : '${siteKey}',
        position  : 'Left',
        language  : 'HE',
        container : '',
        icon : '',
        access : 'https://vee-crm.com',
        styles : {
          primary_color: '#000000',
          secondary_color: '#b586ff',
          background_color: '#f6f6f6',
          primary_text_color: '#636363',
          headers_text_color: '#105675',
          primary_font_size: 14,
          slider_left_color:  '#b586ff',
          slider_right_color:  '#177fab',
          icon_vertical_position: 'bottom',
          icon_offset_top: 100,
          icon_offset_bottom: 100,
          highlight_focus_color: '#177fab',
          toggler_icon_color: '#ffffff',
        },
        links : {
          acc_policy: '',
          additional_link: 'https://vee.co.il/pricing/'
        },
        options : {
          open: false,
          aaa: false,
          hide_tablet: false,
          hide_mobile: false,
          button_size_tablet: 54,
          button_size_mobile: 54,
          position_tablet: 'Right',
          position_mobile: 'Right',
          icon_vertical_position_tablet: 'bottom',
          icon_vertical_position_mobile: 'bottom',
          icon_offset_top_tablet: 100,
          icon_offset_bottom_tablet: 100,
          icon_offset_top_mobile: 100,
          icon_offset_bottom_mobile: 100,
          keyboard_shortcut: true,
          hide_purchase_link: false,
          display_checkmark_icon: false,
          active_toggler_color: '#118f38'
        },
        exclude : []
      };

      (function(doc, head, body){
        var embed = doc.createElement('script');
        embed.src = window.args['access'] + '/js/';
        embed.defer = true;
        embed.crossOrigin = 'anonymous';
        embed.setAttribute('data-cfasync', true );
        body? body.appendChild(embed) : head.appendChild(embed);
      })(document, document.head, document.body);
 `;

  return <ScriptLoader scriptContent={scriptContent}/>;
};

export default AccessibilityPlugin;

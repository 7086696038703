import { Button } from 'antd';
import React, { ReactNode, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import { updateLayout } from '../store/config';
import { CgTrash } from 'react-icons/cg';
import { FaArrowDown, FaArrowUp, FaEdit, FaPlus } from 'react-icons/fa';
import './editComponent.less';
import { RootState } from '../store/store';
import { useSearchParams } from 'react-router-dom';

interface Props {
  children: ReactNode;
  instanceName: string;
  id?: string;
  index: string;
  allowOnlyEdit?: boolean;
  setIsEditModeOn: React.Dispatch<React.SetStateAction<string | null>>;
  isEditModeOn: string | null;
  layout: string;
  name: string;
  moduleName:string;
  isEditAllowed:boolean

}

const EditLayoutComponent = ({
  children,
  instanceName,
  index,
  allowOnlyEdit = false,
  setIsEditModeOn,
  isEditModeOn,
  layout,
  name,
  moduleName,
  isEditAllowed
}: Props) => {
  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.get('editMode') === 'true' && isEditAllowed;
  const isClickingDisabled = searchParams.get('editMode') === 'true' && !isEditAllowed
  const dispatch = useDispatch();
  const siteConfig = useSelector((data: RootState) => data.config.config);
  const handleEditOpen = (name: string) => {
    window.parent.postMessage({ message: {instanceName:name, moduleName} }, '*');
  };
  const ref = useRef<any>(null);

  const handleMoveLayoutModule = (dragIndex: any, hoverIndex: any) => {
    const prevCards = siteConfig.layouts[layout][name];
    const updatedLayout = update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex]],
      ],
    });
    dispatch(
      updateLayout({
        layouts: {
          [layout]: {
            ...siteConfig.layouts[layout],
            [name]: updatedLayout,
          },
        },
      })
    );
  };

  const handleDeleteLayoutModule = (moduleName: string) => {
    dispatch(
      updateLayout({
        layouts: {
          [layout]: {
            ...siteConfig.layouts[layout],
            [name]: siteConfig.layouts[layout][name].filter(
              (item: string) => item !== moduleName
            ),
          },
        },
      })
    );
  };

  const handleAddLayoutModule = (i: number) => {
    window.parent.postMessage(
      { addLayout: i, section: name },
        '*'
    );
  };

  return (
    <div
      className={`EDIT-LAYOUT ${isEditMode ? 'EDIT' : ''} ${
        allowOnlyEdit ? 'ONLY-EDIT' : ''
      } ${isEditModeOn === instanceName ? 'EDIT-MODE-ON' : ''} ${isClickingDisabled ? "IS_CLICKING_DISABLED" : ""}`}
      ref={ref}
      draggable={false}
    >
      {isEditMode && (
        <div className="EDIT-BUTTON">
          {!allowOnlyEdit && (
            <Button
              className="add-btn-top"
              icon={<FaPlus />}
              onClick={() => handleAddLayoutModule(Number(index))}
            >
              Add Layout Module on Top
            </Button>
          )}

          <div>
            {/* {!allowOnlyEdit && (
              <div className="arrows-wrapper">
                <FaArrowUp
                  className={`button ${Number(index) !== 0 ? '' : 'disabled'}`}
                  size={18}
                  onClick={() => {
                    Number(index) !== 0 &&
                      handleMoveLayoutModule(Number(index) - 1, index);
                  }}
                />
                <FaArrowDown
                  className={`button ${
                    siteConfig.layouts[layout][name].length - 1 !==
                    Number(index)
                      ? ''
                      : 'disabled'
                  }`}
                  size={18}
                  onClick={() => {
                    siteConfig.layouts[layout][name].length - 1 !==
                      Number(index) &&
                      handleMoveLayoutModule(Number(index), Number(index) + 1);
                  }}
                />
              </div>
            )} */}

            <div
              onClick={() => {
                handleEditOpen(instanceName);
              }}
              className="edit"
            >
              <FaEdit size={18} className="button" />
            </div>
            {/* {!allowOnlyEdit && (
              <div
                className="delete"
                onClick={() => {
                  handleDeleteLayoutModule(instanceName);
                }}
              >
                <CgTrash size={18} className="button" />
              </div>
            )} */}
          </div>

          {!allowOnlyEdit && (
            <Button
              className="add-btn-bottom"
              icon={<FaPlus />}
              onClick={() => handleAddLayoutModule(Number(index) + 1)}
            >
              Add Layout Module on Bottom
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default EditLayoutComponent;

export interface Room {
  id: number;
  name: string;
  type: string;
  room_price: number;
  person_price: number;
  adult: number;
  child: number;
  infant: number;
  board: string;
}

export interface FlightDestination {
  name: string;
  code: string;
}

export interface Airline {
  id: number;
  image: string;
  name: string;
}

export interface Baggage {
  is_include_suitcase: number;
  suitcase_weight: number;
  is_include_trolly: number;
  suitcase_price: number;
  trolly_price: number;
  trolly_weight: number;
  enable_sale_trolly: boolean,
  enable_sale_suitcase: boolean,
  is_round_trip:boolean
}

export interface FlightDayHour {
  start_day: number;
  start_hour: string;
  day_arrive: number;
  hour_arrive: string;
}

export interface Flight {
  id:string,
  from: FlightDestination;
  to: FlightDestination;
  airline: Airline;
  flight_code: string;
  is_direct?: number;
  num_of_stops?: number;
  stop_comment: string;
  date: string;
  baggage: Baggage;
  flightDayHour: FlightDayHour;
  available_seats:string;
  duration: string;
  flight_number: string;
  arrival_date: number;
  departure_date: number;
}

export enum MainProductType {
  Package = "package",
  Activity = "activity",
  Hotel = "hotel",
  Flight = "flight",
  Flights = "flight_package",
  Mix = "mix",
  Room = 'room',
  Trolly = 'trolly',
  Suitcase = 'suitcase',
  oneWayFlights = 'one_way_flights',
  oneWayFlight = 'one_way_flight',
  roomInfant = 'room_infant',
}
export enum OrderStatus {
  PAYMENT_FINISH = "payment_finish",
  PAYMENT_FAILED = "payment_failed",
  WAITING_TO_APPROVE = "waiting_to_approve",
  FREE = 'free',
  PAY_LATER = 'pay_later'
}

export interface CustomerInfo {
  customer_first_name: string,
  customer_last_name: string,
  customer_phone: string,
  customer_email: string,
  user_comment?: string,
}

interface Terms {
  baggage: string;
  cancel: {
      adult: string;
      child: string;
      infant: string;
  };
  change: {
      adult: string | null;
      child: string;
      infant: string;
  };
  operation: string;
  general: string[];
}

interface Pax {
  adult: number;
  child: number;
  infant: number;
}

interface Identifier {
  ts: number;
  deal_id: string;
  row_id: number;
  searchType: string;
  from_date: string;
  destination: string;
  supplier: string;
  departure: string;
  flightSubType: string;
  adult: number;
  child: number;
}
export interface FlightInterface {
identifier: Identifier;
status: string;
flightFrom: Flight[];
flightBack: Flight[];
terms: Terms;
pax: Pax;
}
export interface ShoppingCart {
  mainProductType: MainProductType,
  mainProductId: number,
  orderSecret?: string;
  orderId?: string;
  dates?: string;
  rooms?: Room[];
  activities?: any;
  name?: string;
  nights?: number;
  is_include_transfer?: number;
  transfer_type?: string;
  flights?: FlightInterface;
  hotel?: {
    id: number;
    name: string;
    infant_price?: number;
    maxChildAge: number;
  };
  codeInfo?: any,
  totalPrice?: number;
  totalNumberOfGuests?: number;
  currency_sign: string;
  paid_currency_sign: string;
  prom?: string;
  agency?: string;
  tp_id?: string;
  transactionId?: string;
  affiliate?:string;
}
export enum agencyChargeTypes {
  CHARGE_TYPE_CREDIT_CARD_FULL= "credit_card_full",
  CHARGE_TYPE_CREDIT_CARD_NET= "credit_card_net",
  CHARGE_TYPE_CASH ="cash",
};

import apiClient from '@skygroup/shared/API/apiClient';
import endpoints from '../../../utils/endpoints';

export const createUserApi = (formData: any, setIsLoading: any) => {
  setIsLoading(true);
  return apiClient
    .post(`${endpoints.AGENT.CREATE_USER}`, formData)
    .then((res) => {
      if (res.data.status === 'success') {
        return res.data.data;
      } else {
        throw res.data;
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const updateUserApi = (
  formData: any,
  secret: string,
  setIsLoading: any
) => {
  setIsLoading(true);
  return apiClient
    .post(`${endpoints.AGENT.UPDATE_USER}?secret=${secret}`, formData)
    .then((res) => {
      if (res.data.status === 'success') {
        return res.data;
      } else {
        throw res.data;
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
};

const appConfig = {
    "siteConfig": {
        "defaultLang": "he_IL",
        "direction": "rtl",
        "languages": [
            "he_IL",
            "en_US"
        ],
        "name": "Soho Travel - רישום קבוצות",
        "domain": "soho.sky360.co.il",
        "defaultDate":  {
            "from": 30,
            "range": 30
        },
        "style": {
            "topClass": 'soho'
        },
        "dateFormat":"DD/MM/YYYY",
        "logo":"/assets/images/logo-large.png",
        "logoSmall":"/assets/images/pazgaz/cart.png",
        "boardBasis":[
            "full_board",
            "sleep_only",
            "breakfast_kidush",
            "all_include",
            "breakfast",
            "choose_activties",
            "half_board",
            "half_board_kosher",
        ],
        "hotelStar":[
            '3', '4', '5'
        ],
        "destinations": {
            "local": [],
            "abroad":[]
        },
        "occupancy": [
            { adult: 2, child: 0},
            { adult: 3, child: 0},
            { adult: 2, child: 1},
            { adult: 2, child: 2},
          ],
        "email": {
            "value": "info@sky-group.co.il",
            "title": "זמינים במייל",
        },
        "phone": {
            "value": "077-772-9055",
            "title": "התקשרו או כתבו לנו בוואטסאפ",
            "hours": "שעות פעילות 09:00-17:00"
        },
        "facebook":{
            "value":"https://www.facebook.com/issta",
            "title":"פרגנו לנו בעוקב בפייסבוק"
        },
        "instagram":{
            "value":"https://www.instagram.com/issta/",
            "title":"פרגנו לנו בעוקב באינסטגרם"
        },
        "whatsapp": {
            "phone":"077-772-9055",
            "message":"",
        },
        "companyInfo": {
            "title":"Familybreak",
            "value":"מעניקה שירותי קרקע ומענה לקהל הישראלי המגיע לאודסה עם מגוון אטרקציות למשפחות,זוגות וצעירים",
            "address": "ויצמן 24, נתניה"
        }
    },
    "theme": {
        "primary": "#24bdad",
        // "primary": "#a6ce39",
        // "secondary": "#FFD62D",
        "secondary": "#2461bd",
        "tertiary": "#24bdad",
        "background": "#fff",
        "button": "btn-style-5",
        "fontFamily": "Heebo",
        "title": "title-style-2"
    },
    "layouts": {
        "default": {
            "topModules": [
                "Header",
            ],
            "bottomModules": [
                "Footer",
            ]
        },
        "noFooter": {
            "topModules": [
                "Header"
            ],
            "bottomModules": [
            ]
        },
        "empty": {
            "topModules": [

            ],
            "bottomModules": [

            ]
        }
    },
    "pages": [
        {
            'path':"/",
            "modules": [
                "TextInTheMiddle",
            ],
            "name": "Main page",
            "type": "mainPage",
            "layout": "noFooter",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/prom/:promSecret",
            "modules": [
                "PromoPage",
            ],
            "name": "Main Page",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path":  ["/groups/:id","/group/:id", "/prom/:promSecret/group/:id","/g/:alias", "/prom/:promSecret/package/:id"],
            "modules": [
                "PackageDetails",
                "Activities",
                "PackageRoomPricing",
            ],
            "name": "Package Info",
            "type": "packagePage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },

        {
            "path": "/checkout/:id",
            "modules": [
                "Checkout",
            ],
            "name": "Checkout",
            "type": "checkoutPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/order/manage",
            "modules": [
                "ManageOrder",
            ],
            "name": "Manage Order",
            "type": "orderPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
        {
            "path": "/404",
            "modules": [
                "NotFound404",
            ],
            "name": "NotFound404",
            "type": "indexPage",
            "layout": "default",
            "seo": {
                "title": "",
                "desc": ""
            }
        },
    ],
    "modulesConfigs": {
        "PromoPage": {
            "module": "PromoPage",
            "packageListConfig": {
                "set": "PackageListViewSet2",
                "backgroundType":"type1",
                "widgetFilterMenu": {
                    "isActive": false,
                },
                "widgetMorePackages": {
                    "isActive": false
                },
                "widgetPackageBoxHeader": {
                    "isActive": true,
                    "title": ""
                }
            },
        },
        "Header": {
            "links": [
            ],
            "set": "HeaderSet2",
            // "logo": "https://www.issta.co.il/media/1226/logo_website_50.png",
            // "logo": "https://upload.wikimedia.org/wikipedia/he/d/d0/LeimanS.svg",
            "logo":"/assets/images/pazgaz/logo-pazgaz-vaad2.png",
            "widgetSocial": {
                "isActive": false,
                "socials": [{
                    "key": "instagram",
                    "value": "https://www.instagram.com/familybreak.co.il/",
                }, {
                    "key": "facebook",
                    "value": "https://www.facebook.com/familybreak.co.il/"
                }, {
                    "key": "whatsapp",
"value": "whatsapp://send?text=ברוכים הבאים למענה של פמילי ברייק!&phone=+972777729055"
                }
                ],
            },
            "module": "Header"
        },
        "Footer": {
            "columns": [],
            "set": "FooterSet1",
            "module": "Footer",
            "widgetScrollToTop": {
                "isActive": true
            },
            "logo": "/assets/images/pazgaz/soho-logo.png",
            "logo2": "/assets/images/pazgaz/alit.png",
            "theme": {
                // "background": "#00CCFF",
                "tertiary": "#000"
            }
        },
        "NotFound404":{
            "image":"/assets/images/404.png",
            "title":'אופס.. הדף שחיפשת לא קיים',
            "redirect_title":'הנך מועבר לדף הבית',
            "redirect_seconds":7000,
        },
        "PackageDetails": {
            "theme": {
                "button": "btn-style-5",
                // "secondary": "#FFD62D",
                // "tertiary": "#000000"
            },
            "widgetFlights": {
                "isActive": true,
                "widgetSet": "FlightsSet1",
            },
            "widgetHotelInfo": {
                "isActive": true,
                "widgetSet": "HotelInfoSet1",
            },
            "widgetActivities": {
                "isActive": true,
                "widgetSet": "ActivitiesSet1",
            }

        },
        "PackageRoomPricing": {
            "showInfantsSwitch":false,
            "theme": {
                "button": "btn-style-5",
                // "tertiary": "#285290",
                "background": "#f9fbf2",
                // "background": "#e7ff0033"
            },
        },
        "Activities": {
            "theme": {
                // "tertiary": "black"
            },
            "set": "ActivitiesSet1",
            "module": "Activities"
        },
        "TextInTheMiddle":{
            "text":"Soho Travel - מערכת קבוצות",
            "module": "TextInTheMiddle"
        },
        "SearchDatePickerMainPage": {
            "module": "SearchDatePicker",
            "theme": {
                "title": "title-style-1"
            },
            "set": "SearchDatePickerSet2",
            "showSearchResults":{
                "isActive":false
            },
            "backgroundImg": "/assets/images/searchDatepickerPackground.jpg",
            "widgetDatePickerCarousel": {
                "isActive": false
            },
            "widgetVacationTypes": {
                "isActive": false
            },
        },
    }
};
export default appConfig;
import { IoClose } from 'react-icons/io5';
import React from 'react';
import { Product } from '../../ShoppingCartSliceV2';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';

interface Props {
  product: Product;
  freePackage: any;
  isCheckoutPage: boolean;
}

const ShoppingCartActivity = ({
  product,
  freePackage,
  isCheckoutPage,
}: Props) => {
  const { shoppingCartTranslations: t } = useSelector(
    (data: RootState) => data.config.translations
  );

  const { ShoppingCartV2 } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );

  return (
    <div className="rooms-activities">
      <b>{t.activities}</b>
      <div className="product-line">
        <div className="product-activity ">
          <span className="amount">{product.product_amount}</span>
          <span className="amount-x">X</span>
          <span className="name">{product.name}</span>
        </div>
        <div className="price-delete">
          {!freePackage && (
            <b>
              {product.total_price || 0}
              {String.fromCharCode(160)}
              {ShoppingCartV2.currency_sign}
            </b>
          )}
          {product?.total_price > 0 && !isCheckoutPage && (
            <IoClose
              size={15}
              className="delete-product"
              // onClick={() => handleDeleteActivity(key)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartActivity;

import React from 'react';

const PersonSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 448 448"
    >
      <g>
        <g>
          <path d="M279.616,220.608c36.715-19.861,61.717-58.709,61.717-103.275C341.333,52.651,288.683,0,224,0    S106.667,52.651,106.667,117.333c0,44.565,25.003,83.413,61.717,103.275C71.723,245.44,0,333.035,0,437.333    C0,443.221,4.779,448,10.667,448s10.667-4.779,10.667-10.667c0-111.744,90.923-202.667,202.667-202.667    s202.667,90.923,202.667,202.667c0,5.888,4.779,10.667,10.667,10.667S448,443.221,448,437.333    C448,333.035,376.277,245.44,279.616,220.608z M128,117.333c0-52.928,43.072-96,96-96s96,43.072,96,96c0,52.928-43.072,96-96,96    S128,170.261,128,117.333z" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
export default PersonSVG;

import { notification } from 'antd';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

const openNotification = (message: string, type: NotificationType) => {
  notification.open({
    message,
    type,
    placement: 'top',
    duration: 7,
  });
};
export default openNotification;

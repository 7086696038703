import ValidateModelLoaded from '@skygroup/shared/components/crud-helpers/ValidateModelLoaded';
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import { Anchor } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FightTermsType } from '../../interfaces/flightInterface';
import { useGetProductTermsMS } from '../../modules/PackagePage/PackagePageApi';
import { RootState } from '../../store/store';

interface Props {
  identifier: string;
  flightsTerms?: any; // FightTermsType;
}

const FlightTerms: React.FC<Props> = ({ identifier, flightsTerms }) => {
  const { agentCommission: t, crud: crudT } = useSelector(
    (data: RootState) => data.config.translations
  );
  const scrollingContainerRef = useRef<HTMLDivElement | null>(null);

  const getAnchorScrollOffset = () => {
    const isColumnLayout = window.innerWidth <= 768; // Adjust the threshold as needed
    return scrollingContainerRef?.current
      ? scrollingContainerRef?.current?.offsetTop - (isColumnLayout ? 150 : 0)
      : 0;
  };

  const anchorItems = useMemo(() => {
    let items: any = [];
    if (flightsTerms) {
      flightsTerms.map((term: any, index: number) => {
        items.push({
          key: 'terms_' + term.segment_id,
          href: `#terms_${term?.segment_id}`,
          title: <b>{term.fromTo}</b>,
        });
        if (term?.info) {
          term.info.map((info: { title: string; text: string }) => {
            items.push({
              key: `${term.fromTo}_${info?.title}`,
              href: `#${term.fromTo}_${info?.title}`,
              title: `${info.title} -`,
            });
          });
        }
      });
    }

    return items;
  }, [flightsTerms]);

  return (
    <>
      {anchorItems && (
        <Anchor
          className={`terms-modal-anchor`}
          getContainer={() =>
            scrollingContainerRef?.current
              ? scrollingContainerRef.current
              : window
          }
          offsetTop={-50}
          targetOffset={getAnchorScrollOffset()}
        >
          {anchorItems.map((item: any) => (
            <Anchor.Link key={item.key} href={item.href} title={item.title} />
          ))}
        </Anchor>
      )}
      <div className="terms-list-wrapper" ref={scrollingContainerRef}>
        {flightsTerms &&
          flightsTerms.map((term: any, index: number) => (
            <div
              className="terms-item-wrapper"
              key={term.segment_id}
              id={'terms_' + term.segment_id}
            >
              <h1>{term.title}</h1>
              {term?.info &&
                term.info.map((info: { title: string; text: string }) => (
                  <div key={info?.title} id={`${term.fromTo}_${info?.title}`}>
                    <h3>{info?.title}</h3>
                    <div>{info?.text}</div>
                    <br />
                  </div>
                ))}
            </div>
          ))}
      </div>
    </>
  );
};

export default FlightTerms;

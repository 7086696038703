import { createSlice } from '@reduxjs/toolkit';
import {agencyChargeTypes, CustomerInfo, MainProductType, Room, ShoppingCart} from './interfaces';
import { PackageSettingsInterface } from '../PackageDetails/packageDataInterface';
import { appliedSubsidize } from '../Checkout/Forms/SubsidizedForm';
import { packagePriceOption } from '@skygroup/shared/constants/checkoutInfo';
import { FlightViewResponse } from '../../interfaces/flightInterface';

export interface ShoppingCartFlightInterface {
  selectedSuitcase:{[key: string]: boolean};
  selectedTrolly:{[key: string]: boolean};
  trollyPrice:number;
  suitcasePrice:number;
  totalPrice:number;
  flightInfo:FlightViewResponse
}

const initialState: {
  shoppingCart: ShoppingCart;
  customerInfo: CustomerInfo,
  guestsInfo: any,
  prom: string,
  settings: PackageSettingsInterface,
  freePackage:boolean,
  priceOption:string,
  appliedSubsidize:appliedSubsidize[]
  orderStatus: string | null;
  paymentRetries: number,
  flight:ShoppingCartFlightInterface
} = {
  shoppingCart: {} as ShoppingCart,
  customerInfo: {} as CustomerInfo,
  guestsInfo: {},
  priceOption: '',
  prom: '',
  settings: {} as PackageSettingsInterface,
  freePackage: false,
  appliedSubsidize:[],
  orderStatus: null,
  paymentRetries: 1,
  flight:{} as ShoppingCartFlightInterface
};

const checkAndSetMainProduct = (state:any, productType:MainProductType, payload: any) => {
  // edge case where we have already init cart on activity but we are on package page - reset main product type
  if (state.shoppingCart.mainProductType == MainProductType.Activity && productType == MainProductType.Package) {
    state.shoppingCart.mainProductType = null;
  }

  // check if need to init
  if (!state.shoppingCart.mainProductType) {
    state.shoppingCart.mainProductType = productType;

    switch (productType) {
      case MainProductType.Package:
        const {packageData, packageFormattedData} = payload;

        // get package activities
        const activities = {...state.shoppingCart.activities} as any;

        // add all activities from package
        packageData.activities.forEach((activity: any) => {
          const productId = "a-package-" + activity.id;
          activities[productId] = {
            ...activity,
            priceType: 'reg',
            inPackage: true,
            amount: 0,
            price: 0,
          };
        });

        state.shoppingCart = {
          ...state.shoppingCart,
          prom: state.prom,
          mainProductId: packageData.id,
          dates: packageFormattedData.dates,
          name: packageData.name,
          nights: packageData.nights,
          activities: activities,
          is_include_transfer: packageData.is_include_transfer,
          transfer_type: packageData.transfer_type,
          flights: packageData.flights,
          currency_sign: packageData.currency_sign,
          paid_currency_sign: packageData.paid_currency_sign,
          hotel: {
            id: packageData.hotel.id,
            name: packageData.hotel.name,
            maxChildAge: packageData.hotel.maxChildAge
          }
        }

        break;
      case MainProductType.Hotel:

        break;
      case MainProductType.Activity:
        state.shoppingCart = {
          ...state.shoppingCart,
          prom: state.prom,
          mainProductId: null,
          mainProductType: productType,
          currency_sign: "₪",
          paid_currency_sign: "₪",
        }
        break;
    }
  }
}

const calculateTotals = (state: any) => {

  let totalPrice = 0;
  let totalActivityPrice = 0;
  let totalFlightPrice = 0;
  let totalGuests = 0;
  let totalProducts = 0;

  for (const i in state.shoppingCart.rooms) {
    const room = state.shoppingCart.rooms[i];
    totalPrice = totalPrice + (room.room_price * room.amount);
    totalGuests = totalGuests + (room.adult+room.child);
    totalProducts++;
  }

  for (const i in state.shoppingCart.activities) {
    const activity = state.shoppingCart.activities[i];
    if (activity.inPackage) {
      // need to update the amount
      state.shoppingCart.activities[i] = {
        ...activity,
        amount: totalGuests,
      }
    } else {
      if(activity.min_price){
        totalPrice = totalPrice + (activity.min_price * activity.amount);
      }
      else if(activity.price){
        totalPrice = totalPrice + activity.price;
      }

    }
  }

  // TODO: check for transfer price - if added


  //Subsidize price
  let totalPriceSubsidized = state.appliedSubsidize?.reduce((acc:number, entry:appliedSubsidize) => {
    return acc+=entry.discount;
  }, 0);
  totalPrice -= totalPriceSubsidized || 0;

  // check discount
  let codeInfo = state.shoppingCart.codeInfo;
  if (codeInfo) {
    let percentageDiscount = 0;
    let isPriceOff = false;
    let priceOff = 0;
    let discountPrice = 0;
    let newPrice = 0;
    let originalPrice = totalPrice;

    if (codeInfo.type === "agent") {
      percentageDiscount = codeInfo.comission_percentage;
    } else {
      if (codeInfo.type === "discount") {
        if (codeInfo.discount.price_off > 0) {
          isPriceOff = true;
          priceOff = Math.round(codeInfo.discount.price_off);
        } else {
          percentageDiscount = codeInfo.discount.percentage_off;
        }
      }
    }

    if (isPriceOff) {
      newPrice = totalPrice - priceOff;
      discountPrice = priceOff;
    } else {
      newPrice = ((100 - percentageDiscount) / 100) * totalPrice;
      newPrice = Math.round(newPrice * 100) / 100;
      discountPrice = originalPrice - newPrice;
      discountPrice = Math.round(discountPrice * 100) / 100;
    }

    if (codeInfo.type === "agent" &&
        codeInfo.charge_type === agencyChargeTypes.CHARGE_TYPE_CREDIT_CARD_FULL) {
      totalPrice = originalPrice;
    } else {
      totalPrice = newPrice;
    }

    codeInfo = {
      ...codeInfo,
      discountPrice:discountPrice,
      originalPrice: originalPrice,
    };
  }

  // update the state
  state.shoppingCart = {
    ...state.shoppingCart,
    codeInfo: codeInfo,
    totalNumberOfGuests: totalGuests,
    totalPrice: totalPrice
  }
}

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    addRoomToCart: (state, action) => {
      if (action.payload.packageData) {
        checkAndSetMainProduct(state, MainProductType.Package, action.payload);
      }

        let roomsToAdd = action.payload.roomData
        // Process each room data
        let newRooms = roomsToAdd.map((room:any) => {
        return {
        ...room,
        room_price: room.room_price + room.infant * state.settings.infant_price
        };
        });

        // Add the new rooms to the cart
        state.shoppingCart.rooms = state.shoppingCart.rooms?.length
                  ? [...state.shoppingCart.rooms, ...newRooms]
                  : newRooms;

        // Calculate total price and guests
        calculateTotals(state);
    },
    addFlightToCartOld: (state, action) => {
        state.shoppingCart.mainProductType = MainProductType.Flight;
        state.flight.flightInfo = action.payload.flight;
        state.flight.selectedSuitcase = action.payload.selectedSuitcase;
        state.flight.selectedTrolly = action.payload.selectedTrolly;
        state.flight.suitcasePrice = action.payload.suitcasePrice;
        state.flight.trollyPrice = action.payload.trollyPrice;
        state.flight.totalPrice = action.payload.totalPrice;
        state.shoppingCart.totalPrice = action.payload.totalPrice;
        state.shoppingCart.currency_sign = action.payload.flight.flightsInfo.price.currency_sign;


    },
    updateRoom: (state, action) => {
      if (state.shoppingCart && state.shoppingCart.rooms) {
        const deltaInfants = action.payload.room.infant - state.shoppingCart.rooms[action.payload.index].infant;
        const deltaInfantsPrice = deltaInfants * state.settings.infant_price;
        // update the room price based
        action.payload.room.room_price += deltaInfantsPrice;
        state.shoppingCart.rooms[action.payload.index] = action.payload.room;
      }
      calculateTotals(state);
    },
    removeRoom: (state, action) => {

      state.shoppingCart.rooms = state.shoppingCart.rooms?.filter(
          (room, i: number) => action.payload.roomIndex !== i
      );

      if (state.shoppingCart.rooms?.length === 0) {
        state.shoppingCart = {} as ShoppingCart;
      } else {
        // now need to calc all price and guests
        calculateTotals(state);
      }
    },
    updateOrderInfo: (state, action) => {
      state.shoppingCart.orderSecret = action.payload.orderSecret;
      state.shoppingCart.orderId = action.payload.orderId;
    },
    updateOrderTransactionId: (state, action) => {
      state.shoppingCart.transactionId = action.payload.transaction_id;
    },
    updatePaymentRetriesCount: (state, action) => {
      state.paymentRetries = action.payload.paymentRetries;
    },
    updateShoppingCart: (state, action) => {
      state.shoppingCart = action.payload;
    },
    updateShoppingCartFlight: (state, action) => {
      state.flight = action.payload;
    },
    updatePackageSettings: (state, action) => {
      state.settings = action.payload;
    },
    updateAppliedSubsidize: (state, action) => {
      state.appliedSubsidize = action.payload;
      calculateTotals(state);
    },
    updatePackagePrice: (state, action) => {
      state.freePackage = action.payload === packagePriceOption.FREE;
      state.priceOption = action.payload;
    },
    updateActivities: (state, action) => {
      checkAndSetMainProduct(state, MainProductType.Activity, action.payload);

      state.shoppingCart.activities = action.payload.activities;

      // now need to calc all price and guests
      calculateTotals(state);
    },
    setOrderDetails: (state, action) => {
      state.shoppingCart = action.payload.shoppingCart;
      state.customerInfo = action.payload.customerInfo;
      state.guestsInfo = action.payload.guestsInfo;
      state.orderStatus = action.payload.status;
    },
    clearShoppingCart: (state) => {
      state.shoppingCart = {} as ShoppingCart;
      state.appliedSubsidize = [];
    },
    updatePromotion: (state, action) => {
      state.prom = action.payload.prom;
    },
    updateAffiliate: (state, action) => {
      state.shoppingCart.affiliate = action.payload.affiliate;
    },
    updateCodeInfo: (state, action) => {
      state.shoppingCart.codeInfo = action.payload.codeInfo;
      calculateTotals(state);
    },
    updateCustomerInfo: (state, action) => {
      state.customerInfo =  action.payload.customerInfo;
      state.guestsInfo = action.payload.guestsInfo;
    },
  },
});

export const {updateCodeInfo,updateActivities, updateRoom,updatePromotion, updateShoppingCart, updateShoppingCartFlight,setOrderDetails, clearShoppingCart, addRoomToCart, removeRoom, updateOrderInfo, updateOrderTransactionId, updateCustomerInfo,updatePackageSettings, updatePackagePrice,updateAppliedSubsidize, updateAffiliate, updatePaymentRetriesCount, addFlightToCartOld} =
    shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;
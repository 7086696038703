import React, { useCallback, useMemo } from "react";
import { themeInterface } from "../interfaces";

const useCssVariables = () => {
   const useVariables =  useCallback(
      (scope:string, theme:themeInterface) => {
        return theme ? <style>
                {`
                    ${scope} {
                        ${theme.primary ? '--primary-color:' + theme.primary : ""} ;
                        ${theme.secondary ? '--secondary-color:' + theme.secondary : ""};
                        ${theme.tertiary ? '--tertiary-color:' + theme.tertiary : ""};
                        ${theme.background ? '--background-color:' + theme.background : ""};
                        ${theme.text_color ? '--text-color:' + theme.text_color : ""};
                        ${theme.fontFamily ? '--font-family:' + theme.fontFamily : ""};
                    }
                `}
            </style> : null;
      },
      [],
    );


    return useVariables;
};

export default useCssVariables;
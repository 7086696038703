export const packageLink = (singlePackage: any) => {
  return `/package/${singlePackage.identifier ?? singlePackage.id} `;
};

export const flightLink = (
  type: string,
  identifier: string,
  currentSearchParams: string
) => {
  return `/flight/view/${type}/${identifier}${currentSearchParams}`;
};

export const hotelLink = (identifier: string, currentSearchParams: string) => {
  return `/hotel/${identifier}${currentSearchParams}`;
};

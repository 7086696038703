import React, { useEffect } from 'react';
function cleanObject(inputObj: Record<string, any>) {
  const currentTime = Date.now();
  const fourHoursInMilliseconds = 4 * 60 * 60 * 1000;
  const result: any = {};
  Object.entries(inputObj).forEach(([key, value]) => {
    const match = key.match(/^t(\d+)_(\d+)$/);
    if (match) {
      const creationTime = parseInt(match[1], 10);
      // Check if the time is less than 4 hours old
      if (currentTime - creationTime <= fourHoursInMilliseconds) {
        result[key] = value;
      }
    }
  });
  return result;
}

const useCleanUpLocalStorage = () => {
  useEffect(() => {
    const cleanedUpLocalStorage = JSON.stringify(
      cleanObject(JSON.parse(localStorage.getItem('cartV2') ?? '{}'))
    );
    localStorage.setItem('cartV2', cleanedUpLocalStorage);
  }, []);
};

export default useCleanUpLocalStorage;

import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';

import { RootState } from '../../../store/store';
import CheckFalse from './assets/CheckFalse';
import CheckTrue from './assets/CheckTrue';
import getAdditionalFaresFlightIcon, {
  useGetAdditionalFaresName,
} from './getAdditionalFaresFlightIcon';

import './additionalFaresFlightBox.less';
import { isBoolean } from 'lodash';
import AmenitiesTooltip from './AmenitiesTooltip';

const Amenities = ({ flightFrom, handleClick, uniqueNames, flightBack }: any) => {
  const getAdditionalFaresName = useGetAdditionalFaresName();


  const { viewFlight: t } = useSelector(
    (rootState: RootState) => rootState.config.translations
  );

  const flightDestinationAirport = flightBack?.to?.name ?? flightFrom.to.name

  const fares = flightFrom.fare.types;
  return (
    <>
      <div className="amenities-wrapper">
        <div className="fare-name">
          <AmenitiesTooltip flightFrom={flightFrom} />

          {uniqueNames?.length > 1 && (
            <div onClick={handleClick} className="check-amenities">
              {t.checkAmenities}
            </div>
          )}

          {flightFrom.from.name} → {flightDestinationAirport}

        </div>

        <div className="amenities">
          {Object.keys(fares).map((key) => {
            if (!isBoolean(fares[key])) return null;

            return (
              <div className="amenity" key={key}>
                {getAdditionalFaresFlightIcon(key)}
                {getAdditionalFaresName(key)}

                {fares[key] ? <CheckTrue /> : <CheckFalse />}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Amenities;

import ValidateModelLoaded from '@skygroup/shared/components/crud-helpers/ValidateModelLoaded';
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import { Anchor } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetProductTermsMS } from '../../modules/PackagePage/PackagePageApi';
import { RootState } from '../../store/store';

interface Props {
  identifier: string;
  description?: string;
}

const AgentProductTerms: React.FC<Props> = ({ identifier, description }) => {
  const { agentCommission: t, crud: crudT } = useSelector(
    (data: RootState) => data.config.translations
  );
  const scrollingContainerRef = useRef<HTMLDivElement | null>(null);
  const { data, isLoading, refetch, isError, error } =
    useGetProductTermsMS(identifier);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [termsArr, setTermsArr] = useState<any[]>([]);
  const [anchorItems, setAnchorItems] = useState<any[]>([]);

  useEffect(() => {
    if (!data) {
      setTermsArr([]);
      return;
    }

    let arr = [...data];

    if (description) {
      arr.unshift({ title: t.packageRemarks, number: 1, text: description });
    }

    setTermsArr(arr);
    if (arr.length > 0) {
      setAnchorItems(() =>
        arr.map((term: any, index: number) => {
          return {
            key: 'terms_' + term.number,
            href: `#terms_${term?.number}`,
            title: term?.title,
          };
        })
      );
    }
  }, [data, description]);

  const getAnchorScrollOffset = () => {
    const isColumnLayout = window.innerWidth <= 768; // Adjust the threshold as needed
    return scrollingContainerRef?.current
      ? scrollingContainerRef?.current?.offsetTop - (isColumnLayout ? 150 : 0)
      : 0;
  };

  return (
    <SpinLoader isLoading={isLoading} onTop>
      <ValidateModelLoaded isError={isError} error={error} t={crudT}>
        {scrollingContainerRef?.current && (
          <Anchor
            className={`terms-modal-anchor`}
            getContainer={() =>
              scrollingContainerRef?.current
                ? scrollingContainerRef.current
                : window
            }
            offsetTop={-100}
            targetOffset={getAnchorScrollOffset()}
          >
            {anchorItems?.length > 0 &&
              anchorItems.map(
                (item: { key: string; href: string; title: string }) => (
                  <Anchor.Link
                    key={item.key}
                    href={item.href}
                    title={`${item.title} -`}
                  />
                )
              )}
          </Anchor>
        )}
        <div className="terms-list-wrapper" ref={scrollingContainerRef}>
          {termsArr &&
            termsArr.map((term: any, index: number) => (
              <div
                className="terms-item-wrapper"
                key={term.title}
                id={'terms_' + term.number}
              >
                <h1>{term.title}</h1>
                {term?.text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: term.text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                    }}
                  />
                )}
                <br />
              </div>
            ))}
        </div>
      </ValidateModelLoaded>
    </SpinLoader>
  );
};

export default AgentProductTerms;

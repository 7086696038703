import React from 'react';
import { MainProductType } from '../../ShoppingCart/interfaces';
import { isEmpty } from 'lodash';

interface Props {
  flightSubProduct: any;
  relatedGuestIndex: number;
}
function countItems(array: number[], searchItem: number) {
  return array.filter((item) => item === searchItem).length;
}
const useGetFlightData = ({ flightSubProduct, relatedGuestIndex }: Props) => {
  if(isEmpty(flightSubProduct)){
    return {
      suitCaseValueBack:undefined,
      suitCaseValueFrom:undefined,
      trollyValueBack:undefined,
      trollyValueFrom:undefined,
      suitCaseSupProductFlightBack:undefined,
      suitCaseSupProductFlightFrom:undefined,
      trollySupProductFlightBack:undefined,
      trollySupProductFlightFrom:undefined,
      flightFrom:undefined,
      flightBack:undefined,
      flightBackBaggage:undefined,
      flightFromBaggage:undefined,
      isBaggageSaleAllowed:undefined,
    };
  }
  const flightKey =
    flightSubProduct[0]?.product_data?.flightFrom ||
    flightSubProduct[0]?.product_data?.flightsInfo?.flightFrom
      ? 'flightFrom'
      : 'flight';

  const flightBackStops =
    flightSubProduct[1]?.product_data?.flightFrom?.length ??
    flightSubProduct[1]?.product_data?.flightBack?.length ??
    flightSubProduct[1]?.product_data?.flight?.length ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flightFrom?.length ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flightBack?.length ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flight?.length;

  const flightBackBaggage =
    flightSubProduct[1]?.product_data?.flightBack?.[flightBackStops - 1]
      ?.baggage ??
    flightSubProduct[1]?.product_data?.flightFrom?.[flightBackStops - 1]
      ?.baggage ??
    flightSubProduct[1]?.product_data?.flight?.[flightBackStops - 1]?.baggage ??
    flightSubProduct[1]?.product_data?.baggage ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flightBack?.[
      flightBackStops - 1
    ]?.baggage ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flightFrom?.[
      flightBackStops - 1
    ]?.baggage ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flight?.[
      flightBackStops - 1
    ]?.baggage;

  const flightFromBaggage =
    flightSubProduct[0]?.product_data?.[flightKey]?.[0]?.baggage ??
    flightSubProduct[0]?.product_data?.flightsInfo?.[flightKey]?.[0]?.baggage ??
    flightSubProduct[0].product_data.baggage;


  const isBaggageSaleAllowed =
    flightBackBaggage?.enable_sale_suitcase ||
    flightBackBaggage?.enable_sale_trolly ||
    flightFromBaggage?.enable_sale_suitcase ||
    flightFromBaggage?.enable_sale_trolly;

  const flightBack =
    flightSubProduct[1]?.product_data?.flightBack?.[flightBackStops - 1] ??
    flightSubProduct[1]?.product_data?.flightFrom?.[flightBackStops - 1] ??
    flightSubProduct[1]?.product_data?.flight?.[flightBackStops - 1] ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flightBack?.[
      flightBackStops - 1
    ] ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flightFrom?.[
      flightBackStops - 1
    ] ??
    flightSubProduct[1]?.product_data?.flightsInfo?.flight?.[
      flightBackStops - 1
    ];

  const flightFrom =
    flightSubProduct[0]?.product_data?.[flightKey]?.[0] ??
    flightSubProduct[0]?.product_data?.flightsInfo?.[flightKey][0];

  const trollySupProductFlightFrom = flightSubProduct[0]?.subProducts?.find(
    (subProduct: any) => {
      return subProduct.type === MainProductType.Trolly;
    }
  );
  const trollySupProductFlightBack = flightSubProduct[1]?.subProducts?.find(
    (subProduct: any) => {
      return subProduct.type === MainProductType.Trolly;
    }
  );

  const suitCaseSupProductFlightFrom = flightSubProduct[0]?.subProducts?.find(
    (subProduct: any) => {
      return subProduct.type === MainProductType.Suitcase;
    }
  );
  const suitCaseSupProductFlightBack = flightSubProduct[1]?.subProducts?.find(
    (subProduct: any) => {
      return subProduct.type === MainProductType.Suitcase;
    }
  );

  const trollyValueFrom = countItems(
    trollySupProductFlightFrom?.related_guest_indexes ?? [],
    relatedGuestIndex
  );
  const trollyValueBack = countItems(
    trollySupProductFlightBack?.related_guest_indexes ?? [],
    relatedGuestIndex
  );
  const suitCaseValueFrom = countItems(
    suitCaseSupProductFlightFrom?.related_guest_indexes ?? [],
    relatedGuestIndex
  );
  const suitCaseValueBack = countItems(
    suitCaseSupProductFlightBack?.related_guest_indexes ?? [],
    relatedGuestIndex
  );

  return {
    suitCaseValueBack,
    suitCaseValueFrom,
    trollyValueBack,
    trollyValueFrom,
    suitCaseSupProductFlightBack,
    suitCaseSupProductFlightFrom,
    trollySupProductFlightBack,
    trollySupProductFlightFrom,
    flightFrom,
    flightBack,
    flightBackBaggage,
    flightFromBaggage,
    isBaggageSaleAllowed,
  };
};

export default useGetFlightData;

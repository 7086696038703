import { Button, Col, ConfigProvider, Form, Modal, Row } from 'antd';
import './addUserModal.less';
import React, { useEffect, useState } from 'react';
import InputWithMovingPlaceholder from '@skygroup/shared/components/InputWithMovingPlaceholder/InputWithMovingPlaceholder';
import { createUserApi, updateUserApi } from '../AgentActionsAPI';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { setCustomerInfo, setWholeCart } from '../../ShoppingCartSliceV2';
import Title from 'antd/lib/typography/Title';

interface Props {
  addUserModal: {
    open: boolean;
    customer_secret: string;
  };
  setAddUserModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      customer_secret: string;
    }>
  >;
}

const AddUserModal = ({ addUserModal, setAddUserModal }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setIsLoading] = useState(false);

  const { general: general_t, shoppingCartTranslations: t } = useSelector(
    (state: RootState) => state.config.translations
  );

  const isCreateMode = !addUserModal.customer_secret;

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { ShoppingCartV2 } = useSelector(
    (state: RootState) => state.shoppingCartV2
  );

  const handleSubmit = async () => {
    await form.validateFields();

    const mainCustomerInfo = {} as any;

    if (customerInfo.first_name)
      mainCustomerInfo.customer_first_name = customerInfo.first_name;
    if (customerInfo.last_name)
      mainCustomerInfo.customer_last_name = customerInfo.last_name;
    if (customerInfo.phone)
      mainCustomerInfo.customer_phone = customerInfo.phone;
    if (customerInfo.email)
      mainCustomerInfo.customer_email = customerInfo.email;
    if (customerInfo.comment)
      mainCustomerInfo.user_comment = customerInfo.comment;

    dispatch(
      setWholeCart({
        ...ShoppingCartV2,
        customerInfo: { ...ShoppingCartV2.customerInfo, ...mainCustomerInfo },
      })
    );

    const response = isCreateMode
      ? await createUserApi(form.getFieldsValue(), setIsLoading)
      : await updateUserApi(
          form.getFieldsValue(),
          customerInfo.customer_secret,
          setIsLoading
        );

    if (response) {
      dispatch(
        setCustomerInfo({
          ...form.getFieldsValue(),
          order_secret: response?.order_secret ?? customerInfo.order_secret ?? "",
          customer_secret: response?.customer_secret ?? customerInfo.customer_secret ?? "",
        })
      );
      setAddUserModal({ open: false, customer_secret: '' });
      localStorage.setItem(
        'currentCustomer',
        JSON.stringify({
          ...form.getFieldsValue(),
          order_secret: response?.order_secret ?? customerInfo.order_secret ?? "",
          customer_secret: response?.customer_secret ?? customerInfo.customer_secret ?? "",
        })
      );
      form.resetFields();
    }
  };

  const { customerInfo } = useSelector(
    (data: RootState) => data.shoppingCartV2
  );
  const { siteConfig } = useSelector((data: RootState) => data.config.config);

  useEffect(() => {
    if (isCreateMode) {
      form.setFieldsValue({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        comment: '',
        order_secret: '',
        customer_secret: ''

      });
    } else {
      form.setFieldsValue(customerInfo);
    }
  }, [addUserModal]);

  return (
    <Modal
      open={addUserModal.open}
      onCancel={() => setAddUserModal({ open: false, customer_secret: '' })}
      footer={null}
      closeIcon={null}
      closable={false}
    >
      <ConfigProvider direction={siteConfig.direction}>
        <Title>
          {isCreateMode ? t.createCustomerTitle : t.updateCustomerTitle}
        </Title>
        <Form form={form} onFinish={handleSubmit}>
          <Row gutter={20}>
            <Col md={12} sm={24}>
              <Form.Item
                name={'first_name'}
                rules={[{ required: true, message: general_t.fieldRequired }]}
              >
                <InputWithMovingPlaceholder
                  onChange={handleChange}
                  value={inputValue}
                  placeholder={t.firstName}
                />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <Form.Item
                name="last_name"
                rules={[{ required: true, message: general_t.fieldRequired }]}
              >
                <InputWithMovingPlaceholder
                  onChange={handleChange}
                  value={inputValue}
                  placeholder={t.lastName}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col md={12} sm={24}>
              <Form.Item
                name="phone"
                rules={[{ required: true, message: general_t.fieldRequired }]}
              >
                <InputWithMovingPlaceholder
                  onChange={handleChange}
                  value={inputValue}
                  placeholder={t.phone}
                />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <Form.Item
                name="email"
                rules={[{ type: 'email', message: general_t.wrongEmailFormat }]}
              >
                <InputWithMovingPlaceholder
                  onChange={handleChange}
                  value={inputValue}
                  placeholder={t.email}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item name="comment">
                <InputWithMovingPlaceholder
                  onChange={handleChange}
                  value={inputValue}
                  placeholder={t.comment}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ConfigProvider>

      <Button onClick={handleSubmit} loading={loading} className="btn-style-5">
        {t.saveCustomer}
      </Button>
    </Modal>
  );
};

export default AddUserModal;

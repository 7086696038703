import React from 'react';
import { Tooltip } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const AmenitiesTooltip = ({ flightFrom, isShowFullText = true }: any) => {
  const { viewFlight: t } = useSelector(
    (rootState: RootState) => rootState.config.translations
  );

  if (!flightFrom?.fare?.name) return null;
  const nonChargeableAmenities = flightFrom?.fare?.additional_services?.filter(
    (item: any) => !item.isChargeable
  );
  return (
    <Tooltip
      overlayClassName="non-chargeable-amenities-tooltip"
      title={
        <div className="non-chargeable-amenities">
          <table>
            <tbody>
              {nonChargeableAmenities.map((item: any) => (
                <tr key={item.code} style={{ borderBottom: '1px solid #ccc' }}>
                  <td className="amenity-description">
                    <span className="amenity-type">
                      {t[item.amenityType] ?? item.amenityType}
                    </span>
                    {t[item.description] ?? item.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    >
      {isShowFullText && t.fullInfo}
      <FaInfoCircle />
      {flightFrom.fare.name}
    </Tooltip>
  );
};

export default AmenitiesTooltip;

import React, { useEffect } from 'react';

interface Props {
  scriptContent: string
}

const ScriptLoader = ({scriptContent}:Props) => {
  useEffect(() => {

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptContent;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default ScriptLoader;
